
import { Checkbox, FormControl, IconButton, InputLabel, MenuItem, Pagination, PaginationItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styles from '../../pages/read-documents/styles/tableResults.module.scss'
import { Box } from '@mui/system';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Images from 'shared/assets/index';
import { ITable, ITableData} from './interfaces/table.interface';
import { RouterGuard } from './RouteGuard';
import { Spinner } from './Spinner';
import { SearchItems } from './paginator/SearchItems';
import { debounceFunction } from 'shared/utils';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { useTableResults } from 'shared/hooks/useTableResults';
import { IParamsUrl } from 'shared/models';


export const TableComponent = (props: ITable<any>)=> {

  const dispatch = useDispatch<Dispatch>();
  const {  columns, title,  optionsTrazact,  valuesResultsPages,  downloadExcel,  rowSelected, selectAllRows,  selectRow } = props
  const { electronicDocuments, isLoading, sizeRows, searcher, handleChangePage, handleChangeSizePage, handleSortByColumn } = useTableResults();
  const { content, contentSize, totalElements, size, number, paramsUrl, url } = electronicDocuments;
  const { open, text } = searcher;
  const params: IParamsUrl = {
    ...paramsUrl,
    filter: text
  }

  useEffect(() => {
    if (open) {
      debounceFunction(() => {
        dispatch.electronicDocuments.getElectronicDocuments(params, url)
      });
    }
  }, [text]);

  return (
    <React.Fragment>
      <Box className={styles.headerTable}>
        <p className={'titleMain'}>{title}</p>
        <Box className={styles.searchContainer}>
          <span className={styles.searchItems}>
            <SearchItems data={content!} placeholder='Buscar' autocomplete={false} />
          </span>

          {
            downloadExcel &&
            <Tooltip title='Exportar Facturas' placement="top" arrow>
              <IconButton
                className={styles.buttonExportExcel}
                data-testid="btn-export"
                onClick={downloadExcel}
                >
                <img className={styles.exportImage} src={Images.iconoDescargar} alt="Exportar a excel" />
              </IconButton>
            </Tooltip>
          }
          {optionsTrazact && optionsTrazact()}
        </Box>

      </Box>

      <Box className={styles.contenedorTotalResultados} data-testid='contenedor_para_desktop'>
        <p className={styles.resultadosTotales}>{contentSize}/{totalElements}</p>
      </Box>

      {
        totalElements > 0 ? (
          <Paper className={styles.containerTableResults} style={{display: 'flex', flexDirection:'column'}}>
            <TableContainer className={styles.tableResults}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                {
                  columns.length > 0 && (
                    <>
                      <TableHead>
                        <TableRow className={styles.tablaHeader}>
                          {
                            rowSelected && selectAllRows &&
                            <TableCell padding="checkbox" className={styles.tableCell}>
                              <Checkbox
                                color="primary"
                                indeterminate={rowSelected.length > 0 && rowSelected.length < contentSize}
                                checked={contentSize > 0 && rowSelected.length === contentSize}
                                onChange={selectAllRows}
                                inputProps={{
                                  'aria-label': 'select all docs',
                                }}
                              />
                            </TableCell>
                          }
                          {
                            columns.map((column, index) => (
                              <React.Fragment key={column.field + '_' + index}>
                              {
                                column.rowGuard  
                                ? 
                                <RouterGuard roles={column.rowGuard.roles} type="component">
                                  <TableCell
                                    align="left"
                                    className={styles.tableCell}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.headerName}
                                    {column.sortable && <img src={Images.iconoOrdenar2} alt={`sort_${index}`} onClick={() => handleSortByColumn(column.sortable)} />}
                                  </TableCell>
                                </RouterGuard>
                                :
                                <TableCell
                                  align="left"
                                  className={styles.tableCell}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.headerName}
                                  {column.sortable && <img src={Images.iconoOrdenar2} alt={`sort_${index}`} onClick={() => handleSortByColumn(column.sortable)} />}
                                </TableCell>
                              }
                              </React.Fragment>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody data-testid='table-results-body'>
                        {content!.map((row:any, index) => (
                          <TableRow
                            key={index}
                          >
                            {
                              selectRow && rowSelected &&
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={rowSelected.indexOf(row.id) !== -1}
                                  inputProps={{
                                    'aria-labelledby': `check${index}`,
                                  }}
                                  onChange={(e) => selectRow(e, row.id)}
                                  className={styles.checkboxFiles}
                                />
                              </TableCell>
                            }
                            {columns.map((column, index) => (
                              <React.Fragment key={column.field + '_' + index}>
                                {
                                  column.rowGuard  
                                  ? 
                                  <RouterGuard roles={column.rowGuard.roles} type="component">
                                    <TableCell
                                      align="left"
                                      className={styles.tableCell}
                                    >
                                      { column.renderCell ? column.renderCell(row) : row }
                                    </TableCell>
                                  </RouterGuard>
                                  :
                                  <TableCell
                                    align="left"
                                    className={styles.tableCell}
                                    key={column.field + index}
                                  >
                                    { column.renderCell ? column.renderCell(row) : row }
                                  </TableCell>
                                }
                              </React.Fragment>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>  
                    </>
                  )
                }
              </Table>
            </TableContainer>
            <Box className={styles.footerGrid}>
                <Box className={styles.contenedorResultadosPorPagina}>
                    <p>Resultados por página</p>
                    <FormControl fullWidth className={styles.containerSizeRows}>
                    <InputLabel id="select-size-label">Cantidad</InputLabel>
                    <Select
                      labelId="select-size-label"
                      id="select-size"
                      data-testid='select-size-pagination'
                      value={sizeRows ?? ''}
                      label="Cantidad"
                      className={styles.selectSizeRows}
                      onChange={handleChangeSizePage}
                    >
                      {
                        valuesResultsPages.map(value=>
                          <MenuItem value={value} key={value}>{value}</MenuItem>
                        )
                      }
                    </Select>
                    </FormControl>
                </Box>
                <Pagination
                    className={styles.paginador}
                    data-testid='action-pagination'
                    page={number}
                    count={Math.ceil(totalElements / size)}
                    siblingCount={0}
                    boundaryCount={1}
                    renderItem={(item) => (
                    <PaginationItem
                        slots={{
                        previous: () => <Box className={styles.botonPaginador}><KeyboardArrowLeft /><p>Ant.</p></Box>,
                        next: () => <Box className={styles.botonPaginador}><p>Sig.</p><KeyboardArrowRight /></Box>
                        }}
                        {...item}
                    />
                    )}
                    onChange={(e, page) => { handleChangePage(e, page) }}
                />
            </Box>
          </Paper>
        ): (
          <Box className={styles.containerResults}>No se encontraron resultados relacionados a su búsqueda</Box>
        )

      }

      {(isLoading) && <Spinner />}
    </React.Fragment>
  )
}
