import { Grid, Typography } from '@mui/material';
import style from '../../styles/detailedConsultation.module.scss'
import Image from 'shared/assets/index';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IContractPerfomanceIndicatos } from '../../Models/IContractPerfomanceIndicatos';

export const ContractPerformanceIndicators = ({ data }: IContractPerfomanceIndicatos) => {

  return (
    <div className={style.containerPerformanceIndicators} data-testid="performance_indicators">
      <section className={style.containerTitleCardSeeContact}>
        <img src={Image.iconoContractExecution} alt="icono_doc" />
        <Typography className={style.titleCard}>{data.title}</Typography>
      </section>

      <section className={style.containerBodyPerfomance}>
        {
          data.values.length > 0 &&
            data?.values?.map((value, index) => {
              return (
                <Grid container direction={"row"} className={`${style.containerInfoIndicators} ${style.gridContractPerfomance}`} key={index} data-testid={`grid_content_${index}`}>
                  {
                    Object.keys(value).map((key) => {
                      if (key in value) {
                        const property = value[key as keyof typeof value] as any;
                        const iconClass = property?.icon ? style[property.icon] : '';
                        return (
                          <Grid item xs={12} md={property?.md} className={`${style.containerProgress} ${iconClass}`} key={key + 100} data-testid={`grid_item_${key}`}>
                            {key.includes('percent') ? (
                              <CircularProgressbar 
                                value={property.percent} 
                                text={`${property.percent}%`} 
                                className={style.circularProgess} 
                                strokeWidth={6}
                                styles={buildStyles({
                                  pathColor: "rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)",
                                  trailColor: "rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 0.4)"
                                })}
                              />
                            ) : (
                              <Typography className={style.valueText}> {property.value} </Typography>
                            )}
                            <Typography>{property.title}</Typography>
                          </Grid>
                        );
                      }
                    })
                  }
                </Grid>
              )
            })
        }
      </section>
    </div>
  )
}
