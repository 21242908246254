
import React, { useEffect } from 'react'
import { Layout } from '../../shared/utils/ui/Layout';
import style from './styles/submitInvoce.module.scss'
import { Button, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Image from 'shared/assets/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyleSubmitInvoice } from './hooks/useStyleSubmitInvoice';
import { PrepareInvoice } from 'pages/prepare-invoice/PrepareInvoice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { activities, statesActivities } from 'shared/models/lineStates';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { ModalLoadRips } from 'pages/read-documents/components/ModalLoadRips';
import { useDispatch } from 'react-redux';
import { ModalLoadSupports } from 'pages/read-documents/components/ModalLoadSupports';

export const SubmitiInvoice = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { id }= useParams();
  const { auditDetails } = useSelector((state: RootState) => state);
  const { callGetDetailedDocument } = useDetailedConsultation();
  const { billingState } = auditDetails;
  const [activeStep, setActiveStep] = React.useState(0);
  const [isHadleSubmit, setIsHadleSubmit] = React.useState(false);
  const [isNextPage, setIsNextPage] = React.useState(false);

  useEffect(() => {
    if(isNextPage){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsNextPage(false)
    }
  },[isNextPage])

  const onNavigateBack = () => navigate(-1);

  const {ColorlibConnector, ColorlibStepIcon } = useStyleSubmitInvoice();

  const steps = [
    {
      label: "Clasificación de factura",
      description: <PrepareInvoice isHadleSubmit={isHadleSubmit} setIsHadleSubmit={setIsHadleSubmit} setIsNextPage={setIsNextPage} />,
    },
    {
      label: "Cargar RIPS",
      description: <ModalLoadRips idDoc={id} isHadleSubmit={isHadleSubmit} setIsHadleSubmit={setIsHadleSubmit} setIsNextPage={setIsNextPage} />,
    },
    {
      label: "Cargar soportes",
      description: <ModalLoadSupports isHadleSubmit={isHadleSubmit} setIsHadleSubmit={setIsHadleSubmit} idDoc={id} />,
    },
  ];

  const handleNext = () => {
    setIsHadleSubmit(true)
  };

  const handleBack = () => {
    if(activeStep === 0){
      navigate(-1);
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIsNextPage(false)
    }
  };

  useEffect(() => {
    if(billingState.code !== '' && !statesActivities.received.includes(billingState.code)){
      navigate('/');
    } 
    const currentStep = billingState.code === activities.FacturaClasificada ? 1 
                          : billingState.code === activities.RIPSValidados ? 2 : 0
    setActiveStep(currentStep);
  }, [billingState])

  useEffect(() => {
    callGetDetailedDocument(id || '');
    dispatch.menuDrawer.changeSelectedIndex(1);
    return ()=>{
      dispatch.auditDetails.setCleanBillingState()
      dispatch.linkSupports.cleanSupports()
      dispatch.linkSupports.cleanState()
    }
  }, [])

  return (
    <Layout>
      <div className={style.containerSeeContact} data-testid="see-contact">
        <section className={style.conteinerHeader}>
            <Typography className={style.title}> Radicar Facturas </Typography>
            <IconButton
              className={`${style['viewButton']} ${style['noHover']}`}
              onClick={onNavigateBack}
              data-testid="button-back"
            >
              <img src={Image.iconoCerrar} className={`${style['sizeImg']}`} alt="cerrar" />
            </IconButton>
        </section>

        <section className={style.containerStepper}>
          <Stepper 
            activeStep={activeStep}
            connector={<ColorlibConnector/>}
            className={style.contentStepper}
            alternativeLabel
          >
            {steps.map((label, index) => {
              return (
                <Step key={label.label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} className={style.stepLabel} color='#6D6D6D'>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </section>

        <section className={style.contentFormulario}>
          <div className={style.formContainer}>
            {steps[activeStep].description}
          </div>

          <div className={style.containerButtons}>
            <Button
              className={style.buttonCancel}
              onClick={handleBack}
              startIcon={ activeStep >= 1 ? <ArrowBackIosIcon/> : null}
            >
              { activeStep >= 1 ? "Anterior" : "Cancelar"}
            </Button>
            <Button
              className={style.buttonNext}
              onClick={() => {
                  handleNext();
              }}
              endIcon={activeStep === steps.length - 1 ?  null : <ArrowForwardIosIcon/>}
            >
              {activeStep === steps.length - 1 ? "Radicar factura" : "Siguiente"}
            </Button>
          </div>
        </section>

      </div>
    </Layout>
  )
}
