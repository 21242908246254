import React, { useEffect, useState } from 'react';
import { Layout } from 'shared/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { TableComponent } from 'shared/components/Table.component';
import styles from './styles/autorizationPayments.module.scss';
import { FormFilter } from './components/FormFilter';
import { POST_PAYMENT_AUTHORIZATION_DETAILS } from 'shared/api';
import { columnsTableAutorizations } from './helper/columnsTableAutorizations';
import { MenuActions } from './components/MenuActions';
import { fieldsFormAutorizations } from './helper/fieldsFormAutorizations';
export const AutorizationPayments = () => {

  const dispatch = useDispatch<Dispatch>();
  const { electronicDocuments, optionsDropdowns } = useSelector((state: RootState) => state);
  const {content, url, paramsUrl} = electronicDocuments;
  const [hasContract, setHasContract] = useState("false");
  const [columns, setColumns] = useState<any[]>([]);
  const [ rowSelected, setRowSelected ] = useState<number[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [dataFields, setDataFields] = useState<any[]>([]);
  const [summationRows, setSummationRows] = useState([
    {
      name: 'Recuento',
      value: "0"
    },
    {
      name: 'Recuento de prestadores de Salud',
      value: "0"
    },
    {
      name: 'Total',
      value: "0"
    },
  ])

  const fillSummationRows = (newSelected: number[])=> {
    const matchingElements = content!.filter((item:any)=> newSelected.includes(item.id));
    
    const sumTotalValue = matchingElements.reduce((total, item:any)=> total + item.authorizationValue, 0);
    const totalProviders = matchingElements.reduce((providers: any, item:any)=> {
      const prov = providers.some((x:any)=> x === item.authorizationValue)
      if(prov){
        return [...providers]
      }

      return [
        ...providers,
        item.authorizationValue
      ]
    }, []);

    const newValuesSummation = [
      {
        name: 'Recuento',
        value: matchingElements.length.toString()
      },
      {
        name: 'Recuento de prestadores de Salud',
        value: totalProviders.length.toString()
      },
      {
        name: 'Total',
        value: sumTotalValue.toLocaleString()
      },
    ]
    setSummationRows(newValuesSummation);
  }
  
  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>, id: any) => {
    const selectedIndex = rowSelected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(rowSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(rowSelected.slice(1));
    } else if (selectedIndex === rowSelected.length - 1) {
      newSelected = newSelected.concat(rowSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        rowSelected.slice(0, selectedIndex),
        rowSelected.slice(selectedIndex + 1),
      );
    }
    setRowSelected(newSelected);

    fillSummationRows(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = content?.map((x) => x.id);
      setRowSelected(newSelected || []);
      fillSummationRows(newSelected!);
      return;
    }
    setRowSelected([]);
    fillSummationRows([]);
  };

  const handleChangeContract = (event: any) => {
    setHasContract(event.target.value);
    dispatch.electronicDocuments.getElectronicDocuments({...paramsUrl, isContract: event.target.value}, url);
  };

  const valuesResultsPages = [
    50,100,250,500
  ];

  useEffect(() => {
    dayjs.locale("es");
  }, [])

  useEffect(() => {
    const columnsTransform:any[] = [
      ...columnsTableAutorizations(),
      {
        field: 'actions',
        headerName: '',
        minWidth: 120,
        renderCell(params:any) {
            return (
                <MenuActions
                    id={params.electronicDocument?.id || ''}
                    idPayment={params.id}
                />
            )
          },
      },
    ]
    setColumns(columnsTransform)
  }, [electronicDocuments.content])

  useEffect(() => {
    const fields = fieldsFormAutorizations(optionsDropdowns)
    setDataFields(fields)
  }, [optionsDropdowns])

  useEffect(() => {
    optionsDropdowns.users.length === 0 && dispatch.optionsDropdowns.getUsers();
    optionsDropdowns.paymentsLines.length === 0 && dispatch.optionsDropdowns.getPaymentsLines();
    optionsDropdowns.paymentsStates.length === 0 && dispatch.optionsDropdowns.getPaymentStates();
  },[])


  useEffect(() => {
    dispatch.menuDrawer.changeSelectedIndex(3);
    dispatch.electronicDocuments.getElectronicDocuments({isContract: hasContract, stateId: ''}, `${POST_PAYMENT_AUTHORIZATION_DETAILS}/${1}/page?`);
    return () => {
      dispatch.electronicDocuments.cleanState()
    }
  }, [])
  

  return (
    <Layout>
      <Typography className={styles.titleMain}>Autorizaciones de pagos</Typography>
      <ToggleButtonGroup
        color="primary"
        value={hasContract}
        exclusive
        onChange={handleChangeContract}
        aria-label="contract"
        className={styles.swichtContract}
        data-testid='toogle-filter-contract'
      >
        <ToggleButton value={"false"} className={`${hasContract == "false" && styles.buttonContract }`}>Sin contrato</ToggleButton>
        <ToggleButton value={"true"} className={`${hasContract == "true" && styles.buttonContract }`}>Con contrato</ToggleButton>
      </ToggleButtonGroup>
      <Button 
        className={styles.authorizationBtnFilters}
        onClick={()=> {setShowForm(!showForm)}}
        endIcon={<FilterAltIcon/>}
        variant='contained' 
        data-testid='btn-filters-autorization'
      >
        Filtrar
      </Button>

      {
        showForm &&
        <FormFilter dataFields={dataFields}/>
      }

      <TableComponent 
          columns={columns}
          title=''
          valuesResultsPages={valuesResultsPages}
          rowSelected={rowSelected}
          selectAllRows={handleSelectAllClick}
          selectRow={handleChangeCheck}
        />

        <Box className={styles.containerSummationsRows}>
          {
            summationRows.map((item, index) =>
              <Box key={index} className={styles.contentSummation}><span>{item.name}: </span>{item.value}</Box>  
            )
          }
        </Box>

    </Layout>
  )
}
