import { useEffect, useRef, useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import Info from '@mui/icons-material/Info';
import { RichTextEditor } from 'shared/components';
import { useTabAuditDetail } from '../hooks/useTabAuditDetail';
import { removeHTMLTagsFromText } from 'shared/utils';
import _ from "lodash";
import style from '../styles/detailedConsultation.module.scss';
import { convertToAuditPatchRequest } from '../helpers/convertToAuditPatchRequest';
import { transformKey } from '../helpers/transformKey';

const InputTextRich = ({ config, data, setData, setLastFieldModified }: any) => {

    const [totalCaracteres, setTotalCaracteres] = useState(0);
    const [richText, setRichText] = useState('');
    const [richTextIsChanged, setRichTextIsChanged] = useState(false);
    const [loadingChanges, setLoadingChanges] = useState(false);
    const [properties, setProperties] = useState(config);
    const { callSaveAuditDetails, readOnly } = useTabAuditDetail();
    const richTextIsChangedRef = useRef<boolean>(false);

    useEffect(() => {
        const cleanText = removeHTMLTagsFromText(data[properties.id + "Text"]);
        setProperties(config);
        setTotalCaracteres(cleanText.length)
    }, [config]);

    const handleChange = (event: any) => {
        setData({
            ...data,
            [properties.id + "Question"]: event.target.value === "true",
        });
        if([false, true].includes(event?.target?.checked)) {
            callSaveAuditDetails({
                ...convertToAuditPatchRequest({
                    ...data,
                    [properties.id + "Question"]: event.target.value === "true",
                }, `${properties.id + "Question"}`),
            })?.finally(() => {
                setLoadingChanges(false);
            })
        }
    };

    const setsStatesChangeValue= (textRichValue: any)=>{
        const cleanText = removeHTMLTagsFromText(textRichValue);
        setRichText(textRichValue);
        setData({
            ...data,
            [properties.id + "Text"]: cleanText.length < 1 ? '<p><br></p>' : textRichValue,
        });
        setTotalCaracteres(cleanText.length);
        setLastFieldModified({
                [properties.id + "Text"]: textRichValue,
                fieldName: transformKey(`${properties.id + "Text"}`),
                value: textRichValue
        })
        setRichTextIsChanged(true);
    }

    const onKeyup = (value:any) => {
        !richTextIsChangedRef.current && setsStatesChangeValue(value);
        richTextIsChangedRef.current = true;
    }

    const onChangeRichText = (richTextProp: any) => {
        if(richTextIsChangedRef.current){
            setsStatesChangeValue(richTextProp);
        }
    };

    const saveAuditDetailsOnChange = () => {
        if (richTextIsChanged) {
            setLoadingChanges(true);
            callSaveAuditDetails({
                ...convertToAuditPatchRequest({ ...data }, `${properties.id + "Text"}`),
                [properties.id + "Text"]: richText
            })?.finally(() => {
                setRichTextIsChanged(false);
                setLoadingChanges(false);
            })
        }
    };

    const TitleTooltip = ({ text }: any) => {
        return (
            <>
                <div>
                    {text}
                </div>
                <div className={style.containerLinks}>
                    <a href="https://siinformacap.dssa.gov.co/Login.aspx" target='_blank' rel="noreferrer">Ir a SIINFORMACAP</a>
                    <a href="https://portal.sisben.gov.co/Paginas/consulta-tu-grupo.html" target='_blank' rel="noreferrer">Ir a SISBEN</a>
                    <a href="https://www.adres.gov.co/consulte-su-eps" target='_blank' rel="noreferrer">Ir al ADRES</a>
                    <a href="https://apps.migracioncolombia.gov.co:8443/consultappt/" target='_blank' rel="noreferrer">Ir a Migración Colombia</a>
                    <a href="https://ruaf.sispro.gov.co/Filtro.aspx" target='_blank' rel="noreferrer">Ir a RUAF</a>
                </div>
            </>
        )
    }

    return (
        <Box className={style.field} key={properties.id} data-testid='input_text_rich'>
            <span className={style.headerFile}>
                <Tooltip title={properties.id === "validateRight" ? <TitleTooltip text={properties.tooltip} /> : properties.tooltip} >
                    <Info fontSize='small' color="primary" data-testid='tooltip'/>
                </Tooltip>
                <Typography className={style.titleDetails}>{properties.label} </Typography>
                {
                    <span className={style.groupRadioButtons}>
                        <label className={style.titleDetails}>
                            <input type="radio" data-testid='radio_button_si' name={properties.id + "Question"} disabled={readOnly || properties.disable} checked={data[properties.id + "Question"] === true} value="true" onChange={handleChange} /> Si
                        </label>
                        <label className={style.titleDetails}>
                            <input type="radio" data-testid='radio_button_no' name={properties.id + "Question"} disabled={readOnly || properties.disable} checked={data[properties.id + "Question"] === false} value="false" onChange={handleChange} /> No
                        </label>
                    </span>
                }
            </span>
            <div style={{ border: 'solid 1px' }}>
                <RichTextEditor
                    onKeyup={onKeyup}
                    onChange={onChangeRichText}
                    onBlur={saveAuditDetailsOnChange}
                    readOnly={properties.disable || readOnly}
                    value={data[properties.id + "Text"]}
                    propWithMention='custom-ql-height-auto'
                />
            </div>
            <span className={style.titleDetails} data-testid='total_caracteres'
            >
                {totalCaracteres}/5000
                {
                    loadingChanges &&
                    <p>Guardando cambios...</p>
                }
            </span>
        </Box>
    );
}

export default InputTextRich;