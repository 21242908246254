import {AuthProvider, AuthService, useAuth} from "./oauh2-with-pkce";
import {Navigate, useLoaderData} from "react-router-dom";
import {JWTIDToken} from "./oauh2-with-pkce/AuthService";
import { getOAuthConfigByLoginType } from './oauh2-with-pkce/config/oauth2ServerProps';

function LoginSuccessContent() {
  const {authService} = useAuth();
  return authService.isAuthenticated() ? ( <Navigate to={'/'}/> || <></> ) : <></>
}

export function LoginSuccess() {
  const userType = (useLoaderData() as ('I' |'E' | null ));
  const oauth2Service: AuthService = new AuthService<JWTIDToken>(getOAuthConfigByLoginType(userType));
  return (
      <AuthProvider authService={oauth2Service}>
        <LoginSuccessContent/>
      </AuthProvider>
  );
}