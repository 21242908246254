export const activities = {
    FacturaRecibida: "1",
    FacturaClasificada: "1.9",
    SoportesCargados: "3",
    SoportesError: "6.2",
    RIPSValidados: "4",
    SoportesValidados: "5",
    AnuladaPorNotaCredito: "1.3",
    AnuladaPorAdminTFE: "1.4",
    AsignadaParaAuditoria: "7",
    FacturaProvisionada: "7.4",
    FacturaSinGlosa: "7.1",
    FacturaConGlosa: "7.2",
    facturaGlosasEnviadas: "7.2.1",
    facturaGlosasRespondidas: "7.2.2",
    facturaGlosasConsolidadas: "7.2.3",
    facturaGlosasConsolidadasAceptadas: "7.2.4",
    facturaGlosasNoAcuerdo: "7.2.5",
    facturaConCDP: "9.1",
    facturaConRPC: "9.2",
    facturaConAutorizacionPagos: "9.3",
};


export const linesStates = {
    validationTail: [
        activities.FacturaClasificada,
        activities.SoportesError,
        activities.SoportesCargados,
        activities.RIPSValidados,
        activities.SoportesValidados,
        activities.AnuladaPorNotaCredito,
        activities.AnuladaPorAdminTFE,
        activities.FacturaProvisionada,
    ],
    auditTail: [
        activities.FacturaSinGlosa,
        activities.FacturaConGlosa,
        activities.AsignadaParaAuditoria,
        activities.facturaGlosasEnviadas,
        activities.facturaGlosasRespondidas,
        activities.facturaGlosasConsolidadas,
        activities.facturaGlosasConsolidadasAceptadas,
        activities.facturaGlosasNoAcuerdo,
    ],
    paymentsTail: [activities.facturaConCDP, activities.facturaConRPC, activities.facturaConAutorizacionPagos],
};

export const statesActivities = {
    received: [
        activities.FacturaRecibida,
        activities.FacturaClasificada,
        activities.SoportesError,
        activities.SoportesCargados,
        activities.RIPSValidados,
        activities.SoportesValidados,
    ],
    annulled: [
        activities.AnuladaPorNotaCredito,
        activities.AnuladaPorAdminTFE,
    ],
    audit: [
        activities.facturaGlosasEnviadas,
        activities.facturaGlosasRespondidas,
        activities.facturaGlosasConsolidadas,
        activities.facturaGlosasConsolidadasAceptadas,
        activities.facturaGlosasNoAcuerdo,
    ],
    payments: [activities.facturaConCDP, activities.facturaConRPC, activities.facturaConAutorizacionPagos],
    acceptAuditConsolidation: [
        activities.facturaGlosasConsolidadas,
        activities.facturaGlosasConsolidadasAceptadas,
        ...linesStates.paymentsTail
    ],
    acceptanceOfTheAuditResult: [
        activities.facturaGlosasConsolidadasAceptadas,
        ...linesStates.paymentsTail
    ],
}
