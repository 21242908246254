import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { apiInstance } from "shared/api/config/apiInstance";
import { IStatesGlosas } from "shared/models";
import { DOCUMENT_GLOSSAS, GET_DATA_MASTER_GLOSSAS } from "shared/api";
import { convertGlossasDocument } from "shared/mappers/convertGlossasDocument";
import { convertCodesGlossas } from "shared/mappers/convertCodesGlossas";
import { IGlosasResponseConvert } from "shared/models"

const stateinitial = {
  initialValues: {
    id: null,
    idGlosa: null,
    codeGlosa: '',
    nameGlosa: '',
    valueGlosa: '',
    statusGlosa: '',
    justification: '',
    valueAccept: 0,
    valueSustained: 0,
    valueNotAccept: 0,
    glossAnswer: '',
  },
  isUpdating: false,
  glosas:[],
  codesAllGlosas: [],
  codesGlosas: [],
  glossesTotalValue: null,
  allGlossConsolited: undefined,
  edocumentPaymentAuthorization: {
    createdAt:'',
    createdBy: '',
    glossesGeneralDetail:''
  },
  sumValuesAllValueNotAccept: 0,
  sumValuesAllValueSustained: 0,
  sumValuesAllValueAccept: 0
}

export const consolidateGlosas = createModel<RootModel>()({
  state: stateinitial as IStatesGlosas,
  reducers: {
    listGlosas( state, payload:IGlosasResponseConvert ){
        return{
          ...state,
          glosas: payload.glossesEDocuments,
          isUpdating: false,
          initialValues: {
            id: null,
            idGlosa: null,
            codeGlosa: '',
            nameGlosa: '',
            valueGlosa: '',
            statusGlosa: '',
            justification: '',
            valueAccept: 0,
            valueSustained: 0,
            valueNotAccept: 0,
            glossAnswer: '',
          },
          glossesTotalValue: payload.glossesTotalValue,
          edocumentPaymentAuthorization: payload.edocumentPaymentAuthorization,
          answerConsolited:payload.answerConsolited,
          glossSupport: payload.glossSupport,
          allGlossConsolited: payload.allGlossConsolited,
          sumValuesAllValueAccept: payload.sumValuesAllValueAccept,
          sumValuesAllValueSustained: payload.sumValuesAllValueSustained,
          sumValuesAllValueNotAccept: payload.sumValuesAllValueNotAccept,
        }
    },
    updateGlosas( state, payload ){
        return{
          ...state,
          initialValues: {
            ...payload,
            nameGlosa: `${payload.codeGlosa} - ${payload.nameGlosa}`,
            codeGlosa: payload.idGlosa,
            id: payload.id
          },
          isUpdating: true
        }
    },
    deleteGlosa( state, payload ){
      return{
        ...state,
        initialValues: payload,
      }
    },
    addAllCodesGlosas( state, payload ){
      return{
        ...state,
        codesAllGlosas: payload,
        codesGlosas: payload
      }
    },
    updateCodesGlosas( state, payload ){
        return{
          ...state,
          codesGlosas: state.codesAllGlosas.filter(x => x.name.toLowerCase().includes(payload.toLowerCase()))
        }
    },
    cleanState(state) {
      return{
        ...state,
        ...stateinitial
      }
    }
  },
  effects: (dispatch) => ({
    async getlistGlosasAsync(id, state) {
      const response = await apiInstance.get(`${DOCUMENT_GLOSSAS}/${id}`);
      dispatch.consolidateGlosas.listGlosas(convertGlossasDocument(response.data))
    },

    async addGlosasAsync(glosa, state) {
      apiInstance.post(`${DOCUMENT_GLOSSAS}`, glosa).then(()=>{
        dispatch.consolidateGlosas.getlistGlosasAsync(glosa.electronicDocumentId)
      })
    },

    async updateGlosasAsync(glosa, state) {
      apiInstance.put(`${DOCUMENT_GLOSSAS}/${glosa.id}`, glosa).then(()=>{
        dispatch.consolidateGlosas.getlistGlosasAsync(glosa.electronicDocumentId)
      })
    },

    async deleteGlosasAsync(glosa, state) {
      apiInstance.put(`${DOCUMENT_GLOSSAS}/${glosa.id}`, glosa).then(()=>{
        dispatch.consolidateGlosas.getlistGlosasAsync(glosa.electronicDocumentId)
      })
    },
    async patchGlosasAsync(id, state) {
      state.consolidateGlosas = stateinitial
    },

    async getCodesGlosasAsync(_:void, rootState) {
      const response = await apiInstance.get(`${GET_DATA_MASTER_GLOSSAS}`);
      dispatch.consolidateGlosas.addAllCodesGlosas(convertCodesGlossas(response.data))
    },
  }),
})