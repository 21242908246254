import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { ICardItem } from '../types/ICardItem';
import { getCharsTotal } from '../helpers/getCharsTotal';
import { RouterGuard } from 'shared/components';
import Image from '../../../shared/assets/index';
import { CardSeeMore } from './CardSeeMore';
import styles from '../styles/card.module.scss';

export const CardReport:React.FC<{card:ICardItem}> = ({card}) =>  {

    const anchorRef = useRef(null);
    const [charDescription, setCharDescripcion] = useState<number>(143)


    const handleResize = ()=>{
        const numeroCaracteres = getCharsTotal();
        setCharDescripcion(numeroCaracteres)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box className={styles.containerMain}>
            <Card
                id={'card_filter'}
                data-testid={`card_filter_${card.id}`}
                className={styles.containerCardItem}
                ref={anchorRef}
            >
                <div className={styles.contentTitleImg}>
                    <span className={styles.contentImgLogo } data-testid='contenido_logo'>
                        {
                                <img id={`img_card_item_error_${card.id}`} data-testid={`img_card_item_error_${card.id}`}
                                    src={Image.iconoDefault}
                                    alt="" className={styles.imgLogoError}
                                />
                        }
                    </span>
                    <div className={styles.contentTitle}>
                        <Typography data-testid={`title_${card.id}`}>
                            {card.title}
                        </Typography>
                    </div>
                </div>

                

                <Box className={styles.bodyContainer} >
                    <Typography className={styles.textoDescripcion}>
                        {
                            card.description && card.description.length > charDescription ?
                                <>
                                    <span>
                                        {card.description.slice(0, charDescription)}
                                        <span
                                            className={styles.verDescripcion}
                                        >
                                            ...
                                        </span>
                                    </span>
                                </>
                                : card.description
                        }
                    </Typography>

                </Box>

            </Card>
            <CardSeeMore
                item={card}
            />
        </Box>

    </Grid>
  )
}
