import Swal from "sweetalert2";

export const downloadDocument = (resp: any, title:string, text: string, result: 'success' | 'error') => {

  const link = document.createElement('a');
  const fileName = resp.headers.get("Content-Disposition")?.split("filename=")[1];
  const blobURL = window.URL.createObjectURL(new Blob([resp.data]));
  
  link.href = blobURL;
  link.setAttribute('download', fileName?.slice(1,-1));
  
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(blobURL);

  link.remove();

  Swal.fire({
    title: title,
    text: text,
    icon: result,
    timer: 8500,
  });
  
};