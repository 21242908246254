import { GET_ELECTRONIC_DOCS } from '..';
import { apiInstance } from '../config/apiInstance';

interface Props{
    url:string;
    urlByParams?: any;
    paramsUrl?: any
}

export const getFileToExport = async({ paramsUrl, url, urlByParams}:Props): Promise<any> => {
    const response = await apiInstance.get(`${GET_ELECTRONIC_DOCS}/${url || "download"}${urlByParams ? '?'+urlByParams : '' }`, { 
        responseType: 'blob',
        timeout: 0,
        headers:{
          'X-Timezone-Id': 'America/Bogota', 
          'Accept-Language': 'es-CO',
        },
        params: paramsUrl
      });
    
    return response;
}