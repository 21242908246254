import { Box, Button, Tooltip, Typography } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { ICardItem } from '../types/ICardItem'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadForOffline } from '@mui/icons-material';
import { getDownloadReports } from 'shared/api/services/getDownloadReports';
import '../styles/_cardSeeMore.scss';
import { Spinner } from 'shared/components';
import { DatesToGenerateReports } from './DatesToGenerateReports';

export const CardSeeMore: React.FC<{item:ICardItem}> = ({item}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { pageSize, page } = useSelector((state: RootState) => state.reportSingle);
  const [loading, setLoading] = useState(false)

  const previewReport = ()=> {
    const id = item.id;
    dispatch.messageModal.setMessageModal({
        show: true, 
        title: 'Visualizar reportes',
        description:( 
            <DatesToGenerateReports 
                titleOK="Aceptar"
                onClose={()=> dispatch.messageModal.cleanStateMessage()}
                onOk={(initialDate, finalDate) => {
                    setLoading(true)
                    dispatch.reportSingle.updateRangeDate({initialDate, finalDate});
                    dispatch.reportSingle.getReportSingleAsync({id, params:`page=${page+1}&size=${pageSize}&dateStart=${initialDate}&dateEnd=${finalDate}`})?.then((response)=>{
                        setLoading(false)
                        if (response.data.content.length > 0) {
                            dispatch.messageModal.cleanStateMessage() 
                            navigate(`/reportes/${id}`);
                        }else{
                            dispatch.messageModal.cleanStateMessage()
                            messaError('El Reporte solicitado no tiene información disponible para visualizar', 'Visualizar reportes')
                        }
                    }).catch((error) => {
                        setLoading(false)
                        messaError('El Reporte solicitado no tiene información disponible para visualizar', 'Visualizar reportes')
                    }); 
                }}
            />
        ),
        onClose() { dispatch.messageModal.cleanStateMessage() },
        size: 'small'
    })
  }

  const downloadReports = ()=> {
    dispatch.messageModal.setMessageModal({
        show: true, 
        title: 'Descargar reportes',
        description:( 
            <DatesToGenerateReports 
                titleOK="Descargar"
                onClose={()=> {
                    dispatch.messageModal.cleanStateMessage()
                    setLoading(false)   
                }}
                onOk={(initialDate, finalDate) => {     
                    setLoading(true)
                    dispatch.reportSingle.getReportSingleAsync({ id: item.id, params:`page=${page+1}&size=${pageSize}&dateStart=${initialDate}&dateEnd=${finalDate}`})?.then((response)=>{
                        setLoading(false)
                        dispatch.messageModal.cleanStateMessage()
                        if (response.data.content.length > 0) {
                            setLoading(true)
                            getDownloadReports({
                                id: item.id,
                                text:"Reportes descargados con Exito",
                                params: `&dateStart=${initialDate}&dateEnd=${finalDate}`
                            }).then(() => {
                                setLoading(false)
                                dispatch.messageModal.cleanStateMessage()   
                            }).catch((error) => {
                                setLoading(false)
                                messaError('El Reporte solicitado no tiene información disponible para descargar', 'Visualizar reportes')
                            })
                        }else{
                            setLoading(false)
                            messaError('El Reporte solicitado no tiene información disponible para descargar', 'Visualizar reportes')
                        }
                    }).catch((error) => {
                        setLoading(false)
                        messaError('El Reporte solicitado no tiene información disponible para descargar', 'Visualizar reportes')
                    })
                }}
            />
        ),
        onClose() { dispatch.messageModal.cleanStateMessage() },
        size: 'small'
    })
  }

  const messaError = (description: string, title: string) => {
    dispatch.messageModal.setMessageModal({
        show: true, 
        title: title,
        description: description,
        onClose() {
            dispatch.reportSingle.clearState()
            dispatch.messageModal.cleanStateMessage()
        },
        onOk(){
            dispatch.reportSingle.clearState()
            dispatch.messageModal.cleanStateMessage()
        },
        size: 'medium',
        OkTitle: 'Aceptar'
      })
  }
  
  return (
    <Fragment>
        <Box className='containerSeeMore' data-testid='contenedor_ver_mas'>
            <Box className="box">
                <div className="contenidoTexto">  
                    <Typography className="textoTituloInfomacion" data-testid='texto_titulo_informacion'>
                        {item.title}
                    </Typography>
                    <div className="contenidoDescripcion">
                        <Typography className="textoDescripcionInformacion" data-testid='texto_descripcion_informacion'>
                            {item.description}
                        </Typography>
                    </div>
                </div>
                <div className="contenidoIconoVer">
                    <Tooltip title={'Ver reportes'} placement="left" arrow>
                        <Button 
                            className="iconButtonContent" 
                            startIcon={<VisibilityIcon/>} 
                            data-testid='btn_icono_ver_contenido' 
                            onClick={previewReport}
                        />
                    </Tooltip>
                    <Tooltip title={'Descargar reportes'} placement="left" arrow>
                        <Button
                            className="iconButtonContent"
                            startIcon={ <DownloadForOffline/>} 
                            data-testid="btn-dowload"
                            onClick={downloadReports}
                        />       
                    </Tooltip>

                </div>
            </Box>
        </Box>
      
        {(loading) && <Spinner/>}

    </Fragment>
  )
}
