import { styled } from "@mui/material/styles";

export const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 27,
    height: 27,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.5s ease-in-out",
    ...(ownerState.active && {
      background: "rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)",
    }),
    ...(ownerState.completed && {
      background: "rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)",
    }),
    ...(!ownerState.completed && {
      background: "#D7D7D7",
      border: "1px solid rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)"
    }),
}));