import { createModel } from "@rematch/core";
import { apiInstance } from "shared/api/config/apiInstance";
import { RootModel } from "../rootModel";
import { REPORTS } from "shared/api";
import { IOrder, IReportSingle, UpdateReports } from "shared/models/store/IReportSingle";
import { renderCellExpand } from "shared/components/GridCellExpand";

export const reportSingle = createModel<RootModel>()({
  state: {
    data: [],
    columns: [],
    page:0,
    pageSize: 10,
    totalItems: 0,
    search: '',
    reportDefinition:{
      id: 0,
      description: '',
      title: '',
    },
    order:{
      field: '',
      sort: 'asc'
    },
    rangeDate: {},
    size: 0
  } as IReportSingle,
  reducers: {
    addReports(state, payload){
      return {
        ...state,
        data: payload?.content?.map((row:string[], index:number) => {
          const object:any = {$key: index};
          row.forEach((value, subIndex) => {
            object[`${payload.headers[subIndex]}`]= value;
          })
          return object;
        }),
        totalItems: payload?.totalElements,
        columns: payload?.headers.map((col:any, index: number) =>{ 
          return{
            field: col,
	          headerName: col,
            width:col.length * 11,
            minWidth: 160,
            renderCell: renderCellExpand,
          }
        }),
        reportDefinition:{
          id: payload?.reportDefinition.id,
          description: payload?.reportDefinition.description,
          title: payload?.reportDefinition.title,
        },
        page: payload?.page,
        size: payload?.size === 10 ? 10 : payload?.size,
      };
    },
    orderData(state,payload) {
      return {
        ...state,
        data: payload
      };
    },
    updateReports (state, payload:UpdateReports) {
      return {
        ...state,
        pageSize: payload.limit,
        page: payload.page,
        search: payload.search || ''
      };
    },
    updateSort (state, payload:IOrder) {
      return {
        ...state,
        order: {
          field: payload.field,
          sort: payload.sort
        }
      };
    },
    updateRangeDate (state, payload:any) {
      return {
        ...state,
        rangeDate: {
          initialDate: payload.initialDate,
          finalDate: payload.finalDate
        }
      };
    },
    clearState(state) {
      return {
        ...state,
        data: [],
        columns: [],
        page:0,
        pageSize: 10,
        totalItems: 0,
        search: '',
        reportDefinition:{
          id: 0,
          description: '',
          title: '',
        },
        order:{
          field: '',
          sort: 'asc'
        },
        rangeDate: {
          initialDate: '',
          finalDate: ''
        },
        size: 0
      };
    }
  },
  effects: (dispatch) => ({
    async getReportSingleAsync(payload) {
        
      const response = await apiInstance.get(`${REPORTS}/${payload.id}/generate?${payload.params}`);

      dispatch.reportSingle.addReports(response?.data);
      return response;
    },
  }),
});