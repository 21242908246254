import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { SearchItems } from '../../../shared/components/paginator/SearchItems'
import { BtnOrderItems } from '../../../shared/components/paginator/BtnOrderItems'
import Image from '../../../shared/assets/index';
import { Dispatch, RootState } from 'shared/store/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { debounceFunction } from 'shared/utils/helpers/debounceFunction';
import '../styles/_filterAndSort.scss'

export const FilterAndSort = () => {
    const dispatch = useDispatch<Dispatch>();
    const { dataView, totalElements } = useSelector((state: RootState) => state.reports);
    const { open, text } = useSelector((state: RootState) => state.searcher);
    const agregarDatosOrdenados = (datos: any[]) => {
        dispatch.reports.orderData(datos)
    };

    useEffect(() => {
        open &&
        debounceFunction(() => {
            dispatch.reports.getDataReportsAsync(`limit=${12}&title=${text}`)?.then(()=> 
                dispatch.reports.updateReports({limit: 12, search:text}))
        });
    }, [text]);

  return (
    <Box className="containerFilter" data-testid="container-filter">
        <Box className="containerSearch">
            <SearchItems data={dataView} placeholder='Buscar reporte'/>
        </Box>
        <BtnOrderItems
            numberPages={dataView?.length}
            content={dataView}
            addDataSort={agregarDatosOrdenados}
        />
        <Box className="containerPages">
            <img src={Image.iconoSearch} alt="Buscar" className="imgPaginacion" />
            <Box className="pagesText">
                <p data-testid="total-elements">{dataView?.length || 0}/{totalElements || 0}</p>
            </Box>
        </Box>
    </Box>
  )
}
