import React, { ReactElement } from 'react'
import { Box, Modal, Typography } from '@mui/material';
import styles from './styles/Modal.module.scss'

interface Props {
    title?: string;
    subtitle?: string;
    children?: ReactElement;
    hidden?: boolean;
    open: boolean;
    onClose: ()=> void;
}

export const ModalFiles = ({title,subtitle,children,open,hidden,onClose}:Props) => {

  return (
    <>
        <Modal
            open={open}
            onClose={()=> onClose()}
            aria-labelledby="modal-upload"
            id="modal-upload"
            data-testid="modal-upload"
            sx={{display: hidden ? 'none' : 'block'}}
            >
            <div>
              <Box className={styles.container} display={hidden ? 'none' : 'block'}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography className='titleMain fs-7 fs-bold my-1 center'>{title}</Typography>
                    <Typography className={styles.subtitle}><b>{subtitle}</b></Typography>
                </Box>
                {children}
              </Box>
            </div>
        </Modal>
    </>
  )
}
