import { useState } from 'react'
import Image from 'shared/assets/index'
import { contentDataDocumentsRelated } from '../helpers/contentTableRowDetailed';
import { getData } from 'shared/api/services/getData';
import { ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, GET_ELECTRONIC_DOCS, GET_ELECTRONIC_DOCUMENTS_RELATED } from 'shared/api';
import { Dispatch } from 'shared/store/store';
import { useDispatch } from 'react-redux';

export const useTabDocumentsRelated = () => {

    const dispatch = useDispatch<Dispatch>();
    const [documents, setDocuments] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true); 
    const [creditNoteTooltipOpen, setCreditNoteTooltipOpen] = useState<boolean>(false);

    const convertServiceDocumentsRelated = (dataDocs: any) => {    
        const data = {
            icon: Image.iconoCheck,
            title: 'Documentos electrónicos relacionados a la factura',
            columns: contentDataDocumentsRelated,
            content: dataDocs?.map((item:any) => {
                return {
                    id: item.id,
                    identificationNumber: item.documentNumber || "",
                    identificationType: item.type.name || "",
                    dateDocument: item.issuedAt || "",
                    idCUDE: item.uniqueCodeElectronicBilling || "",
                    totalValue: item.totalValue || "",
                    attachment: item.attachment.id,
                    linkToGlosses: item.linkToGlosses
                }
            }) || []
        };    
        setDocuments(data);
        setIsLoading(false);
    }

    const callGetDocumentsElectronics = (idDocument: string) => {
        getData( `${GET_ELECTRONIC_DOCS}/${idDocument}${GET_ELECTRONIC_DOCUMENTS_RELATED}`).then((response: any) => {
            response?.length > 0 && convertServiceDocumentsRelated(response)
            setIsLoading(false)
        }).catch(()=> setIsLoading(false))
    }

    const downloadDocument = (idDoc:number, idAtachment: number)=>{
        dispatch.electronicDocuments.getFileExcelToExport({
            url:`${idDoc}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}?supportId=${idAtachment}`,
            text:"DOCUMENTOS descargados con Exito"
        })
    }
    
    const viewDocument = (idDocument:number, id: number)=>{
        const file= "docs";
        window.open(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/${file}/${id}`, "_blank", "noreferrer")

    }

    const handleCreditNoteTooltipOpen = (open: boolean) => {
        setCreditNoteTooltipOpen(open);
    }

    return {
        isLoading,
        documents,
        creditNoteTooltipOpen,
        callGetDocumentsElectronics,
        convertServiceDocumentsRelated,
        downloadDocument,
        viewDocument,
        handleCreditNoteTooltipOpen
    }
}
