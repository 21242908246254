import React from 'react'
import { DeleteOutline } from '@mui/icons-material'
import { Box, IconButton, Link, Typography } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import Swal from 'sweetalert2'
import styles from './styles/CustomFileUploader.module.scss'

interface Props{
  typeAccept: string[];
  fileLength: number;
  multiple?: boolean;
  filesAllowed?: number;
  originalName: string | any | null;
  document: any;
  handleChangeFile: (file: any) => void;
  removeFile: (doc?:any) => void;
  isDisabled?: boolean;
}

export const CustomFileUploader = ({
  typeAccept,
  fileLength,
  originalName,
  document,
  multiple=false,
  filesAllowed=1,
  isDisabled=false,
  handleChangeFile,
  removeFile
}:Props) => {
  const errorTipoArchivo = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `El Archivo cargado no es válido, tipo de archivo incorrecto`,
      icon: 'error',
      timer: 5500
    })
  }
  
  const errorTamanioArchivo = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `El Archivo cargado no es válido, el archivo debe ser menor a ${fileLength}MB`,
      icon: 'error',
      timer: 5500
    })
  }

  return (
    <div className={`${styles.divContentModal} ${isDisabled && styles.isDisabled}`}>
        <FileUploader
          types={typeAccept}
          multiple={multiple}
          maxSize={fileLength}
          handleChange={handleChangeFile}
          hoverTitle={' '}
          onTypeError={errorTipoArchivo}
          onSizeError={errorTamanioArchivo}
          fileOrFiles={document}
          disabled={isDisabled}
        >
          <Box className={`${styles.contentFile} ${isDisabled && styles.isDisabled}`}>
          {
              originalName && originalName.length > 0 ? (
                <Box>
                  <Typography data-testid="name-file">
                      Arrastra el archivo a cargar o haz  
                      <Link className={styles.fileDocument}><b> click aquí para seleccionar {typeAccept[0]} </b></Link>
                  </Typography>
                  <Box className={styles.contentFilesSelected}>
                  {
                    multiple ?
                      originalName.map((doc:any, i:any)=>
                          <div key={i}>
                              <IconButton onClick={()=>removeFile(doc)} data-testid="file-remove">
                                <DeleteOutline/>
                              </IconButton>
                              <Typography className={styles.textoArchivo}>{doc.originalName}</Typography>
                          </div>
                    ) :
                    <div>
                      <IconButton onClick={removeFile} data-testid='file-remove'>
                          <DeleteOutline/>
                      </IconButton>
                      <Typography>{originalName}</Typography>
                    </div>
                  }
                  </Box>
                </Box>
              ): (
              <>
                  <Typography data-testid="name-file">
                      Arrastra el archivo a cargar o
                      <Link className={styles.fileDocument}><b>click aquí para seleccionar {typeAccept[0]} </b></Link>
                  </Typography>
                  <Typography>
                      Se permite máximo <b>{filesAllowed}</b> archivo de hasta <b>{fileLength}MB</b>
                  </Typography>
                  <Typography>
                      Tipos de archivo permitidos: 
                      {
                        !multiple ? <b> {typeAccept[0]} </b> : typeAccept.map((type:string, i:number) => <b key={i}> {type}. </b>)}
                  </Typography>
              </>
              )
          }
          </Box>
        </FileUploader>
      </div>
  )
}
