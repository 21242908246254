import { Link } from "react-router-dom";
import { ITableElement } from "shared/components/interfaces/table.interface";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { formatCurrency } from "shared/utils";
import {IRespDocuments} from 'shared/models/store/IElectronicDocuments'
import { accesRoles } from "shared/models";
import { ComboBoxCustom } from "shared/components";

export const columnsTable= ( handleUserAssignment?: any, validateUserAuditor?:any): ITableElement<any>[] => {

    const columnsTable = [
      { 
         field: 'ToId',
         headerName: '',  
         minWidth: 30,
         renderCell(params:IRespDocuments){
           return(
             <Link to={`/consulta_documentos_electronicos/detalle_documento/${params.id}`} data-testid='link-detailed'>
               <AddCircleIcon />
             </Link>
           )
         }
       },
       {
         field: 'documentNumber',
         headerName: 'Número de factura',
         sortable: 'ELECTRONIC_DOCUMENTS.document_number',
         minWidth: 166,
         renderCell(params:IRespDocuments) {
           return (<div>{params.documentNumber}</div>
           )
         },
       },
       {
         field: 'issuedAt',
         headerName: 'Fecha de factura',
         sortable: 'ELECTRONIC_DOCUMENTS.issued_at',
         minWidth: 160,
         renderCell(params:IRespDocuments) {
           return (<div>{dayjs(new Date(params.issuedAt)).format('DD MMMM YYYY HH:mm')}</div>
           )
         },
       },
       {
         field: 'provider',
         headerName: 'NIT',
         sortable: 'employer_id_number',
         minWidth: 84,
         renderCell(params:IRespDocuments) {
           return (<div>{params.provider.employerIdNumber}</div>
           )
         },
       },
       {
         field: 'business_name',
         headerName: 'Razón social',
         sortable: 'business_name',
         minWidth: 180,
         renderCell(params:IRespDocuments) {
           return (<div>{params.provider.name}</div>
           )
         },
       },
       {
         field: 'totalValue',
         headerName: 'Total factura',
         sortable: 'ELECTRONIC_DOCUMENTS.total_value',
         minWidth: 130,
         renderCell(params:IRespDocuments) {
           return (<div>{formatCurrency(params.totalValue)}</div>
           )
         },
       },
       {
         field: 'stateName',
         headerName: 'Estado',
         sortable: 'ELECTRONIC_DOCUMENT_TIMELINE.LAST_TIMELINE_STAGE',
         minWidth: 170,
         renderCell(params:IRespDocuments) {
           return (<div>{params.stateName}</div>
           )
         },
       },
       {
         field: 'state',
         headerName: 'Actividad',
         sortable: 'activity',
         minWidth: 170,
         renderCell(params:IRespDocuments) {
           return (<div>{params.state.name}</div>
           )
         },
       },
       {
         field: 'users',
         headerName: 'Asignado a',
         sortable: 'USERS"."USER_NAME',
         minWidth: 180,
         rowGuard:{
           roles: accesRoles.usersSSSA
         },
         renderCell(params:IRespDocuments) {
           return (
               <ComboBoxCustom 
                   handleChange={handleUserAssignment} 
                   idsDoc={[params.id]} 
                   currentValue={params.currentUser} 
                   data={params.users!} 
                   placeholder='Asignar' 
                   isDisabled={params?.state.code === "1.3" || params?.state.code === "1.4" || validateUserAuditor()}
               />
           )
         },
       },
       {
         field: 'filingNumber',
         headerName: 'Número de radicado',
         sortable: 'ELECTRONIC_DOCUMENTS.accuse_number',
         minWidth: 176,
         renderCell(params:IRespDocuments) {
             return (<div>{params.filingNumber}</div>
             )
           },
       },
       {
         field: 'filedAt',
         headerName: 'Fecha de radicado',
         sortable: 'ELECTRONIC_DOCUMENTS.received_at',
         minWidth: 166,
         renderCell(params:IRespDocuments) {
             return (<div>{params.documentTimeline?.filedAt ? dayjs(new Date(params.documentTimeline?.filedAt)).format('DD MMMM YYYY HH:mm') : ''}</div>
             )
           },
       },
       
    ];

    return columnsTable
} 
    