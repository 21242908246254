import { GET_ELECTRONIC_DOCS } from '..';
import { apiInstance } from '../config/apiInstance';

interface Props{
    url:string;
    urlByParams?: any;
    paramsUrl?: any
}

export const exportFileGlosses = async({ url}:Props): Promise<any> => {
    const response = await apiInstance.get(`${url}`, { 
        responseType: 'blob',
        timeout: 0,
        headers:{
          'X-Timezone-Id': 'America/Bogota', 
          'Accept-Language': 'es-CO',
        }
      });
    
    return response;
}