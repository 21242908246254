import {createBrowserRouter, Navigate, redirect} from 'react-router-dom';
import {Home, Login, ReadDocuments, DetailedConsultation, ViewFiles, Maintenance, AutorizationPayments, SubmitiInvoice} from "pages";
import {LoginSuccess} from "shared/auth/LoginSuccess";
import {loginType} from 'shared/utils/helpers/loginType';
import {Root} from '../shared/auth/Root';
import {RouterGuard} from 'shared/components/RouteGuard';
import {accesRoles} from '../shared/models/roles';
import {ValidateLogin} from '../shared/auth/ValidateLogin';
import { ReportsView } from 'pages/reports-view/ReportsView';
import { ReportPreview } from 'pages/report-preview-single/ReportPreview';
import {getQueryParam} from "../shared/auth/oauh2-with-pkce/util";
import { ConsolidateGlosas } from 'pages/consolidate-glosas/ConsolidateGlosas';
import { SeeContractDetails } from 'pages/detailed-consultation/components/SeeContractDetails.tsx';


export const routerConfig = createBrowserRouter([
  {
    path: "/",
    loader: () => {
      if(!(!!getQueryParam('LT')) && !(!!loginType()))
        throw redirect('/login');
      return getQueryParam('LT') || loginType();
    },
    element: <Root/>,
    children: [
      {
        index: true,
        element: <RouterGuard roles={accesRoles.reader} type='route'><Home/></RouterGuard>
      },
      {
        path: '/consulta_documentos_electronicos',
        element: <RouterGuard roles={accesRoles.reader} type='route'><ReadDocuments/></RouterGuard>
      },
      {
        path: '/consulta_documentos_electronicos/detalle_documento/:idDocument',
        element: <RouterGuard roles={accesRoles.reader} type='route'><DetailedConsultation/></RouterGuard>
      },
      {
        path: '/consulta_documentos_electronicos/detalle_documento/:idDocument/auditar_glosas',
        element: <RouterGuard roles={accesRoles.auditor} type='route'><ConsolidateGlosas/></RouterGuard>
      },
      {
        path: '/consulta_documentos_electronicos/detalle_documento/:idDocument/ver_detalle_contrato/:idContract',
        element: <RouterGuard roles={accesRoles.auditor} type='route'><SeeContractDetails/></RouterGuard>
      },
      {
        path: '/consulta_documentos_electronicos/detalle_documento/:idDocument/:file/:id',
        element: <RouterGuard roles={accesRoles.reader} type='route'><ViewFiles/></RouterGuard>
      },
      {
        path: '/consulta_documentos_electronicos/radicar_facturas/:id',
        element: <RouterGuard roles={accesRoles.reader} type='route'><SubmitiInvoice/></RouterGuard> //TODO: Cambiar el rol
      },
      {
        path: '/reportes',
        element: <RouterGuard roles={accesRoles.usersSSSA} type='route'><ReportsView/></RouterGuard>
      },
      {
        path: '/reportes/:idReport',
        element: <RouterGuard roles={accesRoles.usersSSSA} type='route'><ReportPreview/></RouterGuard>
      },
      {
        path: '/autorizaciones',
        element: <RouterGuard roles={accesRoles.finance} type='route'><AutorizationPayments/></RouterGuard>
      },
      {
        path: '/mantenimiento',
        element: <Maintenance />
      },
      {
        path: '/*',
        element: <Navigate to="/" />
      },
    ],
  },
  {
    path: "/login",
    element: <ValidateLogin><Login/></ValidateLogin>,
  },
  {
    path: "/login/callback",
    element: <LoginSuccess />,
    loader: ({params}) => {
      if (!!params.code || !(!!loginType())) {
        throw redirect('/login');
      }
      return loginType();
    }
  }
]);

