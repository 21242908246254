
import React from 'react'
import { Grid, Typography } from '@mui/material'
import Image from 'shared/assets/index';
import style from '../../styles/detailedConsultation.module.scss'
import { IContract } from 'shared/api/services';

export interface IGeneralContract{
    data: IContract
}

export const GeneralContractInformation = ({data}: IGeneralContract) => {
    return (
        <div className={`${style.containerSeeContactInfo}`} data-testid='content_general_contract'>          
            <section className={style.containerTitleCardSeeContact}>
                <img src={Image.iconoContrato} alt="icono_doc" />
                <Typography className={style.titleCard} data-testid='title_content'>Información General del Contrato</Typography>
            </section>
            <section className={style.containerBodySeeContact}>
                <Grid container direction={"row"} className={style.contentDetails}>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Número de contrato: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={data?.contractNumber || '-'} data-testid='numero_contrato' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Fecha de inicio de contrato: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={data?.contractStartAt || '-'} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Fecha Fin de contrato: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={data?.contractEndAt || '-'} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Valor Total Contratado: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={`$ ${data?.totalValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') || '-'}`} data-testid='valor_total_contratado' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Nit contratista: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={data?.provider?.employerIdNumber || '-'} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Razón Social Contratista: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={data?.provider?.name || '-'} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Número de CDP del Contrato: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={data?.cdpNumber || '-'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={style.field} >
                        <Typography className={style.titleDetails}>Saldo Contrato: </Typography>
                        <input className={style.inputDetails} type='text' disabled value={`$ ${data?.contractBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') || '-'}`}/>
                    </Grid>
                    
                </Grid>

                <div className={style.objectContactInfo}>
                    <Typography className={style.titleInfo}>Objeto de contrato: </Typography>
                    <div className={style.contentInfo}>
                        <Typography>
                            {data?.contractObject || 'N/A'}
                        </Typography>
                    </div>
                </div>
            </section>
        </div>  
    )
}
