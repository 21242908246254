
import { Box, Button, Grid, TextField, TextFieldProps, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers/';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react'
import { FormikErrors, useFormik } from 'formik';
import styles from '../styles/dateToGenerateReports.module.scss';
import '../styles/_dateGenerate.scss';
import 'dayjs/locale/es'
import utc from 'dayjs/plugin/utc'
import { validateDateRangeByMonth } from 'shared/utils';

export interface IDatasToGenerate{
    titleOK: string;
    onOk: (initialDate: string | undefined, finalDate: string | undefined) => void;
    onClose: () => void;
}

export const DatesToGenerateReports = ({ titleOK, onOk, onClose }: IDatasToGenerate) => {

    const [valueInitialDate, setValueInitialDate] = useState<Dayjs | null>(null);
    const [valueFinalDate, setValuevalueFinalDate] = useState<Dayjs | null>(null);
    
    useEffect(() => {
        dayjs.extend(utc)
    }, [])

    const validate = () => {
        const errors: FormikErrors<any> = {};

        if(valueInitialDate === null){
           errors.initialDate = 'Campo requerido';
        }
        if(valueFinalDate === null){
            errors.finalDate = 'Campo requerido'
        }

        if (!valueInitialDate?.isValid() || !valueFinalDate?.isValid()) {
            errors.range ="Las fechas tienen un formato incorrecto"
        }

        handleFinalDateChange(valueFinalDate) && (errors.range = 'La Fecha final no puede ser anterior a la Fecha inicial');
        validateDateRangeByMonth(valueInitialDate, valueFinalDate, 4) && (errors.range = 'Por favor introduzca un rango de  fechas que no supere  4 meses');

        return errors;
    }

    const { handleSubmit, errors } = useFormik({
        initialValues: {
            initialDate: valueInitialDate,
            finalDate: valueFinalDate,
            range: ''
        },
        onSubmit: () => { onOk(dayjs.utc(valueInitialDate).format('DD/MM/YYYY'), dayjs.utc(valueFinalDate).format('DD/MM/YYYY')) },
        
        validate,
    })

    const renderInput =(params: TextFieldProps) => {
        return(
          <Box sx={{display:'flex', alignItems: 'center'}} className={styles.dataPicker}>
              <TextField 
                  {...params} 
                  onKeyDown={(e:any) => { e.which === 13 && e.preventDefault() }}
                  inputProps={{ 
                        ...params.inputProps,
                        'data-testid': `input_date_from_${params.InputProps?.name}` 
                    }}
                  focused 
              />
          </Box>
        )
    }

    const handleFinalDateChange = (newValueInitial: Dayjs | null): boolean => {
        setValuevalueFinalDate(newValueInitial);
        let errorRange = false
        if (newValueInitial && valueInitialDate && newValueInitial.isBefore(valueInitialDate)) {
            errorRange = true;
        }

        return errorRange;
    };

    return (
        <form onSubmit={handleSubmit} className={`${styles.dataToGenerate} data-generate`} data-testid='form_dates_generate' >
            <Grid container direction={"row"} className={styles.containerDateGrid} data-testid='grid_container'>
                <Grid item xs={12}  lg={6} className={ styles.containerGridItem }>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                        <DatePicker
                            label='Fecha Inicial'
                            toolbarPlaceholder='Fecha Inicial'
                            className={`${styles.contentDatePicker} `}
                            value={valueInitialDate}
                            onChange={(newValue) => { setValueInitialDate(newValue) }}
                            renderInput={(params) => renderInput(params)}
                            InputProps={{
                                name: 'initialDate',
                            }}
                            data-testid='datapicker_date_to'
                            PopperProps={{
                                className: 'pickers_popper'
                            }}
                        />
                        {
                            errors.initialDate && <Typography className={styles.errorMessage} data-testid='text_error_initial_date' >{errors.initialDate}</Typography>
                        }
                    </LocalizationProvider>   
                </Grid>

                <Grid item xs={12}  lg={6} className={ styles.containerGridItem }>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                        <DatePicker
                            label='Fecha Final'
                            toolbarPlaceholder='Fecha Final'
                            className={styles.contentDatePicker}
                            value={valueFinalDate}
                            onChange={(newValue) => { setValuevalueFinalDate(newValue) }}
                            renderInput={(params: any) => renderInput(params)}
                            InputProps={{
                                name: 'finalDate',
                            }}
                            data-testid='datapicker_date_from'
                            PopperProps={{
                                className: 'pickers_popper'
                            }}
                        />
                        {
                            errors.finalDate && <Typography className={styles.errorMessage} data-testid='text_error_final_date' >{errors.finalDate}</Typography>
                        }
                    </LocalizationProvider>
                </Grid>
                {
                    errors.range && <Typography className={styles.errorMessage} data-testid='text_error_range'>{errors.range}</Typography>
                }
            </Grid>
            <Grid container direction={"row"} justifyContent={'center'} className={styles.buttonContainer}>
                <Button type='submit' variant='contained' color='secondary' className={styles.buttonSubmit} data-testid="btn_ok">
                    {titleOK}
                </Button>
                <Button variant='contained' color='secondary' className={styles.buttonCancelar} onClick={onClose} data-testid='btn_close'>
                    Cancelar
                </Button>
            </Grid>
        </form>
    )
}
