import StepConnector, {
    stepConnectorClasses
  } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 14,
      left: "calc(-50% + 0px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)",}
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)",}
    },
    [`&.${!stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#D7D7D7",}
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
      transition: "all 0.4s ease-in-out",
      width: "110%",
    }
  }));