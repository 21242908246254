import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, GLOSSES } from 'shared/api';
import { getFileToExport } from 'shared/api/services/getFileToExport';
import { Document, Page, pdfjs } from 'react-pdf';
import { Layout } from 'shared/utils';
import './styles.scss'
import { exportFileGlosses } from 'shared/api/services/exportFileGlosses';
import { Box, Button, Typography } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export const ViewFiles = () => {

  const { id, idDocument, file } = useParams();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [blobImg, setBlobImg] = useState<string | null>(null)

  const onDocumentLoadSuccess = ({ numPages }:any) => {
    setNumPages(numPages);
  };

  const openPdf = (blobURL: any) => {
    if (blobURL) {
      // Convert Blob to base64
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result;
        setPdfData(base64Data);
      };
      reader.readAsDataURL(blobURL);
    }
  }

  const nextPage = ()=> {
    (pageNumber < numPages!) && setPageNumber(pageNumber+1)
  }

  const prevPage = ()=> {
    (pageNumber > 1) && setPageNumber(pageNumber-1)
  }

  useEffect(() => {
    file === 'docs' ? (
      getFileToExport({
        url: `${idDocument}/download-document-associate?documentAttachmentId=${id}`
      })?.then( (resp:any)=>{
        const fileName = resp.headers.get("Content-Disposition").split("filename=")[1];
        const blobURL = window.URL.createObjectURL(new Blob([resp.data]));
        fileName.split('.')[1] === 'pdf"' ? openPdf(resp.data) : setBlobImg(blobURL) ; 
      }))
      : (
        getFileToExport({
          url:`${idDocument}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}?supportId=${id}`,
        })?.then((resp:any)=>{
          const fileName = resp.headers.get("Content-Disposition").split("filename=")[1];
          const blobURL = window.URL.createObjectURL(new Blob([resp.data]));
          fileName?.split('.').pop() === 'pdf"' ? openPdf(resp.data) : setBlobImg(blobURL) ;     
        })
    )


  }, [])
  
  return (
    <Layout>
      <div  data-testid="container-pdf" className='container-pdf'>
        {
          blobImg ? <img src={blobImg} alt="img" />
          : pdfData && (
            <div>
              <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={900} className='contentFiles'/>
              </Document>
              <Box display='flex' alignItems='center' justifyContent='space-between' width={676} margin='auto'>
                <Box display='flex' alignItems='center'>
                  <Button onClick={prevPage}>
                    Anterior
                  </Button>
                  <Button onClick={nextPage}>
                    Siguiente
                  </Button>
                </Box>
                <Typography>
                  Página {pageNumber} de {numPages}
                </Typography>

              </Box>
            </div>
          )
        }
      </div>
    </Layout>
  )
}
