import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { DeleteOutline, Info } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import Swal from 'sweetalert2';
import { Spinner } from 'shared/components/Spinner';
import { Dispatch, RootState } from 'shared/store/store';
import { GET_DATA_SUPPORT_CONTAINER, GET_ELECTRONIC_DOCS } from 'shared/api';
import styles from '../styles/modalRipsSoportes.module.scss';
import { apiInstance } from 'shared/api/config/apiInstance';
import { useNavigate } from 'react-router-dom';

interface Props {
  idDoc: any;
  isHadleSubmit: boolean,
  setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ModalLoadSupports = ({idDoc, isHadleSubmit, setIsHadleSubmit}:Props) => {

    const dispatch = useDispatch<Dispatch>();
    const navigate = useNavigate();
    const { bill } = useSelector((state: RootState) => state.auditDetails);
    const { response, supports, loading } = useSelector((state: RootState) => state.linkSupports);
    const [hasErrors, setHasErrors] = useState(false);
    const typeAccept = ["PDF","JPG","PNG","DOCX","XLSX","PPTX", "HTML", "ZIP"];
    const maxLength= 5;

    const handleChangeFile = (file:any, code:string) => {
      const arrayFiles = Array.from([file])

      if (file && file.size < (5000000) ) {
        const currentDocs =  supports.map(doc => doc.code === code ? {...doc, file, originalName: file.name} : doc);
        setHasErrors(false);
        dispatch.linkSupports.addSupports(currentDocs);

        dispatch.linkSupports.getLinkAsync({
          file: arrayFiles, 
          url: `${GET_ELECTRONIC_DOCS}/${idDoc}/supports/generate-upload-link`,
          name: code
        })
      }
    };

    const errorTipoArchivo = () => {
      Swal.fire({
        title: 'Carga fallida',
        text: `El Archivo cargado no es válido, tipo de archivo incorrecto`,
        icon: 'error',
        timer: 5500
      })
    }
  
    const errorTamanioArchivo = () => {
      Swal.fire({
        title: 'Carga fallida',
        text: `El Archivo cargado no es válido, el archivo debe ser menor a ${maxLength}MB`,
        icon: 'error',
        timer: 5500
      })
    }


    const removerArchivo = (file:any) => {
      const currentDocs =  supports.map(doc => doc.code === file.code ? {code: doc.code, name:doc.name, id: doc.id} : doc);
      const filterDocuments = response?.filter(doc => doc.originalName !== file.originalName);
      setHasErrors(false);
      dispatch.linkSupports.addSupports(currentDocs);
      dispatch.linkSupports.getLink(filterDocuments);
    };

    const validatedFiles =(element:any)=> {
      return (element.code === '1' || element.code === '2' || element.code === '3' || element.code === '7') && element.hasOwnProperty('file');
    }

    const handleOnclickUpload = async() => {
      const elementsWithFile = supports.filter(validatedFiles);

      if(elementsWithFile.length === 4){
        Swal.fire({
          icon: "warning",
          title: `Atención`,
          text: `¿ Esta seguro que desea radicar la Factura # ${bill}?`,
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText:"Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch.linkSupports.isLoading(true);
            await apiInstance.post(`${GET_ELECTRONIC_DOCS}/${idDoc}/supports`, {
              attachmentIds: response?.map(res=> res.id),
            }).then((res)=>{
              Swal.fire({
                icon: "success",
                title: `Atención`,
                text: `Se ha radicado la Factura #${bill} de manera exitosa. Número de radicado: ${res.data.filingNumber}`,
                confirmButtonText: "Aceptar",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  dispatch.electronicDocuments.getElectronicDocumentAsync({id:idDoc})
                  navigate(-1);
                }
              })
            }).finally(()=>{
              dispatch.linkSupports.isLoading(false)
            });
          }
        })
      } else {
        setHasErrors(true);
        Swal.fire({
          icon: "error",
          title: `Atención`,
          text: `Debe subir los soportes obligatorios`,
          confirmButtonText: "Aceptar",
        })
      }

    }

    const TitleTooltip = () => {
      return (
          <>
              <Typography fontSize={12}>Se permite archivo de hasta <b>{maxLength}MB</b></Typography>
              <Typography fontSize={12}>Tipos de archivo permitidos: 
                {typeAccept.map((type:string, i:number) => <b key={i}> {type}. </b>)}
              </Typography>
          </>
      )
    }

    useEffect(() => {
      if (isHadleSubmit) {
        handleOnclickUpload();
        setIsHadleSubmit(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isHadleSubmit])
    
    useEffect(() => {
      async function fetchData() {
        const response = await apiInstance.get(GET_DATA_SUPPORT_CONTAINER);
        dispatch.linkSupports.addSupports(response.data);
      }
      supports.length <= 0 && fetchData();
    }, [])  

  return (
    <>
      <Grid container>
        {
          supports && supports.map((doc, index)=>(
            <Grid item xs={12} sm={6} md={4} xl={3} className={styles.containerSupports} key={index}>
                <Box display='flex' gap={1} alignItems='center'>
                  <Tooltip title={ <TitleTooltip /> } >
                      <Info fontSize='small' color="primary" />
                  </Tooltip>
                  <Typography className={`${styles.textLabel} ${doc.name.includes('(Obligatorio)') && 'text-required'}`}>{doc.name}</Typography>
                </Box>
                <div className={styles.divContentModal}>
                    <FileUploader
                      types={typeAccept}
                      maxSize={maxLength}
                      handleChange={(e:any)=> handleChangeFile(e, doc.code)}
                      hoverTitle={' '}
                      onTypeError={errorTipoArchivo}
                      onSizeError={errorTamanioArchivo}
                      fileOrFiles={doc.file}
                    >
                      <Box className={styles.contentFile}>
                        <Box data-testid="name-file" className={`${styles.nameFile} ${hasErrors && doc.name.includes('(Obligatorio)') && styles.errorFile}`}>
                              {doc.file ? <span>{doc.originalName}</span> : <p>Arrastrar el archivo a cargar o</p>}
                              <Box display='flex' gap={1}>
                                {
                                  doc.file &&
                                    <Box className={styles.contentFilesSelected}>
                                      <IconButton onClick={()=> removerArchivo(doc)} data-testid='file-remove'>
                                          <DeleteOutline/>
                                      </IconButton>
                                    </Box>
                                }
                                <Link className={styles.fileDocument}><b> CARGAR</b></Link>
                              </Box>
                          
                        </Box>
                      </Box>
                      
                    </FileUploader>

                </div>
                <Box display='flex' justifyContent='space-between'>
                  <span className={styles.spanError}>
                    {hasErrors && doc.name.includes('(Obligatorio)') && 'Campo obligatorio'}
                  </span>
                </Box>
          </Grid>
          ))
        }
      </Grid>
      {(loading) && <Spinner/>}
    </>
  )
}
