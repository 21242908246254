import { IIframeResponse } from "shared/models/store/IIframes";


export const convertWebSites = (options: IIframeResponse[]) => {
    const option =  options?.map((option )=>{
        return{
            id: option.id,
            title: option.name,
            url: option.link
        }
    })
    return option
}