
import { Box, IconButton, Tooltip } from '@mui/material';
import { useCheckRoleAccess } from 'shared/hooks/useCheckRoleAccess';
import style from '../styles/detailedConsultation.module.scss'


interface IViewDetailButtonProp {
    index: number,
    value: any,
    onClickIcon: () => void,
    typeIcon: string,
    column?: any,
    accesRoles?: string[]
    titleTooltip?: string,
    onlyIcon?: boolean,
    disabledButton?: boolean
}

export const ViewDetailButton = ({index, value, column, accesRoles, titleTooltip, onlyIcon, disabledButton = true, onClickIcon, typeIcon}: IViewDetailButtonProp) => {

    const { checkRoles } = useCheckRoleAccess();

    return (
        <Box className={style.inputViewDetailsContainer} data-testid='content_view_detail'>
            <input
                data-testid={`input_value_${index}`}
                className={style.inputDetails}
                type='text' 
                disabled 
                value={value?.content[column.idLabel] || value}
            />
            {
               (accesRoles && checkRoles(accesRoles) && value?.content[column.idLabel] !== 'Información no disponible') ?
                    <Tooltip title={titleTooltip ?? ''} placement="top" arrow data-testid={`tooltip_${index}`}>
                        <IconButton
                            className={style.viewButton}
                            onClick={onClickIcon}
                            disableRipple
                            disabled={disabledButton}
                            data-testid='icon_view_detail'
                        >
                            <img src={typeIcon} alt="Ver detalle" />
                        </IconButton>
                    </Tooltip> 
                    : onlyIcon &&
                        <IconButton
                            className={style.viewButton}
                            onClick={onClickIcon}
                            disableRipple
                            disabled={disabledButton}
                            data-testid='icon_view_detail2'
                        >
                            <img src={typeIcon} alt="Ver detalle" />
                        </IconButton>              
            }
        </Box>
    )
}
