import React from 'react'
import { IComment, ICommentsState } from 'shared/models/store/IComments'
import { CommentForm } from './CommentForm'
import { Box } from '@mui/material'
import { CommentsList } from './CommentsList'
import styles from './styles/comments.module.scss'

interface Props{
    comments:IComment[]
}

export const Comments = ({comments}:Props) => {
  return (
    <>
        {
            comments?.length > 0 ?
            <Box className={styles.commentListContainer}><CommentsList /></Box> :
            <></>
        }
        <CommentForm />
    </>
  )
}
