import { oauth2ServerProperties } from "shared/auth";

export const propsAuthService: typeof oauth2ServerProperties = {
  clientId: "testClientID",
  location: window.location,
  provider: "https://oauth2provider/",
  redirectUri: "http://localhost/",
  scopes: ["openid", "profile"],
  autoRefresh: true,
  logoutEndpoint: 'https://oauth2provider/logout',
};
export const propsAuthServiceHealthProvider: typeof oauth2ServerProperties = {
  clientId: "HealthProvider",
  location: window.location,
  provider: "https://oauth2provider/",
  redirectUri: "http://localhost/",
  scopes: ["openid", "profile"],
  autoRefresh: true,
  logoutEndpoint: 'https://oauth2provider/logout',
};

export interface AuthServiceProps {
  clientId: string;
  clientSecret?: string;
  contentType?: string;
  provider: string;
  authorizeEndpoint?: string;
  tokenEndpoint?: string;
  logoutEndpoint?: string;
  audience?: string;
  redirectUri?: string;
  scopes: string[];
  autoRefresh?: boolean;
  refreshSlack?: number;
}

export interface JWTIDToken {
  given_name: string;
  family_name: string;
  name: string;
  email: string;
  sub: string;
  roles: string[];
}
