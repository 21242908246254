import { memo } from "react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";

export const InnerCountDown:React.FC<{setDisableInput: ()=> void}> = memo(({setDisableInput}) => {
    const rendererCountDown = ({ minutes, seconds, completed }:CountdownRenderProps) => {
        
        if (completed) {
            setDisableInput();
          return(
            <span>
                Su código expiró
            </span>  
          )
        } else{
            return (
                <span>
                    Su código vence en {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>        
            )
        }
      };
    return (
        <Countdown zeroPadTime={2} date={Date.now() + 1800000} renderer={rendererCountDown} />
    );
});