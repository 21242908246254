import * as XLSX from "xlsx"
import { Box, FormControl, IconButton, InputLabel, MenuItem, Pagination, PaginationItem, Select, Tooltip, Typography } from '@mui/material'
import style from '../../pages/detailed-consultation/styles/detailedConsultation.module.scss';
import Image from 'shared/assets/index';
import { DataGrid, GridFilterModel, GridSortModel, GridToolbarQuickFilter, useGridApiRef } from '@mui/x-data-grid';
import { IDataCustomided } from './interfaces';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { CustomNoRowsOverlay } from './CustomNoRowsOverlay';
import SearchIcon from '@mui/icons-material/Search';
import '../../shared/components/paginator/styles/_quickSearchToolbar.scss'

//Componente que maneja el paginado desde el front
export const DataCustomizedComponent: React.JSXElementConstructor<IDataCustomided<any>> = ({ titleCard, iconImage, data, nameRowsDownloadedFileXlX, nameFileXLX  }) => {

  const tableComponentRef = useGridApiRef();
  const [sizeRows, setSizeRows] = useState("10");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [],
  });
  
  useEffect(() => {
    data?.rows.length >= 10 ? setPageSize(10) : setPageSize(data?.rows.length)
  },[])
  
  useEffect(() => {
    setTotalElements(Object.values(tableComponentRef?.current?.state?.filter.filteredRowsLookup).filter(u => u).length)
  },[tableComponentRef, filterModel])

  const handleChangePage = (event: any, newPage: number ) => {
    setPage(newPage);
  };

  const handleChangeSizePage = (event: any) => {
    setSizeRows(event.target.value);
    setPageSize(event.target.value)
    setPage(1)
  };

  const handleSortModelChange = (payload:any) => {
    setSortModel(payload)
  };

  function dinamicData(objeto: any, cambios: Record<string, string>[]) {
    return cambios.reduce((nuevoObjeto, cambio) => {
      const claveOriginal = Object.keys(cambio)[0];

      const nuevaClave = cambio[claveOriginal];
      if (objeto[claveOriginal] && typeof objeto[claveOriginal] === "string" && claveOriginal.endsWith("Date")) {
        const fecha = new Date(objeto[claveOriginal]);
        nuevoObjeto[nuevaClave] = isNaN(fecha.getTime()) ? '-' : fecha.toLocaleDateString("en-US");
      } else {
        nuevoObjeto[nuevaClave] = objeto[claveOriginal];
      }
      return nuevoObjeto;
    }, {} as any);
  }
  
  const downloadReportsLocal = ()=> {
    const ws = XLSX.utils.json_to_sheet(nameRowsDownloadedFileXlX ? data?.rows.map(objeto => dinamicData(objeto, nameRowsDownloadedFileXlX)) : data?.rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${nameFileXLX}.xlsx`);
  }

  function QuickSearchToolbar() {
    return (
      <div className="contentQuick">
        <section className='contentQuickSearch' data-testid="quick_search_content">
          <div className={`${data.rows.length !== 0 ? 'contenido-autocomplete': 'disabled-contenido-autocomplete'}`} data-testid='content_filter'>
            <SearchIcon className='icono-buscar' />
            <GridToolbarQuickFilter
              className='input-buscar'
              placeholder='Buscar'
              data-testid="quick_search"
              disabled={data?.rows.length === 0}
            />
          </div>
          <Tooltip title='Exportar Informacion' placement="top" arrow>
            <span>
              <IconButton
                aria-label="update"
                data-testid="update_xlsx"
                className='icono-descargar'
                onClick={() => (nameRowsDownloadedFileXlX && data?.rows.length > 0)? downloadReportsLocal() : null}
                disabled={data?.rows.length === 0}
              >
                <img src={Image.iconoDescargar} alt="descargar" />
              </IconButton>
            </span>
          </Tooltip>
        </section>
        <section className='contentQuickTotal' data-testid='contenedor_para_desktop'>
          <p className='resultTotales'>{pageSize}/{data.rows.length}</p>
        </section>
      </div>
    )
  }

  function onFilterMode(params:any) {
    setFilterModel(params)
  }

  return (
    <div className={style.containerPerformanceIndicators} data-testid='container_data_customized'>
      <section className={style.containerTitleCardSeeContact}>
        <img src={Image.iconoContractExecution} alt="icono" />
        <Typography className={style.titleCard} data-testid='title_card'>{titleCard}</Typography>
      </section>
      <section className={(data?.rows.length === 0 || totalElements === 0) ? style.noData : ''} data-testid='section_data'>
        <DataGrid
          apiRef={tableComponentRef} 
          className='dataGridCustom'
          columns={data?.columns}
          paginationModel={{pageSize, page: page -1}}
          pageSizeOptions={[10,25,50,100]}
          rows={data?.rows}
          onSortModelChange={handleSortModelChange}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          disableColumnSelector
          disableDensitySelector
          data-testid='data_grid'
          hideFooter={true}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: QuickSearchToolbar,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          sortModel={sortModel}
          slotProps={{ 
            toolbar: { 
              showQuickFilter: true,
            },  
            baseIconButton:{
              title: 'Ordenar'
            } 
          }}
          onFilterModelChange={onFilterMode}
        />
      </section>
      {
        data.rows.length > 0 && 
        <Box className={style.footerGridData}>
          <Box className={style.contenedorResultadosPorPaginaData}>
            <p>Resultados por página</p>
            <FormControl fullWidth className={style.containerSizeRowsData}>
              <InputLabel id="select-size-label">Cantidad</InputLabel>
              <Select
                labelId="select-size-label"
                id="select-size"
                value={sizeRows}
                label="Cantidad"
                className={style.selectSizeRowsData}
                onChange={handleChangeSizePage}
                disabled={data.rows.length === 0 || totalElements === 0}
                data-testid='name-select'
              >
                <MenuItem value={10} data-testid='menu_item_10'>10</MenuItem>
                <MenuItem value={25} data-testid='menu_item_25'>25</MenuItem>
                <MenuItem value={50} data-testid='menu_item_50'>50</MenuItem>
                <MenuItem value={100} data-testid='menu_item_100'>100</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {
            data.columns &&
            <Pagination
              className={style.paginadorData}
              data-testid='action-pagination'
              page={page}
              count={Math.ceil(totalElements / parseInt(sizeRows))}
              siblingCount={0}
              boundaryCount={1}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: () => <Box className={style.botonPaginadorData}><KeyboardArrowLeft /><p>Ant.</p></Box>,
                    next: () => <Box className={style.botonPaginadorData}><p>Sig.</p><KeyboardArrowRight /></Box>
                  }}
                  {...item}
                />
              )}
              onChange={(e, page) => { handleChangePage(e, page) }}
            />
          }
        </Box>
      }

    </div>
  )
}