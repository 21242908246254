import { createModel } from "@rematch/core";
import { apiInstance } from "shared/api/config/apiInstance";
import { RootModel } from "../rootModel";
import { ILinkSupports } from "shared/models/store/ILinkSupports";
import { IGetLink, IValidateDocuments } from "shared/models";
import Swal from "sweetalert2";
import { putUploadDocBlob } from "shared/api/services";
import { cleanFileName } from "shared/utils/helpers/cleanFileName";
import { linkDocAdapter } from "shared/adapters";

export const linkSupports = createModel<RootModel>()({
  state: {
    response: [],
    openModal: false,
    loading:false,
    supports: []
  } as ILinkSupports,
  reducers: {
    getLink(state,payload){
      return {
        ...state,
        response: payload,
        loading: false,
      };
    },
    addSupports(state,payload){
      return {
        ...state,
        supports: payload,
      };
    },
    toogleModal(state, payload) {
      return {
        ...state,
        openModal: payload,
      };
    },
    isLoading(state, payload){
      return {
        ...state,
        loading: payload,
      };
    },
    cleanState(state) {
      return {
        ...state,
        response: [],
        openModal: false,
        loading: false,
      };
    },
    filterLinkState(state, payload){
      return {
        ...state,
        response: state.response?.filter(link => link.id !== payload),
      };
    },
    cleanSupports(state){
      return{
        ...state,
        supports: []
      }
    }
  },
  effects: (dispatch) => ({
    async getLinkAsync(params:IGetLink, state) {   
         
      dispatch.linkSupports.isLoading(true);

      const response = await apiInstance.post(params.url, {
        fileNames: params.file.map((x:any)=> cleanFileName(x.name)) ,
        supportsId: [params.name]
      }).finally(()=>{
        dispatch.linkSupports.isLoading(false)
      })

      response?.data && response.data.map((data:any) => {
        const file = params.file.find((f:any)=> cleanFileName(f.name) === data.originalName);
        return putUploadDocBlob(data.link, data.id, data.originalName, file).then(()=>{
          dispatch.linkSupports.getLink([...state.linkSupports.response!, {id: data.id, originalName: data.originalName}])
        }).catch(()=>{
          dispatch.linkSupports.isLoading(false)
        })
      })
    },

    async validateLinkAsync(params:IValidateDocuments, state) {
      dispatch.linkSupports.isLoading(true);

      await apiInstance.post(params.url, {
        attachmentIds: params.attachmentIds,
      }).then(()=>{

        dispatch.linkSupports.cleanState();
      }).catch((e)=>{
        dispatch.linkSupports.isLoading(false)
      });

    },
  }),
});
