import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { ICommentsState, IComment, ICommentEdited, ICommentList } from "shared/models/store/IComments";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_COMMENTS } from "shared/api";

export const comments = createModel<RootModel>()({
  state: {
    openDrawer: false,
    comments: {},
  } as ICommentsState,
  reducers: {
    handleOpenDrawer( state, payload ){
        return {
            ...state,
            openDrawer: payload
        }
    },
    addComment(state, payload: IComment[]) {
      return {
        ...state,
        comments: payload
      }
    },
    editComment(state, payload: ICommentEdited) {
      return {
        ...state,
        comments: state.comments.map(comment => {
          if (comment.id === payload.id) {
            return {
              ...comment,
              message: payload.message,
              wasUpdated: true,
              date: new Date(),
            }
          }

          return { ...comment }
        })
      }
    }
  },
  effects: (dispatch) => ({
    async getCommentListAsync(documentId: string) {
      const response = await apiInstance.get(`${GET_COMMENTS}/${documentId}`)

      const addResponseComment: IComment[] = response?.data.map((data:ICommentList ) => {
        const list: IComment  = {
          id : String(data.id),
          author: data.createdBy,
          message: data.comment,
          date: new Date(data.createdAt),
          privacy: data.isPrivate ? 'privado' : 'publico',
          wasUpdated: false,
          isVisible: data.isVisible,
          modifiedAt: data.modifiedAt
        }
        return list
      }) || []

      const commentsWithoutModifiedAt = addResponseComment.filter(comment => !comment.modifiedAt)
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      // Filtrar comentarios con "modifiedAt" y ordenar por "modifiedAt"
      const commentsWithModifiedAt = addResponseComment.filter(comment => comment.modifiedAt)
        .sort((a, b) => new Date(a?.[`modifiedAt`] as unknown as string).getTime() - new Date(b?.[`modifiedAt`] as unknown as string).getTime());

      // Concatenar y ordenar la lista completa por "modifiedAt" o "date" si "modifiedAt" no está presente
      const sortedComments = [...commentsWithoutModifiedAt, ...commentsWithModifiedAt].sort((a, b) => {
        const dateA = new Date(a.modifiedAt ?? a.date);
        const dateB = new Date(b.modifiedAt ?? b.date);
        return dateA.getTime() - dateB.getTime();
      });

      dispatch.comments.addComment(sortedComments)
    },
  })
});
