import { Box, Grid, Typography } from '@mui/material';
import {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { Layout } from 'shared/utils';
import { FilterAndSort } from './components/FilterAndSort';
import { Spinner } from 'shared/components';
import { CardReport } from './components/CardReport';
import { useSelector } from 'react-redux';
import { debounceFunction } from 'shared/utils/helpers/debounceFunction';
import styles from './styles/reports.module.scss';


export const ReportsView = () => {
    const dispatch = useDispatch<Dispatch>();
    const { limitItems, dataView, totalElements, search } = useSelector((state: RootState) => state.reports);
    const { loading } = useSelector((rootState: RootState) => rootState.loading.models.reports);

    const handleScroll = (e: any) => {
      const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
      const numberItems = limitItems + 4;
      
      debounceFunction(() => {
        if (totalElements && limitItems < totalElements){
          if (scrollHeight - scrollTop === clientHeight){
            dispatch.reports.getDataReportsAsync(`limit=${numberItems}${search.length > 0 ? '&title='+search : ''}`);
            dispatch.reports.updateReports({limit:numberItems, search});
          }
        }
      });
    };
    

    useEffect(() => {
      dispatch.reports.getDataReportsAsync(`limit=${limitItems}`)
	  }, [])

    useEffect(() => {
      dispatch.menuDrawer.changeSelectedIndex(2);
    }, [dispatch.electronicDocuments, dispatch.menuDrawer])
    
  return (
    <Layout>
      <Box className={styles.headerReports}>
        <Typography className={styles.titleMain}>Reportes de trazabilidad de facturación electrónica</Typography>
        <FilterAndSort/>
      </Box>

      <div                       
          id="container_reports"
          data-testid='container_reports'
          className={styles.containerReports}
          onScroll={handleScroll}
          >
          <Grid
              id="grid_reports"
              data-testid="grid_reports"
              container
              spacing={2}
          >
            {
              dataView?.length > 0 ?
                dataView.map((item, index: number) => {
                  return (
                      <CardReport
                        key={index}
                        card={item}
                      />
                  )
                })
              : !loading ? (
                  <Box className={styles.textError}>
                    <Typography data-testid='mensaje-error'>
                        ¡Ups!, no se encontr&oacute; contenido relacionado a la búsqueda.
                    </Typography>
                  </Box>
                )
                : <></>
            }
          </ Grid>
      </ div>
      {(loading) && <Spinner/>}
    </Layout>
  )
}
