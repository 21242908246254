import { apiInstance } from '../config/apiInstance';
import {AxiosResponse, all} from "axios";

export const getData = async(url:string): Promise<any> => {
    const response = await apiInstance.get( url );
    return response.data;
}

export type MultiRequestMap = { [key: string] : Promise<any> };
export type MultiResponseMap = { [key: string] : AxiosResponse<any> };

export const singleData = (url:string): Promise<any> => {
    return apiInstance.get( url );
}
export const parallelData = (requestMap: MultiRequestMap): Promise<MultiResponseMap> => {
    const requestArray = Object.keys(requestMap)
        .map(requestKey => ({requestKey, requestPromise: requestMap[requestKey]}));
    const requestPromises = requestArray
        .map(requestEntry => requestEntry.requestPromise);

    return all(requestPromises).then((responses) => {
        const responseMap: MultiResponseMap = {};
        requestArray.forEach((requestEntry, index) => {
            responseMap[requestEntry.requestKey] = responses[index];
        })
        return responseMap;
    })
}