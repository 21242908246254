import { createModel } from '@rematch/core';
import { RootModel } from '../rootModel';

export const searcher = createModel<RootModel>()({
	state: {
		open: false,
		text: ''
	} as ISearch,
	reducers: {
		addText(state, payload) {
			state.text = payload
		},
		toogleSearcher(state, payload) {
			state.open = payload;
		},
	},
});

interface ISearch {
	open: boolean;
	text: string;
}
