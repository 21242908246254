import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { IMessageModal } from "shared/models/store/IMessageModal";

export const messageModal = createModel<RootModel>()({
    state: {} as IMessageModal,
    reducers: {
        setMessageModal(state, payload: IMessageModal) {
            return {
                ...state,
                ...payload
            }
        },
        cleanStateMessage(state) {
            return {} as IMessageModal;
        }
    }
})