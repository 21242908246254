import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'shared/store/store';
import * as Yup from "yup";
import { FormikProps } from 'formik';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { GET_ELECTRONIC_DOCS } from 'shared/api';
import { activities } from 'shared/models/lineStates';

export const usePrepareInvoice = (isHadleSubmit: boolean, setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>, setIsNextPage: React.Dispatch<React.SetStateAction<boolean>>) => {

  const {id} = useParams();
  const dispatch = useDispatch<Dispatch>();
  const { linkDocument, optionsDropdowns, prepareInvoiceData, auditDetails } = useSelector((state: RootState) => state);
  const { billingState } = auditDetails;
  const [payLine, setPayLine] = React.useState('');
  const [isPaidValue, setIsPaidValue] = React.useState(true);
  const [isUnderage, setIsUnderage] = React.useState(false);
  const [document, setDocument] = React.useState<any>(null);
  const [cities, setCities] = React.useState(false)
  const formikRef = React.useRef<FormikProps<any>>(null);
  const typeAccept = ["PDF"];
  const { callGetDetailedDocument } = useDetailedConsultation();

  useEffect(() => {
    Object.keys(prepareInvoiceData).length === 0 && dispatch.prepareInvoiceData.getInvoiceDataAsync({id});
    dispatch.optionsDropdowns.getUserTypeDocuments();
  
    return () => {
      dispatch.prepareInvoiceData.clearInvoiceData()
      dispatch.linkDocument.cleanState()
    }
  }, [])

  useEffect(() => {
    if (isHadleSubmit) {
      formikRef.current?.submitForm();
      setIsHadleSubmit(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isHadleSubmit])

  useEffect(() => {
    if (optionsDropdowns.departments.length === 0) {
      dispatch.optionsDropdowns.getDepartments();
    } 
  },[optionsDropdowns?.departments.length == 0])


  useEffect(() => {
    if (Object.keys(prepareInvoiceData).length > 0) {
      formikRef.current?.setValues({
        recoveryValue: prepareInvoiceData?.recoveryFeeValue,
        paidValue: prepareInvoiceData.valuePaidByTheUser,
        typeDocument: prepareInvoiceData.documentType,
        documentNumber: prepareInvoiceData.documentNumber,
        names: prepareInvoiceData.isUnderage ? prepareInvoiceData.namePatientSeen : prepareInvoiceData.name,
        lastNames: prepareInvoiceData.isUnderage ? prepareInvoiceData.LastNamePatientAttended : prepareInvoiceData.lastname,
        tel1: prepareInvoiceData.phoneOne,
        tel2: prepareInvoiceData.phoneTwo,
        email: prepareInvoiceData.email,
        address: prepareInvoiceData.address,
        department: prepareInvoiceData.province,
        city: prepareInvoiceData.city,
        namePatientSeen: prepareInvoiceData.namePatientSeen,
        LastNamePatientAttended: prepareInvoiceData.LastNamePatientAttended,
        payLine: prepareInvoiceData.paymentLineId,
        isLoadFile: prepareInvoiceData.file.id ? true : null
      })
      prepareInvoiceData.paymentLineId && setPayLine(prepareInvoiceData.paymentLineId);
      prepareInvoiceData.province && dispatch.optionsDropdowns.getCities(prepareInvoiceData.province);
      setIsPaidValue(prepareInvoiceData.isRecoveryFee);
      setIsUnderage(prepareInvoiceData.isUnderage);
      if(prepareInvoiceData.file.id){
        const file = new File([prepareInvoiceData.file.originalName], prepareInvoiceData.file.originalName, { type: 'text/plain' });
        dispatch.linkDocument.getLink({
          id: prepareInvoiceData.file.id,
          originalName: prepareInvoiceData.file.originalName,
          link: prepareInvoiceData.file.path,
        })
        setDocument(file);
      } 
    }
  },[prepareInvoiceData])

  useEffect(() => {
    if (!isUnderage && Object.keys(prepareInvoiceData).length > 0) {
      formikRef.current?.setValues((values:any)=>{
        return{
          ...values,
          names: prepareInvoiceData.name,
          lastNames: prepareInvoiceData.lastname,
        }
      })
    }
  },[isUnderage])

  const handleChangeRadioPaidValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPaidValue((event.target as HTMLInputElement).value === "true")
    formikRef.current?.setValues((values:any)=>{
      return{
        ...values,
        paidValue: 0
      }
    })
  }

  const handleChangeRadioUnderage  = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUnderage((event.target as HTMLInputElement).value === "true")

    if (event.target.value === "false") {
      formikRef.current?.setValues((values:any)=>{
        return{
          ...values,
          names: values.namePatientSeen,
          lastNames: values.LastNamePatientAttended,
      }
      })
    }else{
      formikRef.current?.setValues((values:any)=>{
        return{
          ...values,
          names: '',
          lastNames: '',
        }
      })
    }
  }

  const onlyNumbers = (event:any) => {
    const text = (event.clipboardData || window.Clipboard).getData('text');

    if (!/^(\d+)$/.test(text)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
    
  const validationSchema = () => Yup.object().shape({
    payLine: Yup.string().trim().required(),
    namePatientSeen: Yup.string().max(50).trim().required(),
    LastNamePatientAttended: Yup.string().max(50).trim().required(),
    recoveryValue: Yup.string().trim().max(10).required(),
    paidValue: Yup.string().trim().max(10).required(),
    isLoadFile: Yup.boolean().required()
  })
    
  const handleChangeSelect = (event: any) => {
    setPayLine(event.target.value);
    formikRef.current?.setValues((values:any) => ({
      ...values,
      payLine: event.target.value
    }))
  };

  const handleBlurSelectDepartment = (event: any) => {
    const { value } = event.target;
    formikRef.current?.setValues((values:any) => ({
      ...values,
      city: ''
    }));
    dispatch.optionsDropdowns.addCities([]);

    if (!value) return;
    
    dispatch.optionsDropdowns?.getCities(value);
    setCities(!cities);
  };
  
  const handleSubmit = (values: any ) => {

    dispatch.electronicDocuments.putPrepareInvoiceAsync({
      url:`${id}/prepare`,
      params:{
        paymentLineId: payLine,
        isRecoveryFee: isPaidValue,
        electronicDocument : id,
        recoveryFeeValue: Number(values.recoveryValue),
        valuePaidByTheUser: Number(values.paidValue),
        documentType : values.typeDocument,
        documentNumber : values.documentNumber,
        name : values.names,
        lastname : values.lastNames,
        phoneOne : "+57" + values.tel1,
        phoneTwo : "+57" + values.tel2,
        email : values.email,
        province : values.department,
        city : values.city,
        address : values.address,
        patientName: values.namePatientSeen, // campo nuevo
        patientLastname: values.LastNamePatientAttended, // campo nuevo
        isYounger: isUnderage, // campo nuevo
        attachmentIds: [
          linkDocument.id
        ],
      }
    })?.then(()=>{
      callGetDetailedDocument(id || ''); // *Llamar cuando se obtenga la respuesta correcta del servicio
      setIsNextPage(true) // * Llamar cuando se obtenga la respuesta correcta del servicio
      // dispatch.electronicDocuments.getElectronicDocumentAsync({id}).then(()=>{
      //   dispatch.linkDocument.cleanState();
      // })
    })

  };
  

  const handleChangeFile = (file: any) => {
    formikRef.current?.setValues((values:any)=>({
      ...values,
      isLoadFile: true
    }))
    if (file && file.size < (10000000) && file.name.split('.').pop() === "pdf") {
      dispatch.linkDocument.getLinkAsync({
        file: file, 
        url: `${GET_ELECTRONIC_DOCS}/${id}/generate-upload-link`
      }).then(()=>{
        setDocument(file);
      })
    }
  };

  const removeFile = () => {
    setDocument(null);
    formikRef.current?.setValues((values:any)=>({
      ...values,
      isLoadFile: null
    }))
  };
    
  return {
    id,
    payLine,
    isPaidValue,
    document,
    typeAccept,
    formikRef,
    cities,
    isUnderage,
    prepareInvoiceData,
    handleChangeRadioUnderage,
    handleChangeRadioPaidValue,
    handleBlurSelectDepartment,
    handleChangeSelect,
    handleSubmit,
    validationSchema,
    handleChangeFile,
    removeFile,
    onlyNumbers
  }
}
