export const oauth2ServerProperties = {
    clientId: process.env.REACT_APP_OAUTH2SERV_CLIENT_ID!,
    location: window.location,
    provider: process.env.REACT_APP_OAUTH2SERV_BASE_URL!,
    redirectUri: process.env.REACT_APP_OAUTH2SERV_REDIRECT_URI,
    scopes: (process.env.REACT_APP_OAUTH2SERV_SCOPES! || '').split(','),
    autoRefresh: true,
    logoutEndpoint: process.env.REACT_APP_OAUTH2SERV_LOGOUT_URL
};

export const azureAdProperties = {
    clientId: process.env.REACT_APP_AZUREAD_CLIENT_ID!,
    location: window.location,
    provider: process.env.REACT_APP_AZUREAD_BASE_URL!,
    redirectUri: process.env.REACT_APP_AZUREAD_REDIRECT_URI!,
    scopes: (process.env.REACT_APP_AZUREAD_SCOPES! || '').split(','),
    autoRefresh: true,
    logoutEndpoint: process.env.REACT_APP_AZUREAD_LOGOUT_URL
};


export const getOAuthConfigByLoginType = (loginType: 'I' | 'E' | null) => {
    if(loginType === 'I')
        return azureAdProperties;
    return oauth2ServerProperties;
}