import { IOptionsDropdowns } from "shared/models";

export const fieldsFormPayment = [
    {
        id: 'itemformRecoveryValue',
        name: 'recoveryValue',
        type: 'textNumber',
        label: '¿Cuál es el monto total que debió  recuperar en la atención del usuario?',
        placeholder: '$ 0',
        required: true,
    },
    {
        id: 'itemformPaidValue',
        name: 'paidValue',
        type: 'textNumber',
        label: '¿Cuál fue el monto que el usuario pagó por concepto de cuota de recuperación?',
        placeholder: '$ 0',
        required: true,
    },
]

export const fieldsFormInfo = (optionsDropdowns: IOptionsDropdowns): IFieldForm[]  => {
    return [

        {
            id: 'itemformTypeDocument',
            name: 'typeDocument',
            type: 'select',
            label: 'Ingrese Tipo de documento del paciente o adulto responsable',
            placeholder: 'Seleccione tipo de documento',
            required: false,
            options: optionsDropdowns.userTypesDocument
        },
        {
            id: 'itemformDocument',
            name: 'documentNumber',
            type: 'text',
            label: 'Ingrese numero de identificación del paciente  o adulto responsable',
            placeholder: 'Nro documento',
            required: false,
            maxLength: 100,
        },
        {
            id: 'itemformNames',
            name: 'names',
            type: 'text',
            label: 'Ingrese nombres  del paciente o adulto responsable',
            placeholder: 'Nombres',
            required: false,
            maxLength: 20,
        },
        {
            id: 'itemformLastNames',
            name: 'lastNames',
            type: 'text',
            label: 'Ingrese apellidos del paciente o adulto responsable',
            placeholder: 'Apellidos',
            required: false,
            maxLength: 20,
        },
        {
            id: 'itemformTel1',
            name: 'tel1',
            type: 'textNumber',
            label: 'Ingrese teléfono de contacto  del paciente o adulto responsable',
            placeholder: '',
            required: false,
            maxLength: 10,
        },
        {
            id: 'itemformTel2',
            name: 'tel2',
            type: 'textNumber',
            label: 'Ingrese teléfono alterno del paciente o adulto responsable',
            placeholder: '',
            required: false,
            maxLength: 10,
        },
        {
            id: 'itemformEmail',
            name: 'email',
            type: 'text',
            label: 'Ingrese correo del paciente o adulto responsable',
            placeholder: 'Correo Electrónico',
            required: false,
            maxLength: 255,
        },
        {
            id: 'itemformAddress',
            name: 'address',
            type: 'text',
            label: 'Ingrese dirección de residencia del paciente o adulto responsable',
            placeholder: 'Dirección de residencia',
            required: false,
            maxLength: 150,
        },
        {
            id: 'itemformDeparment',
            name: 'department',
            type: 'select',
            label: 'Ingrese departamento  de residencia  del paciente o adulto responsable',
            placeholder: 'Departamento',
            required: false,
            options: optionsDropdowns.departments
        },
        {
            id: 'itemformCity',
            name: 'city',
            type: 'select',
            label: 'Ingrese Municipio de residencia del paciente o adulto responsable',
            placeholder: 'Municipio',
            required: false,
            options: optionsDropdowns.cities
        },
        
    ]
}

export const payLineInfo: IFieldForm[] = [
    {
        id: 'itemNamePatientSeen',
        name: 'namePatientSeen',
        type: 'text',
        label: 'Ingrese nombre del paciente atendido',
        placeholder: 'Nombres',
        required: true,
        maxLength: 50,
    },
    {
        id: 'itemLastNamePatientAttended',
        name: 'LastNamePatientAttended',
        type: 'text',
        label: 'Ingrese apellidos del paciente atendido',
        placeholder: 'Apellidos',
        required: true,
        maxLength: 50
    }
]

type IFieldForm = {
    id: string;
    name: string;
    type: string;
    label: string;
    placeholder: string;
    maxLength?: number;
    options?: any[];
    required: boolean;
}