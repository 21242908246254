import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTabAuditDetail } from '../hooks/useTabAuditDetail';
import _ from 'lodash';
import InputTextRich from './InputTextRich';
import { typeContentAudtDetailed } from '../helpers/contentTableRowDetailed';
import style from '../styles/detailedConsultation.module.scss';
import Image from 'shared/assets/index';
import { Spinner } from 'shared/components';
import { ModalAutorizationPayments } from './ModalAutorizationPayments';

export const TabAuditDetail = ({ NumberInvoice }: any) => {

    const {
        data,
        btnGlossInvoices,
        btnAuthorizeInvoice,
        contentDataAudit,
        isLoading,
        openModalAutorizationPayments,
        readOnly,
        onOpenModalAutorizationPayments,
        handleSetData,
        validateFieldsAutorize,
        validateFieldsGloses,
        validateInitial,
        callGetDataAuditDocument,
        callGetDataRips,
    } = useTabAuditDetail();
    const [lastFieldModified, setLastFieldModified] = useState({})
    
    useEffect(() => {
        callGetDataRips()
    }, [])

    useEffect(() => {
        validateInitial();
    }, [data]);


    useEffect(() => {
        callGetDataAuditDocument();
    }, [])

    return (
        <div className={`${style.containerBody}`} data-testid={'tabs-details'}>
            <div className='containerTitleCardTabs'>
                <img src={Image.iconoRips} alt="" />
                <Typography >Detalle de auditoría de factura {NumberInvoice}</Typography>
            </div>
            <Box className={style.contentDetails}>
                {
                    contentDataAudit.map((content: typeContentAudtDetailed, i: number) =>
                        <div key={i}>
                            {
                                content.isHidden
                                    ? <></>
                                    : <InputTextRich config={content} data={data} setData={handleSetData} readOnly={readOnly} setLastFieldModified={setLastFieldModified} />
                            }
                        </div>
                    )
                }
            </Box>
            {
                !readOnly && <>
                    {
                        lastFieldModified &&
                            <Box display={'flex'} className={style.contentDetailsActions}>
                                <Button
                                    id='btnAuthorizeInvoice'
                                    disabled={btnAuthorizeInvoice}
                                    variant='contained'
                                    color='primary'
                                    className={style.btnActions}
                                    data-testid="btn-authorize-invoice"
                                    onClick={() => validateFieldsAutorize()}
                                >
                                    Autorizar Pago
                                </Button>
                                <Button
                                    id='btnGlossInvoices'
                                    disabled={btnGlossInvoices}
                                    variant='contained'
                                    color='primary'
                                    data-testid="btn-gloss-invoices"
                                    className={style.btnActions}
                                    onClick={() => validateFieldsGloses(lastFieldModified)}
                                >
                                    Glosar Factura
                                </Button>
                            </Box>
                    }

                    <ModalAutorizationPayments
                        open={openModalAutorizationPayments}
                        onOpen={onOpenModalAutorizationPayments}
                        billName={NumberInvoice}
                    />
                </>
            }

            {(isLoading) && <Spinner />}
        </div>
    );
}
