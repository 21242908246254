import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from "yup";
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { NumericFormat } from 'react-number-format';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import styles from '../styles/ConsolidateGlosas.module.scss'
import '../styles/_formConsolidate.scss'

export const FormAddGlosas = () => {
  
    const {idDocument}= useParams();
    const dispatch = useDispatch<Dispatch>();
    const { consolidateGlosas } = useSelector((state: RootState) => state);
    const { initialValues, isUpdating, codesGlosas } = consolidateGlosas
    const { callGetDetailedDocument } = useDetailedConsultation();
    const formikRef = React.useRef<FormikProps<any>>(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const validationSchema = () => Yup.object().shape({
      codeGlosa: Yup.string().required(),
      nameGlosa: Yup.string().required(),
      valueGlosa: Yup.string().trim().max(14).required(),
      justification: Yup.string().trim().max(1500).min(100).required(),
    })

    const handleSubmit = (values:any ) => {
      const valueGlossa = values.valueGlosa.toString().replace(/\$/g, '').replace(/\./g, '').replace(',', '.')

      if(!(valueGlossa > 0)){
        return Swal.fire({
          title: "El valor debe ser superior a 0",
          icon: "error",
          timer: 5000,
        })
      }

      const paramsGlosa = {
        electronicDocumentId: Number(idDocument),
        glossId: values.codeGlosa,
        valueGloss: Number(valueGlossa),
        justification: values.justification,
        stateCode: 1,
        id:values.id
      }
      isUpdating 
        ? dispatch.consolidateGlosas.updateGlosasAsync(paramsGlosa).then(()=>{
          Swal.fire({
            title: "Glosa actualizada",
            icon: "success",
            timer: 5000,
          }).then(()=>{
            callGetDetailedDocument(idDocument || '')
            setSelectedValue(null);
            formikRef.current?.resetForm();
          })
        })
        : dispatch.consolidateGlosas.addGlosasAsync(paramsGlosa).then(()=>{
          Swal.fire({
            title: "Glosa agregada",
            icon: "success",
            timer: 5000,
          }).then(() => {
            callGetDetailedDocument(idDocument || '')
            setSelectedValue(null);
            formikRef.current?.resetForm();
          })
            
        })
    };

    const handleCodeGlosa = (event:any, newValue:any) => {
        formikRef.current?.setValues((values:any) => {
          return {
            ...values,
            codeGlosa: newValue ? newValue.id : '',
            nameGlosa: newValue ? newValue.name : ''
          }
        })
        setSelectedValue(newValue)
    };

    useEffect(() => {
      if(isUpdating) {
        const currentvalues:any = {
          id: initialValues.codeGlosa,
          name: initialValues.nameGlosa
        }
        formikRef.current?.setValues((values:any)=>{
          return{
            ...initialValues
          }
        })
        setSelectedValue(currentvalues)
      }
    }, [isUpdating, initialValues])
      
  return (
    <Fragment>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {
          formik => (
            <Form data-testid='form-justifyGlosa' className='formJustifyGlosa'>
              <Grid container>
                <Grid container direction={"row"} className={styles.containerForm}>
                  <Grid item xs={12} md={8} className={styles.contentItem}>
                    <span className={styles.spanForLabel}>
                      Código o nombre de glosa - Obligatorio
                    </span>
                    <Autocomplete
                      className='autocompleteValueGlosa'
                      value={selectedValue}
                      onChange={handleCodeGlosa}
                      options={codesGlosas}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          placeholder='Código o nombre de glosa'
                          onChange={(e) => { dispatch.consolidateGlosas.updateCodesGlosas(e.target.value) }}
                        />
                      )}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      noOptionsText="No se encontró sugerencias"
                      disablePortal
                      sx={{
                        '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {          
                          fontSize: 'clamp(0.75rem, 0.3042rem + 1.48vw, 0.875rem)'
                        }
                      }}
                      data-testid='autocomplete_value_glosa'
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={styles.contentItem}>
                    <label htmlFor={'valueGlosa'} className={styles.spanForLabel}>
                      Valor Glosa - Obligatorio
                    </label>
                    <Field name={"valueGlosa"} className={styles.inputItemValue}>
                        {({ field }: FieldProps) => (
                            <NumericFormat
                                {...field}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                className={styles.inputItemValue}
                                decimalScale={2}
                                value={formik.values.valueGlosa}
                                prefix="$"
                                isAllowed={(e) => e.value.length <= 10}
                                data-testid='value_glosa'
                            />
                        )}
                    </Field>
                    <Box display='flex' justifyContent='space-between'>
                        <span className={styles.spanError} data-testid='error_value_glosa'>
                          {Object.keys(formik.errors).includes('valueGlosa') && formik.touched['valueGlosa'] && 'Campo obligatorio'}
                        </span>
                        <span className={styles.spanCounter} data-testid='counter_value_glosa'>
                          { formik?.values?.['valueGlosa']?.toString().replace(/[^\d,]/g, '').length || 0}/10
                        </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={ styles.contentItem }>
                    <label htmlFor={'justification-form-item'} className={styles.spanForLabel}>
                        Justificar Glosa - Obligatorio
                    </label>
                    <Field
                        name="justification"
                        as="textarea"
                        placeholder={"Justificación de Glosa"}
                        className={styles.justificationGlosa}
                        id="justification-form-item"
                        maxLength={1500}
                        data-testid='justification_form_item'
                    />
                    <Box display='flex' justifyContent='space-between'>
                        <span className={styles.spanError} data-testid='error_justify'>
                          {Object.keys(formik.errors).includes('justification') && formik.touched['justification'] && 'Campo obligatorio - minimo 200 caracteres'}
                        </span>
                        <span className={styles.spanCounter} data-testid='counter_justify'>
                          { formik?.values?.['justification']?.toString().length || 0}/1500
                        </span>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    className={styles.btnAddGlosa}
                    disabled={ ( !formik.isValid || _.isEmpty(formik.touched) || !document )}
                    data-testid='btn_accept'
                    startIcon={<AddIcon/>}
                >
                  {
                    isUpdating ? "Guardar" : "Agregar Glosa"
                  }
                </Button>
              </Grid>
            </Form>
        )}
      </Formik>
    </Fragment>
  )
}
