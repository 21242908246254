import {createPKCECodes, PKCECodePair} from './pkce'
import {getQueryParam, toFormData, toUrlEncoded, validateToken} from './util'
import jwt_decode from 'jwt-decode'

export interface AuthServiceProps {
  clientId: string
  clientSecret?: string
  contentType?: string
  provider: string
  authorizeEndpoint?: string
  tokenEndpoint?: string
  logoutEndpoint?: string
  audience?: string
  redirectUri?: string
  scopes: string[]
  autoRefresh?: boolean
  refreshSlack?: number
}

export interface AuthTokens {
  id_token: string
  access_token: string
  refresh_token: string
  expires_in: number
  expires_at?: number // calculated on login
  token_type: string
}

export interface JWTIDToken {
  given_name: string
  family_name: string
  name: string
  email: string
  roles: string[],
  login_hint?: string,
  preferred_username: string,
  oid?: string
}

export interface TokenRequestBody {
  clientId: string
  grantType: string
  redirectUri?: string
  refresh_token?: string
  clientSecret?: string
  code?: string
  codeVerifier?: string
}


export class AuthService<TIDToken = JWTIDToken> {
  props: AuthServiceProps
  timeout?: number
  pkceStorageKey: string
  authStorageKey: string
  
  constructor(props: AuthServiceProps) {
    this.props = props
    this.pkceStorageKey = `${props.clientId}$pkce`
    this.authStorageKey = `${props.clientId}$auth`
    const code = this.getCodeFromLocation();
    const currentLocation = new URL(window.location.href).pathname;
    const redirectURI = new URL(props.redirectUri as string).pathname;
    const isRedirectUri = currentLocation.includes(redirectURI);
    if (isRedirectUri && code !== null) {
      this.fetchToken(code)
        .then(() => {
          this.restoreUri()
        })
        .catch((e) => {
          this.removeItem(this.pkceStorageKey);
          this.removeItem(this.authStorageKey);
          this.removeCodeFromLocation()
          console.warn({ e })
        })
    } 
    if (this.props.autoRefresh) {
      this.startTimer()
    }
  }

  getUser(): TIDToken | null {
    let result: TIDToken | null = null
    const t = this.getAuthTokens()
    if (t) {
      result = jwt_decode(t.id_token);
    }
    return result
  }

  getUserRoles(): string[] {
    let decodedToken: JWTIDToken | null = null;
    const t = this.getAuthTokens();
    if (t) {
      decodedToken = jwt_decode(t.access_token);
    }
    return decodedToken?.roles || [];
  }

  isInternalUser(): boolean {
    let decodedToken: any;
    const t = this.getAuthTokens();
    if (t) {
      decodedToken = jwt_decode(t.access_token);
    }
    return decodedToken?.iss === process.env.REACT_APP_AZUREAD_ISSUER_URI;
  }

  getCodeFromLocation(): string | null {
    return getQueryParam('code');
  }

  removeCodeFromLocation(): void {
    const [base, search] = window.location.href.split('?')
    if (!search) {
      return
    }
    const newSearch = search
      .split('&')
      .map((param) => param.split('='))
      .filter(([key]) => key !== 'code')
      .map((keyAndVal) => keyAndVal.join('='))
      .join('&')
    window.history.replaceState(
      window.history.state,
      'null',
      base + (newSearch.length ? `?${newSearch}` : '')
    )
  }

  getItem(key: string): string | null {
    return window.localStorage.getItem(key)
  }
  removeItem(key: string): void {
    window.localStorage.removeItem(key)
  }

  getPkce(): PKCECodePair {
    const pkce = window.localStorage.getItem(this.pkceStorageKey)
    if (null === pkce) {
      throw new Error('PKCE pair not found in local storage')
    } else {
      return JSON.parse(pkce)
    }
  }

  setAuthTokens(auth: AuthTokens): void {
    const { refreshSlack = 5 } = this.props
    const now = new Date().getTime()
    auth.expires_at = now + (auth.expires_in + refreshSlack) * 1000
    window.localStorage.setItem(this.authStorageKey, JSON.stringify(auth))
  }

  getAuthTokens(): AuthTokens {
    return JSON.parse(window.localStorage.getItem(this.authStorageKey) || '{}');
  }

  isPending(): boolean {
    return (
      window.localStorage.getItem(this.pkceStorageKey) !== null &&
      window.localStorage.getItem(this.authStorageKey) === null
    )
  }

  isAuthenticated(): boolean {
    const t = this.getAuthTokens();
    return !!t?.access_token && validateToken(jwt_decode(t?.access_token));
  }

  logout(): void {
    const { logoutEndpoint } = this.props;
    this.removeItem(this.pkceStorageKey);
    this.removeItem(this.authStorageKey);
    this.removeItem('LT');
    window.location.replace(`${logoutEndpoint}?post_logout_redirect_uri=${window.location.origin}?logout`);
  }

  async login(): Promise<void> {
    this.authorize()
  }

  // this will do a full page reload and to to the OAuth2 provider's login page and then redirect back to redirectUri
  async authorize(): Promise<boolean> {
    const {
      clientId,
      provider,
      authorizeEndpoint,
      redirectUri,
      scopes,
      audience
    } = this.props

    const pkce = createPKCECodes();
    window.localStorage.setItem(this.pkceStorageKey, JSON.stringify(pkce))
    // eslint-disable-next-line no-restricted-globals
    window.localStorage.setItem('preAuthUri', location.href)
    window.localStorage.removeItem(this.authStorageKey)
    const codeChallenge = await pkce.codeChallenge

    const query = {
      clientId,
      scope: scopes.join(' '),
      responseType: 'code',
      redirectUri: `${new URL(window.location.href).origin}${new URL(redirectUri as string).pathname}`,
      ...(audience && { audience }),
      codeChallenge,
      codeChallengeMethod: 'S256'
    }
    // Responds with a 302 redirect
    const endPoint = `${provider}/authorize`
    const url = `${authorizeEndpoint || endPoint}?${toUrlEncoded(
      query
    )}`
    window.location.replace(url)
    return true
  }

  // this happens after a full page reload. Read the code from localstorage
  async fetchToken(code: string, isRefresh = false): Promise<AuthTokens> {
    const {
      clientId,
      clientSecret,
      contentType,
      provider,
      tokenEndpoint,
      redirectUri,
      autoRefresh = true
    } = this.props
    const grantType = 'authorization_code'

    let payload: TokenRequestBody = {
      clientId,
      ...(clientSecret ? { clientSecret } : {}),
      redirectUri: `${new URL(window.location.href as string).origin}${new URL(redirectUri as string).pathname}`,
      grantType
    }
    if (isRefresh) {
      payload = {
        ...payload,
        grantType: 'refresh_token',
        refresh_token: code
      }
    } else {
      const pkce: PKCECodePair = this.getPkce()
      const codeVerifier = pkce.codeVerifier
      payload = {
        ...payload,
        code,
        codeVerifier
      }
    }
    console.log(`Trying Fetch Token with GranType: ${payload.grantType}`);
    const url = tokenEndpoint || provider+'/token'
    const response = await window.fetch(
      url,
      {
        headers: {
          'Content-Type': contentType || 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body: toFormData(payload)
      }
    )
    this.removeItem(this.pkceStorageKey)
    const json = await response.json()
    if (isRefresh && !json.refresh_token) {
      json.refresh_token = payload.refresh_token
    }
    this.setAuthTokens(json as AuthTokens)
    if (autoRefresh) {
      this.startTimer()
    }
    return this.getAuthTokens()
  }

  armRefreshTimer(refreshToken: string, timeoutDuration: number): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = window.setTimeout(() => {
      this.fetchToken(refreshToken, true)
        .then(({ refresh_token: newRefreshToken, expires_at: expiresAt }) => {
          if (!expiresAt) return
          const now = new Date().getTime()
          const timeout = expiresAt - now
          if (timeout > 0) {
            this.armRefreshTimer(newRefreshToken, timeout)
          } else {
            this.removeItem(this.authStorageKey)
            this.removeCodeFromLocation()
          }
        })
        .catch((e) => {
          this.removeItem(this.authStorageKey)
          this.removeCodeFromLocation()
          console.warn({ e })
        })
    }, timeoutDuration);
  }

  startTimer(): void {
    const authTokens = this.getAuthTokens()
    if (!authTokens) {
      return
    }
    const { refresh_token: refreshToken, expires_at: expiresAt } = authTokens
    if (!expiresAt || !refreshToken) {
      return
    }
    const now = new Date().getTime()
    const timeout = expiresAt - now
    if (timeout > 0) {
      this.armRefreshTimer(refreshToken, timeout)
    } else {
      this.removeItem(this.authStorageKey)
      this.removeCodeFromLocation()
    }
  }

  restoreUri(): void {
    const uri = window.localStorage.getItem('preAuthUri')
    window.localStorage.removeItem('preAuthUri')
    if (uri !== null) {
      window.location.replace(uri)
    }
    this.removeCodeFromLocation()
  }
}
