import { ClickAwayListener, IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import { RootState } from 'shared/store/store';

export const TooltipLinkToGlosses = () => {

    const { auditDetails } = useSelector((state: RootState) => state);
    const { bill } = auditDetails;

    const [creditNoteTooltipOpen, setCreditNoteTooltipOpen] = useState<boolean>(false);

    const handleCreditNoteTooltipOpen = (open: boolean) => {
        setCreditNoteTooltipOpen(open);
    }

    return (
        <ClickAwayListener onClickAway={() => { handleCreditNoteTooltipOpen(false) }}>
            <Tooltip
                title={`Esta nota crédito fue vinculado al proceso de Aceptación de glosas, 
                    referente al proceso de Auditoria ejecutado en la factura ${bill}. 
                    Para mayor detalle por favor dar clic en la  pestaña  GLOSAS de esta pantalla.`
                }
                placement="top"
                open={creditNoteTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
            >
                <IconButton
                    aria-label="view-credit-note-info"
                    data-testid="view-credit-note-info"
                    onClick={() => { handleCreditNoteTooltipOpen(true) }}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    )
}
