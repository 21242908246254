import { GET_ELECTRONIC_DOCS } from ".."
import { apiInstance } from "../config/apiInstance"

type Idata = {
    "userInfoQuestion": boolean,
    "userInfoText": string,
    "validateRightQuestion": boolean,
    "validateRightText": string,
    "adminInfoQuestion": boolean,
    "adminInfoText": string,
    "clinicHistoryQuestion": boolean,
    "clinicHistoryText": string,
    "medicalAttentionQuestion": boolean,
    "medicalAttentionText": string,
    "processQuestion": boolean,
    "processText": string,
    "medicationsQuestion": boolean,
    "medicationsText": string,
    "otherServicesQuestion": boolean,
    "otherServicesText": string
}

export const patchSaveAuditDocument = (id:string, data:Idata) => {
    return apiInstance.patch( `${GET_ELECTRONIC_DOCS}/${id}/audit/audit-document`, {
        ...data
    })
}