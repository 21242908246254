import axios from "axios";
import Swal from "sweetalert2";

export const putUploadDocBlob = async (
  link: any,
  id: any,
  originalName: any,
  file: File,
) => {  
  const headers = {
    "x-ms-blob-type": "BlockBlob",
  };
  try {
    await axios.put(link, file, { headers: headers });
  } catch (e: any) {
    Swal.fire({
      title: "Carga fallida",
      text: `${
        e?.response.status === 403 ? "Debe subir un nuevo archivo" : e.message
      }`,
      icon: "error",
      timer: 5500,
    });
    return false;
  }
};
