import { GET_ELECTRONIC_DOCS } from "..";
import { apiInstance } from "../config/apiInstance";

export const postInvoiceAssignment = async(idsDoc:number[], idUser:number)=> {
  const documentsToAssigment = idsDoc.map(item => ({
    electronicDocumentsId: item, 
    userRolesId: idUser
  }))

  const response = await apiInstance.post(
        `${GET_ELECTRONIC_DOCS}/invoiceAssignment`,
        documentsToAssigment
    );
  
  return response.data;
}
