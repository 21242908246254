import React, {useEffect} from 'react';

import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Spinner } from 'shared/components';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useTabRips } from '../hooks/useTabRips';
import style from '../styles/detailedConsultation.module.scss'
import _ from 'lodash';


export const TabRips = () => {

    const { idDocument } = useParams();
    const {callGetDataRips, downloadsRips, ripDetails, isLoading} = useTabRips()

    useEffect(() => {
        callGetDataRips(idDocument || "")
    },[])

    return (
        <>
            {
                ripDetails.length > 0 ? (
                    <>
                    <Button 
                        color='primary' 
                        variant='contained' 
                        sx={{display:"block", marginLeft: "auto", marginTop:"1rem", marginBottom:".5rem", borderRadius:100}}
                        onClick={()=>downloadsRips(idDocument)}
                        data-testid='btn-download-rips'
                    >
                        Descargar Rips
                    </Button>
                    { 
                        ripDetails.map((item:any, index:number) =>
                        <div key={index}>
                            {
                                item.id ? (
                                    _.isEmpty(item.content) ? <></> :
                                    <div className={`${style.containerBody}`} data-testid={'tabs-details'}>
                                        <div className='containerTitleCardTabs'>
                                            <img src={item.icon} alt="" />
                                            <Typography>{item.title}</Typography>
                                        </div>
                                
                                        <Grid container direction={"row"} className={style.contentDetails}>
                                        {
                                            item.columns.map((column:any, index:number) => {
                                                return(
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index} >
                                                        <Typography className={style.titleDetails}>{column.headerName}: </Typography>
                                                        <input data-testid={`input_value_${index}`} className={style.inputDetails} type='text' disabled defaultValue={
                                                            item.content[column.field]
                                                        }/>
                                                    </Grid> 
                                                )
                                            })
                                        }
                                        </Grid>
                                    </div>
                                ) : (
                                        _.isEmpty(item.content) ? <></> :
                                    <Paper className={'containerTableResults'} key={index} data-testid='container_table_rips'>
                                        <div className='containerTitleCardTabs'>
                                            <img src={item.icon} alt="" />
                                            <Typography>{item.title}</Typography>
                                        </div>
                                            <DataGrid
                                                rows={item.content}
                                                columns={item.columns.map((column:any) => ({
                                                    ...column,
                                                    sortable: false,
                                                }))}
                                                className='tableResultsRips'
                                                data-testid="datagrid-rips"
                                                disableColumnFilter
                                                disableColumnSelector
                                                disableDensitySelector
                                                disableColumnMenu={true}
                                                hideFooterSelectedRowCount={true}
                                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                hideFooter={true}
                                            />
                                        
                                    </Paper> 
                                )
                            }
                        </div>   
                        )
                    } 
                </>
                ) : (
                    <Box className={style.containerResults}>Aún no se ha cargado la información correspondiente de esta sección</Box>
                    )
                }
                {(isLoading) && <Spinner/>}
        </>
    )
}