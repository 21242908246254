
import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import Image from '../../assets/index';
import { sortItems } from '../../../pages/reports-view/helpers/sortItems';
import './styles/_filterSearchOrder.scss';

export const BtnOrderItems: React.FC<{ numberPages: any, content: any[], addDataSort: (data: any[]) => void }> = ({numberPages, content, addDataSort}) => {

    const [sort, setSort] = useState<string>("");
    const itemOffset = useRef(0)
    const [currentItems, setCurrentItems] = useState<any>(null);

    useEffect(() => {
        const endOffset = itemOffset.current + (numberPages);
        setCurrentItems(content?.slice(itemOffset.current, endOffset));
    }, [itemOffset, numberPages, content]);

    const sortData = (order: string) => {
        setSort(order);
        const datosOrdenados = sortItems(currentItems, order);      
        addDataSort(datosOrdenados);
    }

    return (
        !sort || sort === 'z-a' ? (
            <Button className='btnSort' data-testid='btn_order_asc' id="btn_order_asc" variant="outlined" onClick={() => sortData('a-z')}>
                <img src={Image.iconoOrdenar} alt="sort" />
                Ordenar
            </Button>
        ) : (
            <Button className='btnSort' data-testid='btn_order_desc' id="btn_order_desc" variant="outlined" onClick={() => sortData('z-a')}>
                <img src={Image.iconoOrdenar} alt="sort" />
                Ordenar
            </Button>
        )
    )
}
