import { useEffect, useState } from 'react';
import { Layout } from '../../shared/utils/ui/Layout';
import { Typography, Box, Tabs, Tab, IconButton } from '@mui/material';
import { TabPanel } from './components/TabPanel';
import { useParams } from 'react-router-dom';
import Image from 'shared/assets/index';
import { useDetailedConsultation } from './hooks/useDetailedConsultation';
import { ModalFiles, RouterGuard, Spinner } from 'shared/components';
import { SteppersStates } from './components/StepperStates';
import style from './styles/detailedConsultation.module.scss'
import { useTableResults } from 'shared/hooks/useTableResults';
import { MenuFunctions } from 'pages/read-documents/components/MenuFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { TabDetails } from './components';
import CommentIcon from '@mui/icons-material/Comment';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { GET_ELECTRONIC_DOCS } from 'shared/api';
import { SnackBarAlert } from 'shared/components/snackBar/SnackBarAlert';
import { getCums, getCups } from 'shared/api/services/getCupsCums';
import { activities } from 'shared/models/lineStates';
import { accesRoles } from 'shared/models';

export const DetailedConsultation = () => {

    const { idDocument } = useParams();
    const dispatch = useDispatch<Dispatch>();
    const { notifications, invoiceData, auditDetails, consolidateGlosas } = useSelector((state: RootState) => state);
    const { valuesPaymentConsolidate } = auditDetails;
    const { sumValuesAllValueNotAccept } = consolidateGlosas;
    const [ isBillValueNotAccept, setIsBillValueNotAccept ] = useState(false);
    const { value, timeLine, contentTabs, isLoading, handleChange, callGetDetailedDocument } = useDetailedConsultation();
    const { paramsUrl, content, modalFiles,modalAbortDoc,openModal, 
            actionModalAbortDE, linkToDian, actionModalResetAudit, setDataAlert, setShowAlert, showAlert, dataAlert } = useTableResults();
    const [document, setDocument] = useState<any | undefined>(undefined);

    useEffect(() => {
        const billNotAccept = sumValuesAllValueNotAccept <= 0  && valuesPaymentConsolidate?.initialValues?.consolidatedInNoAgreementValue?.value <= 0;
        setIsBillValueNotAccept(!billNotAccept);
    }, [valuesPaymentConsolidate?.initialValues, sumValuesAllValueNotAccept])
    
    useEffect(() => {
        if (content) {
            setDocument(content?.find(doc => doc.id === Number(idDocument!)))
        } else {
            dispatch.electronicDocuments.getElectronicDocuments({ states: "", id: idDocument }, `${GET_ELECTRONIC_DOCS}/page`)
        }
    }, [content, dispatch.electronicDocuments, idDocument])

    useEffect(() => {
        callGetDetailedDocument(idDocument || '')
    }, [notifications.anchorOrigin, notifications.notifications])

    useEffect(() => {
        dispatch.menuDrawer.changeSelectedIndex(1);
        dispatch.comments.getCommentListAsync(idDocument ?? '')
        dispatch.iframes.getIframeListAsync();
        
        return () => {
            dispatch.electronicDocuments.cleanState();
            dispatch.consolidateGlosas.cleanState();
        }
    },[])

    useEffect(() => {
        if (document && document?.state?.code == activities.AsignadaParaAuditoria && dataAlert.length === 1) {
            getCups(idDocument ?? '').then(responseCups => {
                setDataAlert(data => {
                    const a = responseCups.map((item: any) => {
                        return {
                            typeIcon: 'warning',
                            message: item.validation
                        }
                    }),
                    b = data
                    return [...a, ...b]
                })
            }).catch(error => console.log(error))
    
            getCums(idDocument ?? '').then(responseCums => {
                setDataAlert(data => {
                    const a = responseCums.map((item: any) => {
                         return {
                            typeIcon: 'warning',
                            message: item.validation
                         }
                     }),
                     b = data
                     return [...a, ...b]
                 })
            }).catch(error => console.log(error))
        }
    },[document])

    return (
        <Layout>
            {
                showAlert && <SnackBarAlert close={() => setShowAlert(false)} data={dataAlert}/>
            }
            
            <div className={style.containerMain} data-testid='contenido_principal'> 
                <div className={style.containerHeader}>
                    <Typography className={style.title}>Detalles factura N° {invoiceData.dataGeneralParameters[0]?.content?.nroFactura}</Typography>
                    <SteppersStates timeLine={timeLine} />
                </div>

                {
                    document && (
                        <>
                            <div>
                                <TabDetails detailedParameters={invoiceData.dataGeneralParameters} docState={document} />
                            </div>

                            <div className={style.containerActions}>
                                <Box className={style.actions}>
                                    <img src={Image.iconoEditar} alt="Edit" />
                                    <MenuFunctions
                                        detailed={true}
                                        paramsUrl={paramsUrl}
                                        doc={document}
                                        actionModalAbortDE={actionModalAbortDE}
                                        linkToDian={linkToDian}
                                        actionModalResetAudit={actionModalResetAudit}
                                        isBillValueNotAccept={isBillValueNotAccept}
                                    />
                                </Box>

                                <IconButton 
                                    className={style.commentsButton} 
                                    disableRipple
                                    onClick={() => { dispatch.comments.handleOpenDrawer(true); dispatch.iframes.handleOpenDrawer(false) }}
                                    data-testid='open_comments'
                                >
                                    <CommentIcon />
                                </IconButton>
                                <RouterGuard roles={accesRoles.usersSSSA} type='component'>
                                    <IconButton 
                                        className={style.commentsButton} 
                                        disableRipple
                                        onClick={() => { dispatch.iframes.handleOpenDrawer(true); dispatch.comments.handleOpenDrawer(false)}}
                                        data-testid='open_comments'
                                    >
                                        <HomeRepairServiceIcon />
                                    </IconButton>
                                </RouterGuard>
                               
                                
                            </div>
                        </>
                    )
                }

                <Box className={style.contenedorTabs}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={style.contenTabs}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        TabScrollButtonProps={{ className: style.scrollButton }}
                    >
                        {
                            contentTabs.filter(tab => !tab.hidden).map((tab: any, index: number) =>
                                tab.hidden ?
                                    <Tab key={index} sx={{ display: 'none' }} /> :
                                    <Tab key={index} label={tab.label} disabled={tab.disable} defaultValue={tab.label} className={style.contentTab} data-testid={`tab_${index}`}
                                        style={value === tab.id ? { backgroundColor: 'rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)', color: '#fff' } : { backgroundColor: '#fff', color: 'rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)' }}
                                    />
                            )
                        }
                    </Tabs>
                </Box>

                {
                    contentTabs.filter(tab => !tab.hidden).map((tab: any, index: number) =>
                        <TabPanel key={index} value={value} index={tab.id}>
                            {tab.content}
                        </TabPanel>
                    )
                }

            </div>
            <ModalFiles
                title={modalFiles.title}
                subtitle={modalFiles.subtitle}
                open={openModal || modalAbortDoc || false}
                hidden={modalFiles.hidden}
                onClose={modalFiles.onClose}
                children={modalFiles.children}
            />
            {(isLoading) && <Spinner />}
        </Layout>
    )
}
   
