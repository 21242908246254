import { Avatar, Badge, Box, Button } from "@mui/material";
import style from './styles/bagageUser.module.scss'

export const BagageUser:React.FC<{user:any}> = ({user}) => {

    return (
        <>
            <Badge
                className='badge-logo-user'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                overlap="circular"
                badgeContent={
                    <Button 
                        className='btn-edit'
                        data-testid='btn-edit'
                    >
                    </Button>
                }>
                <Box className={style.contenedorImagenPerfil}>
                    <div id='contenido_logo_letras_nombres_usuario' className={style.contentAvatarUser}>
                        <Avatar data-testid='logo_avatar' imgProps={{ id: 'logo_avatar' }} className={style.avatarUser}>
                            {user?.slice(0,1).toUpperCase()}
                        </Avatar>
                    </div>
                </Box>
            </Badge>
        </>
    )
}