import dayjs from "dayjs";

export const validateDateRangeByMonth = (initialDate: dayjs.Dayjs | null, finalDate: dayjs.Dayjs | null, rangeMonth: number): boolean => {

    if (initialDate && finalDate) {
        const diffInMonths = finalDate.diff(initialDate, 'month');
        return diffInMonths > rangeMonth || (diffInMonths === rangeMonth && initialDate.date() !== finalDate.date());
    }
    
    return false;
};