import React, {useEffect, useState} from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { ButtonAction } from 'shared/components/ButtonAction'
import { Link } from 'react-router-dom'
import { accesRoles } from 'shared/models'
import { ModalDocumentsPdf } from 'shared/components/ModalDocuments.pdf'

interface Props {
    id:any,
    detailed?: boolean 
    idPayment: string
}

export const MenuActions =({id, detailed, idPayment }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  
  const isOpenFunctions = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };  

  const handleCloseModal = (value:boolean) => {
    setOpenModal(value);
  };  


  return (
    <div className={'MenuFunctions'}>
        <IconButton
            data-testid={`buttonFunctions`}
            onClick={handleClick}
        >
            Acciones <ExpandMore/>
        </IconButton>
        <Menu
            id="menu-options"
            data-testid='menuOptions'
            className={'menuOptions'}
            anchorEl={anchorEl}
            open={isOpenFunctions}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
        >
            <MenuItem>
              <ButtonAction
                  roles={accesRoles.finance}
                  disabled={!!detailed}
              >
                  <Link to={`/consulta_documentos_electronicos/detalle_documento/${id}`} data-testid='link-detailed'>
                      Ver factura
                  </Link>
              </ButtonAction>
            </MenuItem>
            <MenuItem>
              <ButtonAction
                roles={accesRoles.finance}
                disabled={false}
                action={()=>handleCloseModal(true)}
              >
                <span>Ver autorización de pago</span>
              </ButtonAction>
            </MenuItem>   

        </Menu>
        { openModal && <ModalDocumentsPdf idPayment={idPayment} openIframePdf={openModal} handleOpenIframePdf={()=> handleCloseModal(false)}/> }
    </div>
  )
}
