import { Box, IconButton, Menu, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerTabs } from './ContainerTabs';
import './styles/_menuNotifications.scss'

export const ModalNotifications = () => {

  const dispatch = useDispatch<Dispatch>();    
  const { anchorOrigin } = useSelector((state: RootState) => state.notifications);

  const openMenu = Boolean(anchorOrigin);

  const cleanAnchorOrigin = () => {
    dispatch.notifications.addStatemodal(null)
  }  

  return (
    <>
        <Menu
          anchorEl={anchorOrigin}
          id="account-menu"
          open={openMenu}
          onClose={cleanAnchorOrigin}
          className='menuNotifications'
          PaperProps={{
            elevation: 0
          }}
          transformOrigin={{ horizontal: 269, vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          disableAutoFocusItem
          data-testid='modalNotifications'
        >
          <Box className='headerContainer'>
            <Typography className='titleNotifications'>Notificaciones</Typography>
            <IconButton
              className='btnClose'
              onClick={cleanAnchorOrigin}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className='bodyContainer'>
              <ContainerTabs />
          </Box>
        </Menu>
    </>
  )
}
