import { UserUnauthorized } from 'pages/user-unauthorized/UserUnauthorized';
import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom';
import { useAuth } from 'shared/auth';
import { checkUnderMaintenance } from 'shared/utils/helpers/checkMaintenance';

interface Props {
    roles: string[];
    children: ReactElement | ReactElement[];
    type: 'route' | 'component';
}

export const RouterGuard = ({ roles, children, type }: Props) => {

    const {authService} = useAuth();
    
    const componentDefault = ()=>{
        return(
            type === 'component' ? <></> : <UserUnauthorized/>
        )
    }

    const emailUser = authService.getUser()?.preferred_username || '';
    const isUnderMaintenance = checkUnderMaintenance(emailUser);

    if (isUnderMaintenance) {
        return <Navigate to="/mantenimiento" />;
    }

    return (
        <>
            {
                authService.isAuthenticated() &&
                authService.getUserRoles()?.some((x:any)=> {return roles.includes(x)}) ?
                    children : componentDefault()
            } 
        </>
    )
}