import { ReactElement } from 'react';
import { Fab } from '@mui/material'
import { RouterGuard } from 'shared/components'

interface Props{
    roles: string[];
    disabled: boolean;
    action?: () => void;
    dataTestid?: string;
    children?: ReactElement | ReactElement[];
}

export const ButtonAction = ({roles, disabled, action= ()=>true, dataTestid, children}: Props) => {
  return (
    <RouterGuard roles={roles} type="component">
            <Fab 
                disabled={disabled} 
                data-testid={dataTestid}
                id='btn_modal' 
                onClick={()=>action()}
            >
                { children }
            </Fab>
    </RouterGuard>
  )
}
