export const transformKey = (key: string): string => {
    switch (key) {
        case 'userInfoQuestion':
            return 'user_info_question';
        case 'userInfoText':
            return 'user_info_text';
        case 'validateRightQuestion':
            return 'validate_right_question';
        case 'validateRightText':
            return 'validate_right_text';
        case 'adminInfoQuestion':
            return 'admin_info_question';
        case 'adminInfoText':
            return 'admin_info_text';
        case 'clinicHistoryQuestion':
            return 'clinic_history_question';
        case 'clinicHistoryText':
            return 'clinic_history_text';
        case 'medicalAttentionQuestion':
            return 'medical_attention_question';
        case 'medicalAttentionText':
            return 'medical_attention_text';
        case 'processQuestion':
            return 'process_question';
        case 'processText':
            return 'process_text';
        case 'medicationsQuestion':
            return 'medications_question';
        case 'medicationsText':
            return 'medications_text';
        case 'otherServicesQuestion':
            return 'other_services_question';
        case 'otherServicesText':
            return 'other_services_text';
        default:
            return key;
    }
}