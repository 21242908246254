import { Box, Button, IconButton, TextField, TextFieldProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styles from '../styles/provitionServiceDateField.module.scss';
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useCheckRoleAccess } from "shared/hooks/useCheckRoleAccess";
import { IRespDocuments, accesRoles } from "shared/models";
import Image from 'shared/assets/index';
import SaveIcon from '@mui/icons-material/Save';
import { Spinner } from "shared/components";
import { putProvitionServiceDate } from "shared/api/services";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "shared/store/store";
import { activities, statesActivities } from "shared/models/lineStates";

interface Props {
    index: number;
    property: string;
    value: string;
    docState?:IRespDocuments,
}

export const ProvitionServiceDateField = ({ index, property, value, docState }: Props) => {

    const { idDocument } = useParams();
    const dispatch = useDispatch<Dispatch>();
    const [dayjsValue, setdayjsValue] = useState<Dayjs | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [userHasPermission, setUserHasPermission] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [billHasAudit, setBillHasAudit] = useState<boolean>(false);

    const { checkRoles } = useCheckRoleAccess();

    const renderInput = (params: TextFieldProps) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }} className={styles.dataPicker}>
                <TextField
                    {...params}
                    onKeyDown={(e: any) => { e.which === 13 && e.preventDefault() }}
                    data-testid='datapicker_date_from'
                />
            </Box>
        )
    }

    const editButton = () => {
        return (
            <IconButton
            className={billHasAudit ? styles.editButtonDisabled: styles.editButton}
                onClick={() => {
                    const isEdit = !isEditing;
                    setIsEditing(isEdit);

                    if (isEdit === false) {
                        setdayjsValue(dayjs(value));
                    }
                }}
                disableRipple
                disabled={ billHasAudit }
            >
                <img src={Image.iconoEditar} alt="Editar fecha" />
            </IconButton>
        )
    }

    const onChange = (value: Dayjs | null) => {
        setdayjsValue(value);
    }

    const onSave = () => {
        const oldDate = dayjs(value).format('DD MMMM YYYY');
        const newDate = dayjsValue?.format('DD MMMM YYYY');

        if (oldDate === newDate) {
            setIsEditing(() => !isEditing);
            return;
        };

        setIsLoading(true);

        const isStartDate = property === 'fechaInicioServicios';

        const dateFormated = dayjs(dayjsValue).format('YYYY-MM-DD');

        const request = isStartDate ?
            { attentionPeriodStartedAt: dateFormated } :
            { attentionPeriodEndedAt: dateFormated }

        putProvitionServiceDate(idDocument!, request).then(() => {
            setIsEditing(false);

            if (isStartDate) {
                dispatch.invoiceData.modifyFechaInicioServicios(newDate);
                return;
            }

            dispatch.invoiceData.modifyFechaFinalServicios(newDate);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        setUserHasPermission(checkRoles(accesRoles.auditor));
    }, [])

    useEffect(() => {
        setBillHasAudit(![...statesActivities.audit, activities.AsignadaParaAuditoria].includes(docState?.state.code!));
    }, [])

    useEffect(() => {
        setdayjsValue(dayjs(value));
    }, [value])

    return (
        <>
            {
                userHasPermission && isEditing ?
                    <Box className={styles.fieldEditionContainer}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                            <DatePicker
                                className={styles.contentDatePicker}
                                value={dayjsValue}
                                onChange={onChange}
                                renderInput={(params) => renderInput(params)}
                            />
                        </LocalizationProvider>
                        <Box className={styles.buttonsContainer}>
                            { editButton() }

                            <IconButton
                                className={styles.saveButton}
                                disableRipple
                                onClick={onSave}
                            >
                                <SaveIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    :
                    <>
                        <Box className={styles.inputEditonDetailsContainer} >
                            <input
                                data-testid={`input_value_${index}`}
                                className={styles.inputDetails}
                                type='text' disabled value={value}
                            />
                            {
                                userHasPermission && editButton()
                            }
                        </Box>
                    </>
            }
            {isLoading && <Spinner />}
        </>
    )
}
