import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Drawer, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, RootState } from 'shared/store/store';
import { useWidth } from 'shared/hooks/HookDetailedConsultation/useWidth';
import { Navbar, SideBar } from 'shared/components';
import styles from './styles/layout.module.scss';
import { useLocation } from 'react-router';
import { Comments } from 'shared/components/comments/Comments';
import { ContainerIframes } from 'shared/components/iframes/ContainerIframes';

interface Props {
  children: React.ReactNode;
  isBackgroundImg?: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `-${320}px`,
  maxWidth: '100%',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    width: '55%',
  }),
}));

export const Layout = ({ children, isBackgroundImg = true }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const { comments, iframes } = useSelector((state: RootState) => state);
  const { width } = useWidth();
  const location = useLocation();

  useEffect(() => {
    width > 900 && dispatch.menuDrawer.activeMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((comments.openDrawer || iframes.openDrawer) && location.pathname) {
      dispatch.comments.handleOpenDrawer(false);  
      dispatch.iframes.handleOpenDrawer(false);
    }
  },[location])

  return (
    <>
      <Navbar />
      <Box className={styles.layout}>
        <SideBar />
        <Box sx={{ display: 'flex' }}>
          <Main open={comments.openDrawer || iframes.openDrawer}>
            <Box className={`${!isBackgroundImg ? styles['noBackgroundIm'] : styles['containerMain']}`} sx={{ marginLeft: 'auto', width: width > 900 ? "calc(100% - 231px)" : "100%" }}>
              {children}
            </Box>
          </Main>
          <div>
            <div></div>
            <Drawer
              sx={{
                width: '320px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: '320px',
                  boxSizing: 'border-box',
                  position: 'relative',
                },
              }}
              anchor="right"
              variant="persistent"
              open={comments.openDrawer || iframes.openDrawer}
            >
              <Box className={styles.leftDrawerContent}>
                <>
                  <IconButton
                    className={styles.buttonCloseCommentsDrawer}
                    disableRipple
                    onClick={() => { comments.openDrawer ? dispatch.comments.handleOpenDrawer(false): dispatch.iframes.handleOpenDrawer(false) }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {
                    comments.openDrawer && <Comments comments={comments.comments}/>
                  }
                  {
                    iframes.openDrawer && <ContainerIframes iframes={iframes.iframes}/>
                  }
                </>
              </Box>
            </Drawer>
          </div>
        </Box>
      </Box>
    </>
  )
}
