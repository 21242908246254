import { Navigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { useAuth } from "shared/auth";
import style from './styles/maintenance.module.scss';
import Imagen from '../../shared/assets/index';
import { checkUnderMaintenance } from "shared/utils/helpers/checkMaintenance";

export const Maintenance = () => {

    const { authService } = useAuth();
    const emailUser = authService.getUser()?.preferred_username || '';

    const isUnderMaintenance = checkUnderMaintenance(emailUser);

    if (!isUnderMaintenance) {
        return <Navigate to="/" />;
    }

    return (
        <main className={style.mainContainer}>
            <Box className={style.iconsContainer}>
                <SettingsSharpIcon className={style.settingsIconOne} />
                <SettingsSharpIcon className={style.settingsIconTwo} />
            </Box>
            <Box className={style.textContainer}>
                <Typography variant="h1" className={style.title}>En este momento nos encontramos en mantenimiento del portal de Trazabilidad de facturación electrónica de la Secretaría Seccional de Salud y Protección Social de Antioquia.</Typography>
                <Typography variant="h3" className={style.subTitle}>Estamos trabajando para restablecer el servicio lo antes posible.</Typography>
                <Typography variant="h3" className={style.subTitle}>Gracias por su paciencia.</Typography>
            </Box>
            <footer className={style.footer}>
                <img className={style.imageGobernacion} src={Imagen.logoGobernacion} alt="Logo Gobernación" />
            </footer>
        </main>
    )
}
