import { apiInstance } from "../config/apiInstance";
import { GET_COMMENTS } from "..";


export const postCommentToAdd = async (dataComment: object, documentId: string): Promise<any> => {
    const { data } = await apiInstance.post( `${GET_COMMENTS}/${documentId}`,dataComment)
    return data;
}

//put
export const putCommentToUpdate = async (dataComment: object, documentId: string): Promise<any> => {
    const { data } = await apiInstance.put( `${GET_COMMENTS}/${documentId}`, dataComment)
    return data;
}