import { createModel } from "@rematch/core";
import { apiInstance } from "shared/api/config/apiInstance";
import { RootModel } from "../rootModel";
import { IDocValidated } from "shared/models";
import Swal from "sweetalert2";

export const validateDocuments = createModel<RootModel>()({
  state: {
    documentValidated: undefined,
  } as IDocValidated,
  reducers: {
    updateDocument(state,payload){
      return {
        ...state,
        documentValidated: payload,
      };
    },
    cleanState(state) {
      return {
        ...state,
        documentValidated: undefined,
      };
    },
  },
  effects: (dispatch) => ({
    async validateDocumentsAsync(params:any) {
      const response = await apiInstance.patch(params.url, params.payload)

      dispatch.validateDocuments.updateDocument(response);
      Swal.fire({
        title: "El proceso se realizó con éxito",
        text: `documento actualizado`,
        icon: "success",
        timer: 8500,
      });
    },

  }),
});
