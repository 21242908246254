import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import styles from '../styles/swalAlertContent.module.scss';
import React, { useState } from 'react';
import { RichTextEditor } from 'shared/components';

interface Props {
    description?: string;
    highlightedText?: string;
    useCheck?: boolean;
    checkText?: string;
    textBtn: string;
    onCancel?: () => void;
    onConfirm?: () => void;
}

export const SwalAlertContent = (props:Props) => {
    const { description, highlightedText, useCheck, checkText, textBtn, onCancel, onConfirm } = props
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);
    

    return (
        <Box className={styles.informationContainer}>
            { description && <Typography variant='body2' className={styles.descriptionText}>{description}</Typography> }
            { highlightedText && <Typography variant='body2' className={styles.highlightedText}>{highlightedText}</Typography> }

            { 
                useCheck && checkText && <Box className={styles.checkboxContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox 
                                disableRipple 
                                className={styles.checkbox}
                                onChange={(event) => { setDisableConfirmButton(!event.target.checked) }}
                            />
                        }
                        label={<p className={styles.checkboxLabel}>{checkText}</p>}
                    />
                </Box>
            }
            <Box className={styles.buttonsContainer}>
                <Button
                    variant='contained'
                    className={styles.confirmButton}
                    data-testid='alert_authorize_payment_button'
                    disabled={disableConfirmButton && !!useCheck && !!checkText}
                    onClick={onConfirm}
                >
                    {textBtn}
                </Button>
                <Button
                    variant='contained'
                    className={styles.cancelButton}
                    data-testid='alert_cancel_authorize_payment_button'
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
            </Box>
        </Box>
    )
}
