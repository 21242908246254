import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createRoot } from "react-dom/client";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { RootState } from "shared/store/store";
import { SwalAlertContent } from "../components";
import { postResultAccepted } from "shared/api/services";
import { useDetailedConsultation } from "./useDetailedConsultation";
import { CreditNoteType } from "../Models/ECreditNoteType";

export const useAuditResultAcceptanceForm = () => {

    const { auditDetails, invoiceData } = useSelector((state: RootState) => state);
    const { bill } = auditDetails;
    const { content } = invoiceData.dataGeneralParameters[0];
    const { idDocument } = useParams();

    const { callGetDetailedDocument } = useDetailedConsultation();

    const [acceptSettlement, setAcceptSettlement] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(true);

    const showAcceptancePolicy = () => {
        if (acceptSettlement) {
            setAcceptSettlement(false);
            return;
        }

        Swal.fire({
            title: 'Política de aceptación',
            text: `Señor Prestador, con el fin de garantizar la trazabilidad de la factura electrónica del sector salud por servicios prestados a la población a 
                cargo del Departamento de Antioquia - Secretaría Seccional de Salud y Protección Social; y de conformidad con la ley estatutaria 1581 del 17 de octubre del 2012 
                y el decreto reglamentario 1377 del 27 de julio de 2013. Acepta la consolidación de glosas realizadas a la factura ${bill}, 
                la cual tiene sustento legal en la norma anteriormente citada y en el Acuerdo de Confidencialidad y NO Divulgación de Información entregada y 
                reservada previamente firmado por usted.
                Con dicha aceptación se entenderá que la factura ${bill} queda cerrada y por tanto no habrá lugar a nuevos cobros de la misma.`,
            icon: 'info',
            confirmButtonText: 'Aceptar Conciliación',
            allowEscapeKey: false,
            allowOutsideClick: false,
        }).then(() => {
            setAcceptSettlement(true);
        })
    }

    const acceptResult = () => {
        setIsLoading(true);

        postResultAccepted(idDocument || '', CreditNoteType.Accepted)
            .then(() => {
                callGetDetailedDocument(idDocument || '');
            })
            .finally(() => { setIsLoading(false) })
    }

    const showConfirmResultAlert = () => {
        const modalComponent = document.createElement("div");

        createRoot(modalComponent).render(<SwalAlertContent
            description={`¿Confirma que desea Aceptar la consolidación la Auditoria de la factura ${bill}?`}
            useCheck
            checkText='Confirmo que deseo aceptar la consolidación de Auditoria'
            onCancel={() => { Swal.clickCancel(); }}
            onConfirm={() => {
                Swal.clickCancel();
                acceptResult();
            }}
            textBtn='Aceptar'
        />);

        Swal.fire({
            title: "¡Atención!",
            icon: "warning",
            html: modalComponent,
            showCancelButton: false,
            showConfirmButton: false,
        });
    }

    useEffect(() => {
        setReadOnly(!!content.fechaAceptacionResultado)
    }, [content.fechaAceptacionResultado]);

    return {
        isLoading,
        readOnly,
        acceptSettlement,
        idDocument,
        showAcceptancePolicy,
        showConfirmResultAlert
    }
}
