import { Box, Button, Checkbox, IconButton, Modal, Table, TableBody, TableContainer, Typography } from '@mui/material'
import {useEffect, useState} from 'react';
import { IModalStatesProps } from '../model/IModalStates';
import CloseIcon from '@mui/icons-material/Close';
import { RowStatesCollapsable } from './RowStatesCollapsable';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import styles from '../styles/modalSelectStates.module.scss';

export const ModalStates = ({ onCloseModal, openModal }:IModalStatesProps) => {    

    const dispatch = useDispatch<Dispatch>();
    const { invoiceStates } = useSelector((state: RootState) => state);

    useEffect(() => {
        dispatch.invoiceStates.getinvoiceStates();
	}, [])

    const handleCloseModal = ()=> {
        dispatch.invoiceStates.resetStatesSelected()
        onCloseModal()
    }

    const selectAllActivies = ()=> {
        dispatch.invoiceStates.changesAllActivitiesSelected();
    }
    

  return (
    <Modal
        open={openModal}
        onClose={onCloseModal}
        className={styles.modalStates}
        data-testid='modal-card-states'
    >
        <>
            <Box className={styles.container}>
                <Box className={styles.header}>
                    <Box className={styles.containerTitleClose}>
                        <Typography
                            className={styles.titleModal}
                            variant="h6"
                        >
                            Seleccionar actividades de estados
                        </Typography>
                        <IconButton
                            className={styles.buttonClose}
                            onClick={handleCloseModal}
                            data-testid='boton-cerrar'
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box className={styles.containerTable}>
                    {
                        invoiceStates.length > 0 &&
                            <>
                                <div className={styles.selectAllContainer}>
                                    <Checkbox
                                        disableRipple
                                        checked={invoiceStates.every(({ activities }) => activities.every(({ selected }) => selected))}
                                        onClick={() => { selectAllActivies() }}
                                        inputProps={{
                                            'aria-label': `select_all_activities_checkbox`,
                                            // @ts-ignore
                                            'data-testid': 'select_all_activities_input-check',
                                        }}
                                    />
                                    <span className={styles.selectAllCheckboxText}>Seleccionar todo</span>
                                </div>
                                <TableContainer 
                                    data-testid='contenedor-tabla'
                                    className={styles.contentTable} 
                                >
                                    <Table className={styles.tablaColapsable}>
                                        <TableBody>
                                            {
                                                invoiceStates.map((state, index) => (
                                                    <RowStatesCollapsable
                                                        key={index}
                                                        name={state.name}
                                                        content={state.activities}
                                                        head={index % 2 === 0}
                                                    />
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer> 
                            </>
                    }
                </Box>
            </Box>
        </>
    </Modal>
  )
}
