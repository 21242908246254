import { useAuth } from 'shared/auth';

export const useCheckRoleAccess = () => {
    const { authService } = useAuth();

    const checkRoles = (authorizedRoles: string[]): boolean => {
        const userRoles = authService.getUserRoles();
        
        const userRolesIsRigth = userRoles.some(role => authorizedRoles.includes(role));

        return userRolesIsRigth;
    }

    return {
        checkRoles,
    }
}
