import { useEffect, useState } from "react";
import { useAuth } from "shared/auth";

export const useInfoUser = () => {

    const {authService} = useAuth();
    const [user, setUser] = useState<any>()

    useEffect(() => {
        authService.isAuthenticated() && setUser(authService.getUser())
    }, [authService])


    return {
        user: user?.name || user?.sub
    }
}
