import { GET_ELECTRONIC_DOCS } from "..";
import { apiInstance } from "../config/apiInstance";

interface PutProvitionServiceDateRequest {
    attentionPeriodStartedAt?: string;
    attentionPeriodEndedAt?: string;
}

export const putProvitionServiceDate = (id: string, request: PutProvitionServiceDateRequest) => {
    return apiInstance.put(`${GET_ELECTRONIC_DOCS}/${id}/rips/dates-registry`, request);
}