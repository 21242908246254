import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import Image from 'shared/assets/index';
import { RichTextEditor } from 'shared/components';
import style from './style/detailedGlosse.module.scss'
import './style/_detailedGlose.scss'

interface Props{
    title: string;
    dataGlosas:any
}

export const DetailedGlosse = ({title, dataGlosas}:Props) => {

    const [data, setData] = useState<any>({
        columns: []
    })

    useEffect(() => {
        setData(dataGlosas)
      
    }, [dataGlosas.content])
    
  return (
        <div className={`${style.containerBody} containerFormDetaledGlossa`} data-testid={'tabs-details'}>
            <div className='containerTitleCardTabs noPadding'>
                <img src={Image.iconoResumen} alt="" />
                <Typography>{title}</Typography>
            </div>
            
            <Grid container direction={"row"} className={style.contentDetails}>
                {
                    data.content && data?.columns.map((column:any, index:number) => {
                        return (
                                column.idLabel === 'justification' ? (
                                    <Grid item xs={12} className={style.field} key={index} >
                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                        <RichTextEditor
                                            onChange={()=>{}}
                                            readOnly={true}
                                            value={data.content[column.idLabel]}
                                        />
                                    </Grid> 
                                ) : 
                                (
                                    <Grid item xs={12} sm={6} md={4} className={style.field} key={index} >
                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                        <span data-testid={`input_value_${index}`} className={style.inputDetails}>{data.content[column.idLabel]}</span>
                                    </Grid> 
                                )
                            
                        )
                    })
                }
            </Grid>

        </div>
  )
}
