import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Image from 'shared/assets/index'
import { FormAddGlosas } from './components/FormGlosas'
import { GridGlosas } from './components/GridGlosas'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'shared/store/store'
import styles from './styles/ConsolidateGlosas.module.scss'
import { useParams } from 'react-router-dom'
import { FormAuthorizePayments } from './components/FormAuthorizePayments'
import { Layout } from 'shared/utils'

export const ConsolidateGlosas = () => {

  const {idDocument}= useParams();
  const dispatch = useDispatch<Dispatch>();
  const { bill } = useSelector((state: RootState) => state.auditDetails);

  useEffect(() => {
    dispatch.consolidateGlosas.getCodesGlosasAsync()
    dispatch.consolidateGlosas.getlistGlosasAsync(idDocument)
  }, [])
  

  return (
    <Layout>
      <Box className={styles.containerGlosas}>
          <div className={styles.contentTitle}>
              <img src={Image.iconoGlosanoacuerdo} alt="" />
              <Typography className={styles.title} data-testid="title-documents-related">Detalle de Glosas</Typography>
          </div>
          <Typography className={styles.contentDescription} data-testid="title-documents-related">
              A continuación, se deben agregar las glosas encontradas para documentar los hallazgos relacionados a la factura {bill}, tenga en cuenta que una vez termine el proceso, usted no podrá realizar ningún ajuste en la auditoría. 
          </Typography>

          <FormAddGlosas/>

          <GridGlosas/>

          <FormAuthorizePayments/>
      </Box>
    </Layout>
  )
}
