
import { IFieldForm } from "pages/read-documents/model/IFieldForm";
import { IOptionsDropdowns } from "shared/models";

export const fieldsFormAutorizations = (optionsDropdowns: IOptionsDropdowns): IFieldForm[]  => {
    return [
        {
            id: 'itemFormConsecutivo',
            name: 'consecutive',
            type: 'text',
            label: 'Consecutivo',
            placeholder: 'Consecutivo',
            testId: 'itemFormConsecutivo',
            isEnable: true,
        },
        {
            id: 'authorizedAt',
            name: 'authorizedAt',
            type: 'datePicker',
            label: 'Fecha de autorización',
            placeholder: 'Fecha de autorización',
            testId: 'authorizedAt',
            isEnable: true
        },
        {
            id: 'itemFormAuthorizedBy',
            name: 'authorizationValue',
            type: 'Autocomplete',
            label: 'Auditor que autoriza',
            placeholder: 'Auditor que autoriza',
            testId: 'itemFormAuthorizedBy',
            isEnable: true,
            options: optionsDropdowns?.users
        },
        {
            id: 'itemFormPaymentLine',
            name: 'paymentLine',
            type: 'Select',
            label: 'Linea de pago',
            placeholder: 'Linea de pago',
            testId: 'itemFormPaymentLine',
            isEnable: true,
            options: optionsDropdowns?.paymentsLines
        },
        {
            id: 'itemFormPaymentReference',
            name: 'paymentReference',
            type: 'text',
            label: 'Referencia de pago',
            placeholder: 'Referencia de pago',
            testId: 'itemFormPaymentReference',
            isEnable: true
        },
        {
            id: 'itemformState',
            name: 'state',
            type: 'selectCheck',
            label: 'Estado autorización de pago',
            placeholder: 'Estado autorización de pago',
            testId: 'itemformState',
            isEnable: true,
        },
        {
            id: 'itemFormInvoiceReference',
            name: 'invoiceReference',
            type: 'text',
            label: 'Factura de referencia',
            placeholder: 'Factura de referencia',
            testId: 'itemFormInvoiceReference',
            isEnable: true
        },
        {
            id: 'invoiceAt',
            name: 'invoiceAt',
            type: 'datePicker',
            label: 'Fecha de factura',
            placeholder: 'Fecha de factura',
            testId: 'invoiceAt',
            isEnable: true
        },
        {
            id: 'itemformNit',
            name: 'nitEntidad',
            type: 'text',
            label: 'Nit',
            placeholder: 'Ingrese nit',
            testId: 'itemformNit',
            isEnable: true
        },
        {
            id: 'itemformRadicado',
            name: 'radicado',
            type: 'text',
            label: 'Radicado',
            placeholder: 'Radicado',
            testId: 'itemformRadicado',
            isEnable: true
        },
        {
            id: 'radicadoAt',
            name: 'radicadoAt',
            type: 'datePicker',
            label: 'Fecha de radicación',
            placeholder: 'Fecha de radicación',
            testId: 'radicadoAt',
            isEnable: true
        },
    ]
}