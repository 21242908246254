import React, { useState, useEffect } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'shared/components/Spinner';
import { Dispatch, RootState } from 'shared/store/store';
import { GET_ELECTRONIC_DOCS } from 'shared/api';
import styles from '../styles/modalRipsSoportes.module.scss'
import { CustomFileUploader } from 'shared/components';
import Swal from 'sweetalert2';
import { activities } from 'shared/models/lineStates';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { DeleteOutline } from '@mui/icons-material';
import { apiInstance } from 'shared/api/config/apiInstance';
import { downloadDocument, downloadsDescription, downloadsTitle } from 'shared/utils';

interface Props {
  idDoc: any;
  isHadleSubmit: boolean,
  setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>,
  setIsNextPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalLoadRips = ({idDoc, isHadleSubmit, setIsHadleSubmit, setIsNextPage}:Props) => {
    const dispatch = useDispatch<Dispatch>();
    const { callGetDetailedDocument } = useDetailedConsultation();
    const { linkDocument, auditDetails } = useSelector((state: RootState) => state);
    const { id, originalName } = linkDocument;
    const { billingState } = auditDetails;
    const [document, setDocument] = useState<any>(null);
    const [isRipsValidate, setIsRipsValidate] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const typeAccept = ["ZIP"];

    const handleChangeFile = (file:any) => {
      if (file && file.size < (5000000) && file.name.split('.').pop() === "zip") {
        setDocument(file);
        dispatch.linkDocument.getLinkAsync({
          file: file, 
          url: `${GET_ELECTRONIC_DOCS}/${idDoc}/rips/generate-upload-link`
        })
      }
    };

    const handleOnclickUpload = async() => {
      if(id){
        setIsLoading(true);
        const response = await apiInstance.post(`${GET_ELECTRONIC_DOCS}/${idDoc}/rips`, {
          attachmentId: id,
        })

        if(response.status === 206){
          downloadDocument(response, downloadsTitle.errorRips, downloadsDescription.errorRips, 'error')
        } else {
          dispatch.linkDocument.validateLinkAsync('');
          callGetDetailedDocument(idDoc || '');
          setIsNextPage(true);
        }
        setIsLoading(false);
      } else {
         Swal.fire({
          icon: "error",
          html: `Debe cargar archivo RIPS`,
          showCancelButton: false,
          showConfirmButton: true,
        });
      }
    }

    const removeFile = () => {
      setDocument(null);
      dispatch.linkDocument.cleanState();
    };

    useEffect(() => {
      if (isHadleSubmit) {
        isRipsValidate ? ( id ? handleOnclickUpload() : setIsNextPage(true) ) : handleOnclickUpload();
        setIsHadleSubmit(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isHadleSubmit])

    useEffect(() => {
      const isRips = billingState.code === activities.RIPSValidados;
      setIsRipsValidate(isRips);
    }, [billingState])
    
    

    useEffect(() => {
      return() => {
        dispatch.linkDocument.cleanState();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Box className={styles.containerUploadRips}>

      <Box className={styles.containerRips}>
        <Box className={styles.contentUploads}>
          {
            isRipsValidate ? (
              <Typography className={styles.textLabel}>Actualmente tiene RIPS cargados <b> según Resolución 3374 </b> en caso que desee reemplazarlos carguelos nuevamente.</Typography>
            ) : (
              <Typography className={styles.textLabel}>Cargue RIPS en formato <b> .ZIP según Resolución 3374 </b></Typography>
            )
          }
          <CustomFileUploader 
            fileLength={5}
            originalName={originalName}
            typeAccept={typeAccept}
            document={document}
            handleChangeFile={handleChangeFile}
            removeFile={removeFile}
          />
        </Box>
        <Box className={styles.separatorUploads}></Box>
        <Box className={styles.contentUploads}>
          {
            isRipsValidate ? (
              <Typography className={styles.textLabel}>Actualmente tiene RIPS cargados <b> según Resolución 2275 JSON y CUV </b> en caso que desee reemplazarlos carguelos nuevamente.</Typography>
            ) : (
              <Typography className={styles.textLabel}>Cargue RIPS Segun Resolución 2275 el <b> JSON y CUV dentro de un archivo en formato .ZIP </b></Typography>
            )
          }
          <CustomFileUploader 
            fileLength={5}
            originalName={null}
            typeAccept={typeAccept}
            document={null}
            handleChangeFile={handleChangeFile}
            removeFile={removeFile}
            isDisabled
          />
        </Box>

      </Box>
      <Typography className={styles.textDescription}>
        {/* texto */}
      </Typography>

      {(isLoading) && <Spinner/>}
    </Box>
  )
}
