import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'pages';
import React, { useEffect } from 'react'
import { CardNotifications } from './CardNotifications';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { Spinner } from '../Spinner';
import { useNotifications } from './useNotifications';
import './styles/_menuNotifications.scss'

const tabsInfo = [
    {
      id: 0,
      data_test_id: 'btn_tab_no_leidas',
      label: 'No leídas'
    },
    {
      id: 1,
      data_test_id: 'btn_tab_leidas',
      label: 'Leídas'
    },
    {
      id: 2,
      data_test_id: 'btn_tab_todas',
      label: 'Todas'
    }
  ]

export const ContainerTabs = () => {

    const dispatch = useDispatch<Dispatch>();    
    const {
      valor,
      isLoading,
      statusNotifications,
      notifications,
      totalElements,
      changeTab,
      validateScrollEnd,
      readNotification,
      readAllNotifications,
      deleteNotificacion,
      changeLoading,
      viewNotification
    } = useNotifications()
    

    useEffect(() => {
      dispatch.notifications.getNotificationsAsync({
        page: 1,
        status:statusNotifications,
        resetLoad: true,

      })?.then(()=>{
        setTimeout(() => {
          changeLoading(false)
        }, 1000);
      })
    }, [statusNotifications])
    

  return (
    <>
      <Tabs
        value={valor}
        onChange={changeTab}
        className='tabNotifications'
        data-testid={`tabNotifications_${valor}`}
      >
        {
          tabsInfo.map(({ id, data_test_id, label }) => (
            <Tab
              key={`tab_${label}`}
              id={`btn_tab_${id}`}
              aria-label={data_test_id}
              sx={{ textTransform: "capitalize" }}
              label={label}
              data-testid={`tab_${label}`}
            />
          ))
        }
      </Tabs>
      {
        tabsInfo.map(({ id, label }) => (
          <TabPanel key={`tab_content_${label}`} value={valor} index={id} className='tabPanelContainer'>
            {
                valor === 0 && totalElements > 0 &&
                <Box className='containerCheckAll'>
                    <button 
                        className='btnCheckAll'
                        onClick={() => readAllNotifications()}
                    >
                        Marcar todas como leídas
                    </button>
                </Box>
            }
            <Box aria-label='contenedor-tarjetas' data-testid='box_contenedir_tarjetas' className='containerCards'>
              {
                notifications.length > 0 ?
                  notifications.map(({ id, title, message, createdAt, electronicDocumentId, status, action }, index:number) => (
                    <CardNotifications
                      key={index}
                      id={id}
                      title={title}
                      message={message}
                      createdAt={createdAt}
                      electronicDocumentId={electronicDocumentId}
                      action={action}
                      status={status}
                      readNotification={readNotification}
                      deleteNotificacion={deleteNotificacion}
                      viewNotification={viewNotification}
                    />
                  )) :
                  <Box className='containerNotData'>
                    <Typography className='textNotData'>
                      No hay notificaciones para mostrar
                    </Typography>
                  </Box>
              }
            </Box>
          </TabPanel>
        ))
      }
      {isLoading && <Spinner/>}
    </>
  )
}
