import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {getQueryParam} from "../../shared/auth/oauh2-with-pkce/util";

export const Login = () => {

	const navigate = useNavigate();

	const sendUserToLogin = (loginType: any) => {
		window.localStorage.setItem('LT', loginType);
		navigate('/');
	}

	useEffect(() => {
        sendUserToLogin(getQueryParam('LT') || 'E')
    }, [])

	return (
		<></>
	);
};
