import { apiInstance } from '../config/apiInstance';
import { GET_EVENTS_HISTORY } from '../index';

export const getEventTrace = async(documentNumberId: string): Promise<any> => {
    const response = await apiInstance.get( GET_EVENTS_HISTORY, {
        params: {
            documentNumberId: documentNumberId
        }
    });

    return response.data;
}