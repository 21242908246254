import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_ELECTRONIC_DOCS } from "shared/api";
import { convertDataPrepareInvoice } from "shared/mappers/convertDataPrepareInvoice";

export const prepareInvoiceData = createModel<RootModel>()({
  state: {} as IPrepareInvoiceData,
  reducers: {
    setInvoiceData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    clearInvoiceData (state) {
      return {} as IPrepareInvoiceData;
    }
  },
  effects: (dispatch) => ({
    async getInvoiceDataAsync(payload, state) {
        const response = await apiInstance.get(`${GET_ELECTRONIC_DOCS}/${payload.id}/responsible-user`);

        dispatch.prepareInvoiceData.setInvoiceData(convertDataPrepareInvoice(response.data));
    },
  }),
});

export interface IPrepareInvoiceData {
    paymentLineId: string;
    isRecoveryFee: boolean;
    recoveryFeeValue: string;
    valuePaidByTheUser: string;
    documentType: string;
    documentNumber: string;
    name: string;
    lastname: string;
    phoneOne: string;
    phoneTwo: string;
    email: string;
    province: string;
    city: string;
    address: string;
    attachmentIds: string[];
    namePatientSeen: string;
    LastNamePatientAttended: string;
    isUnderage: boolean;
    id: string;
    file: any;
}