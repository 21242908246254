export const roles = {
  pss: process.env.REACT_APP_ROL_PSS!,
  lector: process.env.REACT_APP_ROL_LECTOR!,
  admin: process.env.REACT_APP_ROL_ADMIN!,
  radicador: process.env.REACT_APP_ROL_RADICADOR!,
  auditor: process.env.REACT_APP_ROL_AUDITOR!,
  financiero: process.env.REACT_APP_ROL_FINANCIERO!,
};

export const accesRoles: IAccesRoles = {
  adminUser: [roles.admin],
  healthProvider: [roles.pss],
  validator: [roles.admin, roles.radicador],
  auditor: [roles.admin, roles.auditor],
  finance: [roles.admin, roles.financiero],
  usersSSSA: [roles.admin, roles.financiero, roles.auditor, roles.radicador, roles.lector],
  reader: [roles.admin, roles.pss, roles.lector, roles.auditor, roles.financiero, roles.radicador],
};

export interface IAccesRoles{
  adminUser: string[];
  healthProvider: string[];
  validator: string[];
  auditor: string[];
  finance: string[];
  usersSSSA: string[];
  reader: string[];
}