import React,{useState} from 'react'
import Image from 'shared/assets/index';
import { contentTableRowRipsAC, contentTableRowRipsAF, contentTableRowRipsAH, contentTableRowRipsAM, contentTableRowRipsAN, contentTableRowRipsAP, contentTableRowRipsAT, contentTableRowRipsAU, contentTableRowRipsUS } from '../helpers/contentTableRowDetailed';
import { getDataRips } from 'shared/api/services/getDataRips';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import _ from 'lodash';


export const useTabRips = () => {

    const [ ripDetails, setRipDetails ] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true); 
    const dispatch = useDispatch<Dispatch>();

    const convertServiceRips = (dataRips: any) => {
        const data = [
            {
                id:"US",
                icon: Image.iconoRips,
                title: 'Información Archivo US',
                columns: contentTableRowRipsUS,
                content: _.isEmpty(dataRips.ripsReportUS) ? {}
                     : {
                        identificationType: dataRips.ripsReportUS.identificationType || "",
                        identificationNumber: dataRips.ripsReportUS.identificationNumber || "",
                        userType: dataRips.ripsReportUS.userType || "",
                        firstLastName: dataRips.ripsReportUS.firstLastName || "",
                        secondLastName: dataRips.ripsReportUS.secondLastName || "",
                        firstName: dataRips.ripsReportUS.firstName || "",
                        middleName: dataRips.ripsReportUS.middleName || "",
                        age: dataRips.ripsReportUS.age || "",
                        ageUnit: dataRips.ripsReportUS.ageUnit || "",
                        gender: dataRips.ripsReportUS.gender || "",
                        province: dataRips.ripsReportUS.province || "",
                        city: dataRips.ripsReportUS.city || "",
                        residenceArea: dataRips.ripsReportUS.residenceArea || ""
                    },
            },            
            {
                id:"AF",
                icon: Image.iconoRips,
                title: 'Información Archivo AF',
                columns: contentTableRowRipsAF,
                content: _.isEmpty(dataRips.ripsReportAF) ? {}
                    : {
                        healthServiceProviderCode: dataRips.ripsReportAF.healthServiceProviderCode || "",
                        healthServiceProviderName: dataRips.ripsReportAF.healthServiceProviderName || "",
                        identificationType: dataRips.ripsReportAF.identificationType || "",
                        identificationNumber: dataRips.ripsReportAF.identificationNumber || "",
                        invoiceNumber: dataRips.ripsReportAF.invoiceNumber || "",
                        invoiceIssuedAt: dataRips.ripsReportAF.invoiceIssuedAt || "",
                        attentionPeriodStartedAt: dataRips.ripsReportAF.startDate || '',
                        attentionPeriodEndedAt: dataRips.ripsReportAF.endDate || '',
                        epsCode: dataRips.ripsReportAF.epsCode || "",
                        epsName: dataRips.ripsReportAF.epsName || "",
                        contractNumber: dataRips.ripsReportAF.contractNumber || "",
                        benefitsPlan: dataRips.ripsReportAF.benefitsPlan || "",
                        insurancePolicyNumber: dataRips.ripsReportAF.insurancePolicyNumber || "",
                        sharedFeeValue: dataRips.ripsReportAF.sharedFeeValue || 0,
                        commissionValue: dataRips.ripsReportAF.commissionValue || 0,
                        discountsValue: dataRips.ripsReportAF.discountsValue || 0,
                        totalValue: dataRips.ripsReportAF.totalValue || "",
                    },
            },        
            {
                icon: Image.iconoRips,
                title: 'Información Archivo AC',
                columns: contentTableRowRipsAC,
                content: _.isEmpty(dataRips.ripsReportAC[0]) ? {}
                    : dataRips?.ripsReportAC?.map((item:any, index:number) => {
                    return {
                        id: index,
                        invoiceNumber: item.invoiceNumber || "",
                        healthServiceProviderCode: item.healthServiceProviderCode || "",
                        identificationType: item.identificationType || "",
                        identificationNumber: item.identificationNumber || "",
                        consultationDate: item.consultationDate || "",
                        authorizationNumber: item.authorizationNumber || "",
                        consultationCode: item.consultationCode || "",
                        consultationCodeName: item.consultationCodeName.name || "",
                        consultationPurpose: item.consultationPurpose || "",
                        externalCause: item.externalCause || "",
                        primaryDiagnosis: item.primaryDiagnosis || "",
                        firstRelatedDiagnosis: item.firstRelatedDiagnosis || "",
                        secondRelatedDiagnosis: item.secondRelatedDiagnosis || "",
                        thirdRelatedDiagnosis: item.thirdRelatedDiagnosis || "",
                        primaryDiagnosisType: item.primaryDiagnosisType || "",
                        consultationValue: item.consultationValue || "",
                        sharedFeeValue: item.sharedFeeValue || "",
                        totalValue: item.totalValue || "",
                    }
                }) || []
            },            
            {
                id: "AU",
                icon: Image.iconoRips,
                title: 'Información Archivo AU',
                columns: contentTableRowRipsAU,
                content: _.isEmpty(dataRips.ripsReportAU) ? {}
                    : {
                        invoiceNumber: dataRips.ripsReportAU.invoiceNumber|| "",
                        healthServiceProviderCode: dataRips.ripsReportAU.healthServiceProviderCode || "",
                        identificationType: dataRips.ripsReportAU.identificationType || "",
                        identificationNumber: dataRips.ripsReportAU.identificationNumber || "",
                        admissionDate: dataRips.ripsReportAU.admissionDate || "",
                        admissionTime: dataRips.ripsReportAU.admissionTime || "",
                        authorizationNumber: dataRips.ripsReportAU.authorizationNumber || "",
                        externalCause: dataRips.ripsReportAU.externalCause || "",
                        egressDiagnosis: dataRips.ripsReportAU.egressDiagnosis || "",
                        firstRelatedEgressDiagnosis: dataRips.ripsReportAU.firstRelatedEgressDiagnosis || "",
                        secondRelatedEgressDiagnosis: dataRips.ripsReportAU.secondRelatedEgressDiagnosis || "",
                        thirdRelatedEgressDiagnosis: dataRips.ripsReportAU.thirdRelatedEgressDiagnosis || "",
                        egressDestination: dataRips.ripsReportAU.egressDestination || "",
                        egressVitalState: dataRips.ripsReportAU.egressVitalState || "",
                        deathCause: dataRips.ripsReportAU.deathCause || "",
                        egressDate: dataRips.ripsReportAU.egressDate || "",
                        egressTime: dataRips.ripsReportAU.egressTime || "",
                    }
            },            
            {
                id:"AH",
                icon: Image.iconoRips,
                title: 'Información Archivo AH',
                columns: contentTableRowRipsAH,
                content: _.isEmpty(dataRips.ripsReportAH) ? {}
                    : {
                        invoiceNumber: dataRips.ripsReportAH.invoiceNumber || "",
                        healthServiceProviderCode: dataRips.ripsReportAH.healthServiceProviderCode || "",
                        identificationType: dataRips.ripsReportAH.identificationType || "",
                        identificationNumber: dataRips.ripsReportAH.identificationNumber || "",
                        admissionRoad: dataRips.ripsReportAH.admissionRoad || "",
                        admissionDate: dataRips.ripsReportAH.admissionDate || "",
                        admissionTime: dataRips.ripsReportAH.admissionTime || "",
                        authorizationNumber: dataRips.ripsReportAH.authorizationNumber || "",
                        externalCause: dataRips.ripsReportAH.externalCause || "",
                        primaryAdmissionDiagnosis: dataRips.ripsReportAH.primaryAdmissionDiagnosis || "",
                        primaryEgressDiagnosis: dataRips.ripsReportAH.primaryEgressDiagnosis || "",
                        firstRelatedEgressDiagnosis: dataRips.ripsReportAH.firstRelatedEgressDiagnosis || "",
                        secondRelatedEgressDiagnosis: dataRips.ripsReportAH.secondRelatedEgressDiagnosis || "",
                        thirdRelatedEgressDiagnosis: dataRips.ripsReportAH.thirdRelatedEgressDiagnosis || "",
                        complicationDiagnosis: dataRips.ripsReportAH.complicationDiagnosis || "",
                        egressVitalState: dataRips.ripsReportAH.egressVitalState || "",
                        deathCauseDiagnosis: dataRips.ripsReportAH.deathCauseDiagnosis || "",
                        egressDate: dataRips.ripsReportAH.egressDate || "",
                        egressTime: dataRips.ripsReportAH.egressTime || "",
                    }
            },            
            {
                icon: Image.iconoRips,
                title: 'Información Archivo AP',
                columns: contentTableRowRipsAP,
                content: _.isEmpty(dataRips.ripsReportAP[0]) ? {}
                    : dataRips.ripsReportAP?.map((item:any, index:number) => {
                        return {
                            id:index,
                            invoiceNumber: item.invoiceNumber || "",
                            healthServiceProviderCode: item.healthServiceProviderCode || "",
                            identificationType: item.identificationType || "",
                            identificationNumber: item.identificationNumber || "",
                            procedureDate: item.procedureDate || "",
                            authorizationNumber: item.authorizationNumber || "",
                            procedureCode :item.procedureCode || "",
                            procedureCodeName: item.procedureCodeName?.name || "",
                            procedureContext: item.procedureContext || "",
                            procedurePurpose: item.procedurePurpose || "",
                            attendantStaff: item.attendantStaff || "",
                            primaryDiagnosis: item.primaryDiagnosis || "",
                            relatedDiagnosis: item.relatedDiagnosis || "",
                            complication: item.complication || "",
                            procedureExecutionMethod: item.procedureExecutionMethod || "",
                            procedureValue: item.procedureValue || "",
                        }
                }) || []
            },      

            {
                icon: Image.iconoRips,
                title: 'Información Archivo AT',
                columns: contentTableRowRipsAT,
                content: _.isEmpty(dataRips.ripsReportAT[0]) ? {}
                    : dataRips.ripsReportAT?.map((item:any, index:number) => {
                        return {
                            id: index,
                            invoiceNumber: item.invoiceNumber || "",
                            healthServiceProviderCode: item.healthServiceProviderCode || "",
                            identificationType: item.identificationType || "",
                            identificationNumber: item.identificationNumber || "",
                            authorizationNumber: item.authorizationNumber || "",
                            serviceType: item.serviceType || "",
                            serviceCode: item.serviceCode || "",
                            serviceName: item.serviceName || "",
                            quantity: item.quantity || "",
                            unitValue: item.unitValue || "",
                            totalValue: item.totalValue || "",
                        }
                })  || []
            },            
            {
                icon: Image.iconoRips,
                title: 'Información Archivo AM',
                columns: contentTableRowRipsAM,
                content: _.isEmpty(dataRips.ripsReportAM[0]) ? {}
                    : dataRips.ripsReportAM?.map((item:any, index:number) => {
                        return {
                            id: index,
                            invoiceNumber: item.invoiceNumber || "",
                            healthServiceProviderCode: item.healthServiceProviderCode || "",
                            identificationType: item.identificationType || "",
                            identificationNumber: item.identificationNumber || "",
                            authorizationNumber: item.authorizationNumber || "",
                            age: item.age || "",
                            ageUnit: item.ageUnit || "",
                            medicineCode: item.medicineCode || "",
                            medicineType: item.medicineType || "",
                            activePrinciple: item.activePrinciple || "",
                            pharmaceuticalPresentation: item.pharmaceuticalPresentation || "",
                            measurementUnit: item.measurementUnit || "",
                            amountOfUnits: item.amountOfUnits || "",
                            unitValue: item.unitValue || "",
                            totalValue: item.totalValue || "",
                        }
                })  || []
            },            
            {
                icon: Image.iconoRips,
                title: 'Información Archivo AN',
                columns: contentTableRowRipsAN,
                content: _.isEmpty(dataRips.ripsReportAN[0]) ? {}
                    : dataRips.ripsReportAN?.map((item:any, index:number) => {
                        return {
                            id: index,
                            healthServiceProviderCode: item.healthServiceProviderCode || "",
                            identificationType: item.identificationType || "",
                            identificationNumber: item.identificationNumber || "",
                            birthDate: item.birthDate || "",
                            birthTime: item.birthTime || "",
                            pregnancyStatus: item.pregnancyStatus || "",
                            birthControl: item.birthControl || "",
                            gender: item.gender || "",
                            weight: item.weight || "",
                            newBornDiagnosis: item.newBornDiagnosis || "",
                            deathCause: item.deathCause || "",
                            deathDate: item.deathDate || "",
                            deathTime: item.deathTime || "",
                        }
                })  || []
            },      
                      
        ]       
        
        setRipDetails(data);
        setIsLoading(false);
    }

    const callGetDataRips = (idDocument: string) => {
        ripDetails.length <= 0 && getDataRips( idDocument || "" ).then((response: any) => {
            convertServiceRips(response)
        }).catch(()=> setIsLoading(false))
    }

    const downloadsRips = (id:any)=>{
        dispatch.electronicDocuments.getFileExcelToExport({
            url:`${id}/rips/download`,
            text:"RIPS descargados con Exito"
        })
    }

  return {
    isLoading,
    ripDetails,
    callGetDataRips,
    downloadsRips,
    convertServiceRips
  }
}
