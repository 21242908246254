import { useEffect } from 'react';
import { Box, Button } from '@mui/material'
import { useAuth } from 'shared/auth';

export const UserUnauthorized = () => {
  const {authService} = useAuth();

  useEffect(() => {
    setTimeout(() => {
      authService.logout();
    }, 8000);
  }, [authService])
  
  return (
    <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' height='100vh' width='100%'>
        <h2>USUARIO NO AUTORIZADO</h2>
        <p className='fs-7 mb'>Será redireccionado al login</p>
        <Button 
          variant='contained' 
          color='secondary' 
          data-testid="btn-logout"
          onClick={()=>authService.logout()}
        >
            OK
        </Button>
    </Box>
  )
}
