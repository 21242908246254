import { FormikProps } from 'formik';
import { SwalAlertContent } from 'pages/detailed-consultation/components';
import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';
import { RootState } from 'shared/store/store';
import { debounceFunction, removeHTMLTagsFromText } from 'shared/utils';
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { INoAgreementBalance } from '../model/INoAgreementBalance';
import { getAttachDocument, getAuditConsolidate, postAttachDocument, postAuditConsolidate } from 'shared/api/services';
import { IPostAuditConsolidate } from '../model/IAuditConsolidateGloses';
import { CreditNoteType } from 'pages/detailed-consultation/Models/ECreditNoteType';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';

interface Props {
    hideModal: (hide: boolean) => void;
    selectedDocument: string;
}

export const useFormNoAgreementBalance = ({ hideModal, selectedDocument }: Props) => {

    const validationSchema = () => Yup.object().shape({
        managementDetail: Yup.string()
            .test('minLength', value => {
                if (value) {
                    return removeHTMLTagsFromText(value).length >= 200 || removeHTMLTagsFromText(value).length === 0;
                }
                return true;
            })
            .test('maxLength', value => {
                if (value) {
                    return removeHTMLTagsFromText(value).length <= 5000;
                }
                return true;
            }),
        uploadResponse: Yup.mixed()
            .test('fileFormat', value => {
                if (value?.file) {
                    const supportedFormats = ['pdf'];
                    return supportedFormats.includes(value.file.name.split('.').pop());
                }
                return true;
            })
            .test('fileSize', value => {
                if (value?.file) {
                    return value.file.size <= 10485760;
                }
                return true;
            }),
        acceptedValue: Yup.string().required().max(100),
        authorizedValue: Yup.string().required().max(15),
    });

    const { invoiceData, auditDetails } = useSelector((state: RootState) => state);
    const { content } = invoiceData.dataGeneralParameters[0];
    const formRef = useRef<FormikProps<any>>(null);
    const [acceptedValueHasValue, setAcceptedValueHasValue] = useState<string | undefined>(undefined);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<INoAgreementBalance>({
        managementDetail: '<p><br></p>',
        uploadResponse: undefined,
        acceptedValue: '',
        authorizedValue: ''
    });
    const { callGetDetailedDocument } = useDetailedConsultation();

    const showConfirmAcceptation = () => {
        hideModal(true);
        const modalComponent = document.createElement("div");

        createRoot(modalComponent).render(<SwalAlertContent
            description={`¿Confirma que desea Guardar la gestión realizada con el saldo en No Acuerdo ${auditDetails.bill}?`}
            useCheck
            checkText='Confirmo que deseo Guardar la Gestión del Saldo en No Acuerdo'
            onCancel={() => { Swal.clickCancel(); hideModal(false); }}
            onConfirm={() => {
                Swal.clickCancel();
                postAuditConsolidateGloses();
            }}
            textBtn='Guardar'
        />);

        Swal.fire({
            title: "¡Atención!",
            icon: "warning",
            html: modalComponent,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => { hideModal(false) }
        });
    }

    const verifyIfAcceptedHasValue = (fieldName: string, currentValue: string) => {
        if (fieldName === 'acceptedValue' && currentValue !== '0') {
            debounceFunction(() => {
                setAcceptedValueHasValue(currentValue);
            })
        }
    }

    const checkIfFormIsValid = (formik: FormikProps<any>) => {
        setTimeout(() => {
            setFormIsValid(formik.isValid);
        });
    }

    const downloadFile = (file?: File) => {
        if (!readOnly || file?.name === undefined) return;

        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
    }

    const uploadAttachedDocument = (file: File) => {
        setIsLoading(true);

        postAttachDocument(selectedDocument.toString(), file)
            .then((uploadedFileId) => {
                const currentValue = formRef.current?.values['uploadResponse'];

                formRef.current?.setFieldValue('uploadResponse', {
                    ...currentValue,
                    id: uploadedFileId
                });
            })
            .finally(() => { setIsLoading(false) })
    }

    const postAuditConsolidateGloses = () => {
        setIsLoading(true);

        const request: IPostAuditConsolidate = {
            attachmentId: formRef.current?.values['uploadResponse']?.id,
            inNoAgreeDetail: formRef.current?.values['managementDetail'],
            acceptedValue: formRef.current?.values['acceptedValue'],
            authorizedPaymentValue: formRef.current?.values['authorizedValue'],
            type: CreditNoteType.NoAgree,
            noAgreement: true
        }

        postAuditConsolidate(selectedDocument.toString(), request)
            .then(() => {
                callGetDetailedDocument(selectedDocument.toString());
            })
            .finally(() => { setIsLoading(false) })
    }

    const getAuditConsolidateGloses = () => {
        setIsLoading(true);

        getAuditConsolidate(selectedDocument.toString())
            .then(async ({ inNoAgreeDetail, attachment }) => {
                let newFile: File | undefined = undefined;

                if (attachment?.id) {
                    newFile = await getAttachDocument(selectedDocument.toString(), attachment.id, attachment.originalName);
                }
                
                setInitialValues({
                    managementDetail: inNoAgreeDetail || '<p><br></p>',
                    uploadResponse: {
                        id: undefined,
                        file: newFile
                    },
                    acceptedValue: content.acceptedValue || content.acceptedValue === 0 ? content.acceptedValue.toString() : '',
                    authorizedValue: content.authorizedPaymentValue || content.authorizedPaymentValue === 0 ? content.authorizedPaymentValue.toString() : ''
                })
            })
            .finally(() => { setIsLoading(false) })
    }

    useEffect(() => { getAuditConsolidateGloses() }, [])

    useEffect(() => {
        setReadOnly((!!content.acceptedValue || content.acceptedValue === 0) && (!!content.authorizedPaymentValue || content.authorizedPaymentValue === 0))
    }, [content.acceptedValue, content.authorizedPaymentValue]);

    return {
        initialValues,
        validationSchema,
        acceptedValueHasValue,
        formIsValid,
        formRef,
        readOnly,
        isLoading,
        showConfirmAcceptation,
        checkIfFormIsValid,
        verifyIfAcceptedHasValue,
        downloadFile,
        uploadAttachedDocument
    }
}
