const messagesHTTP = new Map(
  Object.entries({
    401: "Ups, parece que no est\u00e1s autenticado para este recurso.",
    403: "Ups, parece que no tienes acceso a este recurso.",
  })
);

export const getMessageHTTP = (codigoHTTP?: number) => {
  return {
    message: messagesHTTP.get(codigoHTTP?.toString() || "") || "No pudimos procesar la transacci\u00f3n, por favor intentalo nuevamente."
  }
};
