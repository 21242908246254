import { Box, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DetailedGlosse } from './DetailedGlosse';
import { FormReplyGlosse } from './FormReplyGlosse';
import { useTabGlosses } from 'pages/detailed-consultation/hooks/useTabGlosses';
import styles from './style/modalReplyGlosses.module.scss';
import { FormConsolidateGlossas } from './FormConsolidateGlossas';
import { Spinner } from 'shared/components';
import { activities } from 'shared/models/lineStates';

interface Props {
    billName: string;
    open: boolean;
    idGlossa: number;
    readOnly: boolean;
    enableConsolidate:boolean;
    onOpen: (open: boolean) => void;
    onCloseSetReadOnly: () => void;
}

export const ModalReplyGlosses = ({billName, idGlossa, open, readOnly, enableConsolidate, onOpen, onCloseSetReadOnly}: Props) => {

    const { glosas, idDocument, documentsSupports, isLoading, loading, billingState, viewDocument, downloadSupports, callGetFiles } = useTabGlosses();
    const [lastGlossa, setLastGlossa] = useState(false);
    const [dataSingleGlosa, setDataSingleGlosa] = useState<any>({
      columns:[],
      content:{
        id: null,
        idGlosa: null,
        codeGlosa: '',
        nameGlosa: '',
        valueGlosa: '',
        statusGlosa: '',
        justification: ''
      }
    });

    const contentDataSingleGlosa = [
      { label: 'ID', idLabel: 'id' },
      { label: 'Código', idLabel: 'codeGlosa' },
      { label: 'Nombre de la glosa', idLabel: 'nameGlosa' },
      { label: 'Valor de la glosa', idLabel: 'valueGlosa' },
      { label: 'Estado', idLabel: 'status' },
      { label: 'Justificación de la glosa', idLabel: 'justification' },
    ]

    const statusOpen = 'Abierta';

    const onCloseModal = () => {
      onOpen(false);
      onCloseSetReadOnly();
    }

    useEffect(() => {
        const findGlossa = glosas.find(data => data.id === idGlossa);

        const validateLastGlosse = ():boolean =>{
          if(glossasOpen.length <= 1 && !findGlossa?.glossAnswer)
              return true;
          else if(billingState.code === activities.facturaGlosasEnviadas && glossasOpen.length === 0 ){
            return true;
          }
          return false
        }
        
        const data = {
          columns: contentDataSingleGlosa,
          content: {
              id: findGlossa?.id || "1",
              codeGlosa: findGlossa?.codeGlosa || "",
              idGlosa: findGlossa?.idGlosa || "",
              nameGlosa: findGlossa?.nameGlosa || "",
              valueGlosa: Number(findGlossa?.valueGlosa).toLocaleString("es-CO"),
              statusGlosa:{
                name: findGlossa?.statusGlosa.name || "",
                code: findGlossa?.statusGlosa.code || "",
              },
              status: findGlossa?.statusGlosa.name || "",
              justification: findGlossa?.justification || "",
              valueAccept: findGlossa?.valueAccept || '',
              valueSustained: findGlossa?.valueSustained || '',
              valueNotAccept: findGlossa?.valueNotAccept || '',
              glossAnswer: findGlossa?.glossAnswer || '',
              answerConsolited: findGlossa?.answerConsolited || ' ',
              glossSupport: findGlossa?.glossSupport
          }     
        };
        const glossasOpen = glosas.filter(data => data.statusGlosa.name === statusOpen);
        setDataSingleGlosa(data);
        setLastGlossa(validateLastGlosse);
    }, [idGlossa,open]);
  
  return (
    <>
            <Modal 
                open={open} 
                onClose={onCloseModal} 
                className={styles.modal} 
                data-testid='modal-authorization-payments'
            >
                <Box className={styles.modalContainer}>
                    <div className={styles.containerClose}>
                        <CloseIcon onClick={onCloseModal}/>
                    </div>
                    { dataSingleGlosa && <DetailedGlosse dataGlosas={dataSingleGlosa} title='Resumen de Glosa'/> }
                    <FormReplyGlosse 
                      onCloseModal={onCloseModal} 
                      dataSingleGlosa={dataSingleGlosa.content} 
                      billName={billName} 
                      lastGlossa={lastGlossa}
                      readOnly={readOnly}
                      downloadDocument={downloadSupports}
                      viewDocument={viewDocument}
                      callGetFiles={callGetFiles}
                      documentsSupports={documentsSupports}
                    />
                    {
                      enableConsolidate &&
                        <FormConsolidateGlossas
                          onCloseModal={onCloseModal} 
                          dataSingleGlosa={dataSingleGlosa.content} 
                          billName={billName} 
                          idDocument={idDocument!}                          
                        />
                    }
                </Box>
            </Modal>

            {(isLoading || loading) && <Spinner/>}
            
        </>
  )
}
