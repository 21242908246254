import { useEffect, useRef, useState } from "react";
import { FormikProps } from "formik";
import * as Yup from "yup";
import { getCreditNotesAdded, postCreditNoteToAdd, putCreditNotesAdded } from "shared/api/services";
import { ICreditNoteAdded, IRequestPostCreditNoteAdded } from "../Models/ICreditNoteAdded";
import { CreditNoteType } from "../Models/ECreditNoteType";

interface Props {
    creditNoteType: CreditNoteType;
    selectedDocument: string;
    acceptedValue?: string;
}

export const useAddCreditNotesForm = ({ creditNoteType, selectedDocument, acceptedValue }: Props) => {

    const formRef = useRef<FormikProps<any>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validationSchema = () => Yup.object().shape({
        legalNumber: Yup.string().required().max(20),
        CUDE: Yup.string().required().max(100),
        value: Yup.string().required().max(15),
    });

    const [creditNotesAdded, setCreditNotesAdded] = useState<ICreditNoteAdded[]>([]);

    const addCreditNote = (creditNoteToAdd: ICreditNoteAdded) => {
        setIsLoading(true);

        const request: IRequestPostCreditNoteAdded = {
            value: parseFloat(creditNoteToAdd.value.toString()),
            uniqueCodeElectronicBilling: creditNoteToAdd.CUDE,
            documentNumber: creditNoteToAdd.legalNumber,
            type: creditNoteType,
            acceptedValue
        }

        postCreditNoteToAdd(request, selectedDocument.toString())
            .then(() => {
                formRef.current?.resetForm();
                setCreditNotesAdded([...creditNotesAdded, creditNoteToAdd]);
            })
            .finally(() => { setIsLoading(false) })
    }

    const getCreditsNotesAdded = () => {
        setIsLoading(true);

        getCreditNotesAdded(selectedDocument.toString(), creditNoteType)
            .then((creditNotes) => {
                setCreditNotesAdded(creditNotes);
            })
            .finally(() => { setIsLoading(false) })
    }

    const deleteCreditNote = (creditNoteToAdd: ICreditNoteAdded) => {
        setIsLoading(true);
        const request: IRequestPostCreditNoteAdded = {
            value: parseFloat(creditNoteToAdd.value.toString()),
            uniqueCodeElectronicBilling: creditNoteToAdd.CUDE,
            documentNumber: creditNoteToAdd.legalNumber,
            type: creditNoteType,
            acceptedValue
        }
        putCreditNotesAdded(request, selectedDocument.toString())
            .then(() => {
                getCreditsNotesAdded()
            })
            .finally(() => { setIsLoading(false) })
    }

    useEffect(() => { getCreditsNotesAdded() }, [])


    return {
        isLoading,
        validationSchema,
        creditNotesAdded,
        formRef,
        addCreditNote,
        deleteCreditNote
    }
}
