import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import Swal from 'sweetalert2';
import { SwalAlertContent } from '../components/SwalAlertContent';
import { createRoot } from 'react-dom/client';
import { valuesPayment } from 'shared/models/store/IAuditDetails';
import { postPaymentAuthorizationDetail } from 'shared/api/services/postPaymentAuthorizationDetail';
import { useParams } from 'react-router-dom';
import { removeHTMLTagsFromText } from 'shared/utils';
import { useDetailedConsultation } from './useDetailedConsultation';
import { GET_ELECTRONIC_DOCS } from 'shared/api';

interface Props {
    billName: string;
    onOpen: (open: boolean) => void;
}

export const useModalAutorizationPayments = ({ billName, onOpen }: Props) => {

    const maxLength = 5000;
    const minLength = 100;

    const dispatch = useDispatch<Dispatch>();
    const { paymentInformation } = useSelector((state: RootState) => state.auditDetails);
    const { idDocument } = useParams();

    const { callGetDetailedDocument } = useDetailedConsultation();

    const [richText, setRichText] = useState('');
    const [totalAuthorizationValue, setTotalAuthorizationValue] = useState('0');
    const [hideModal, setHideModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onChangeRichText = (richText: string) => {
        const cleanText = removeHTMLTagsFromText(richText);

        if (cleanText.length < minLength || cleanText.length > maxLength) {
            setRichText('');
            return;
        }

        setRichText(richText);
    }
    const onCloseModal = () => { onOpen(false); setRichText(''); }
    const onHideModal = (hide: boolean) => { setHideModal(hide) }

    const totalValue = (): number => {
        const totalText = valuesPayment.totalAuthotizate.toLowerCase();
        return paymentInformation?.find(payment => payment.name.toLowerCase() === totalText)?.value || 0
    };

    const disableConfirmationButton = (): boolean => {
        const thereIsNotRichText = !richText;
        const totalAuthorizationNumberValue = parseInt(totalAuthorizationValue.replace('.', ''));
        
        return thereIsNotRichText || totalAuthorizationNumberValue < 0;
    }

    const onAuthorize = () => {
        onHideModal(true);

        const modalComponent = document.createElement("div");
        createRoot(modalComponent).render(<SwalAlertContent
            description={`¿Está seguro que desea Autorizar el pago de la Factura ${billName} por un total de ${totalAuthorizationValue} para pago?`}
            highlightedText='Recuerde que esta factura será autorizada para pagos y una vez autorizada no se podrá cambiar este valor'
            useCheck
            checkText='Confirmo que quiero autorizar el pago'
            onCancel={() => { onHideModal(false); Swal.clickCancel(); }}
            onConfirm={authorizePayment}
            textBtn='Autorizar Pago'
        />);
        
        Swal.fire({
            title: "¡Atención!",
            icon: "warning",
            html: modalComponent,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => { onHideModal(false) }
        });
    }

    const authorizePayment = () => {
        setIsLoading(true);
        onHideModal(false);
        Swal.clickCancel();
        onOpen(false);

        if (!idDocument) return;
        
        postPaymentAuthorizationDetail(idDocument, richText)
            .then(() => {
                callGetDetailedDocument(idDocument);
                dispatch.electronicDocuments.getElectronicDocuments({states:"", id: idDocument}, `${GET_ELECTRONIC_DOCS}/page`);
            })
            .finally(() => { setIsLoading(false) })
    }

    useMemo(() => {
        setTotalAuthorizationValue(totalValue().toLocaleString("es-CO"));
    }, [paymentInformation])

    return {
        isLoading,
        hideModal,
        maxLength,
        onCloseModal,
        onChangeRichText,
        onAuthorize,
        disableConfirmationButton,
    }
}
