
import React from 'react'
import { IconButton } from "@mui/material";
import styles from '../styles/SnackBarAlert.module.scss';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import GppBadIcon from '@mui/icons-material/GppBad';
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { IDataSnackBar, ISnackBarAlert } from 'shared/models';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const SnackBarAlert = ({typeAlert, data, close}: ISnackBarAlert) => {

  const typeIcons: Record<IDataSnackBar['typeIcon'], JSX.Element> = {
    info: <InfoIcon/>,
    error: <GppBadIcon/>,
    warning: <ReportProblemIcon/>,
    success: <CheckCircleIcon/>  
  }

  const validationData = () => {
    return data.filter((item) => item.message !== '')
  }

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = validationData().length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={styles.contentSnack} data-testid='content_snack'> 
      <section className={styles.contentAlert}>
        {
          validationData().length > 1 && 
          <div className={styles.button} data-testid='content_button_back'>
            <IconButton onClick={handleBack} disabled={activeStep === 0} data-testid="icon_button_back">
              <ArrowBackIosIcon />
            </IconButton>
          </div>
        }
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          style={{width: '100%'}}
        >
          { 
            validationData().map((step, index) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= 1 ? (  
                    <div className={` ${styles[step.typeIcon]} `} >
                      <section className={styles.contentButoonClose}>
                        <IconButton onClick={close} data-testid={`icon_button_close_${index}`}>
                          <CloseIcon />
                        </IconButton>
                      </section>
                      <section className={styles.contentText}>
                          {typeIcons[step.typeIcon]}
                          <p>
                            {step.message}   
                          </p>                    
                      </section>
                      <section className={styles.totalCharacters}>
                        <span>{activeStep + 1}/{validationData().length}</span>  
                      </section>
                    </div>
                ) : null}
              </div>
            ))
          }
        </SwipeableViews >
        {
          validationData().length > 1 && 
            <div className={styles.button}  data-testid='content_button_next'>
              <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1} data-testid="icon_button_next">
                <ArrowForwardIosIcon />
              </IconButton>
            </div>  
        }
      </section>
    </div>
  )
}
