import React from 'react';
import { Box, Typography } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { IIframe } from 'shared/models/store/IIframes';
import { IframeItem } from './IframeItem';
import { FormAddIframes } from './FormAddIframes';
import styles from './styles/iframes.module.scss';

interface Props{
  iframes:IIframe[]
}

export const ContainerIframes = ({iframes}:Props) => {
  return (

    <>
      <FormAddIframes/>
      {
        iframes?.length > 0 ?
        <Box className={styles.iframesListContainer}>
          <Box className={styles.formTitle}>
              <InsertLinkIcon />
                <Typography>Sitios web:</Typography>
            </Box>
          {
            iframes.map((iframe, i) => 
              <IframeItem key={i} iframe={iframe}/>
            )
          }
        </Box> :
        <></>
      }
    
    </>
  )
}
