import React from 'react'
import Swal from 'sweetalert2';
import { useAuth } from '../auth/oauh2-with-pkce/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ExitToApp } from '@mui/icons-material';

export const ButtonLogout = () => {
    const {authService} = useAuth();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(() => {
      if(openModal){
        Swal.fire({
          title: 'Estás seguro de salir?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0089e4',
          cancelButtonColor: '#6e7881',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
              authService.logout();
              setOpenModal(false);
            }else if(result.dismiss){
              setOpenModal(false);
            }

        })
      }
    }, [authService, navigate, openModal])

  return (
    <Button
        className='fs-9'
        color="success" 
        variant='outlined' 
        startIcon={<ExitToApp/>}
        aria-label="btn-logout"
        onClick={()=> setOpenModal(true)}
        >
            Cerrar sesión
    </Button>
  )
}
