import { StepIconProps } from '@mui/material';
import React from 'react'
import { ColorlibStepIconRoot } from 'shared/utils';
import Images from 'shared/assets/index'
import { ITimeLine } from '../Models/ITimeLine';
import {useState} from 'react';
import _ from 'lodash';

interface Props{
  timeLine: ITimeLine
}

export const useStepper = ({timeLine}:Props) => {

  const [seeTimeLine, setSeeTimeLine] = useState<any>({
    receivedAt: !!timeLine.receivedAt,
    cancelledAt: !!timeLine.cancelledAt,
    filedAt: !!timeLine.filedAt,
    conciliationAt: !!timeLine.conciliationAt,
    authorizedToPaymentAt: !!timeLine.authorizedToPaymentAt,
    paidAt: !!timeLine.paid_at,
  })

    const changeSeeTimeLine = () => {
      const newObject = Object.assign({}, seeTimeLine);

      for (let prop in newObject) {
        if (newObject.hasOwnProperty(prop) && !newObject[prop]) {
          newObject[prop] = true
          break
        }
      }
      !_.isEqual(seeTimeLine, newObject) && setSeeTimeLine({...newObject})
      
    }

    const ColorlibStepIcon = (props: StepIconProps)=> {
        const { active, completed, className } = props;
      
        const iconsInitial = {
          1:{ 
              icon: <img src={Images.iconoDescarga} alt='' />,
              date: new Date(timeLine.receivedAt!),
            },
          2:{ 
              icon: <img src={Images.iconoNulo} alt='' />,
              date: timeLine.cancelledAt ? new Date(timeLine.cancelledAt!) : null,
            },
          3:{ 
              icon: <img src={Images.iconoCheck} alt='' />,
              date: new Date(timeLine.filedAt!),
            },
          4:{ 
              icon: <img src={Images.iconoPago} alt='' />,
              date: new Date(timeLine.conciliationAt!),
            },
          5:{ 
              icon: <img src={Images.iconoManos} alt='' />,
              date: new Date(timeLine.authorizedToPaymentAt!),
            },
          6:{ 
              icon: <img src={Images.iconoMonedas} alt='' />,
              date: new Date(timeLine.paid_at!),
            },
        };
      
        let iconsArray = Object.entries(iconsInitial);
        iconsArray = iconsArray.filter(icon=> icon[1].date !== null)
        iconsArray.sort((a:any, b:any) => a[1].date - b[1].date);

        const icons:any = {};
        iconsArray.forEach((item, index) => {
            icons[index + 1] = item[1].icon;
        });
      
        return (
          <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
          >
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }

      const validateCancelledAt = ()=>{
        return !!timeLine.cancelledAt && false
      }

      let steps = [
        {
          date: new Date(timeLine.receivedAt!),
          label: "Factura Recibida",
          active: seeTimeLine.receivedAt && !!timeLine.receivedAt,
          completed: seeTimeLine.receivedAt && !!timeLine.receivedAt
      },
      {
          date: timeLine.cancelledAt ? new Date(timeLine.cancelledAt) : null,
          label: "Factura Anulada",
          active: seeTimeLine.cancelledAt && ( !!timeLine.cancelledAt || !!timeLine.filedAt),
          completed: seeTimeLine.cancelledAt &&  !!timeLine.cancelledAt
      },
      {
          date: new Date(timeLine.filedAt!),
          label: "Factura radicada",
          active: seeTimeLine.filedAt && ( validateCancelledAt() || !!timeLine.filedAt || !!timeLine.conciliationAt || !!timeLine.authorizedToPaymentAt),
          completed: seeTimeLine.filedAt && ( validateCancelledAt()|| !!timeLine.filedAt || !!timeLine.conciliationAt || !!timeLine.authorizedToPaymentAt)
      },
      {
          date: new Date(timeLine.conciliationAt!),
          label: "Factura En conciliación",
          active: seeTimeLine.conciliationAt && ( validateCancelledAt() || !!timeLine.conciliationAt || !!timeLine.authorizedToPaymentAt ),
          completed: seeTimeLine.conciliationAt && ( validateCancelledAt() || !!timeLine.conciliationAt || !!timeLine.authorizedToPaymentAt)
      },
      {
          date: new Date(timeLine.authorizedToPaymentAt!),
          label: "Factura Autorizada para pago",
          active: seeTimeLine.authorizedToPaymentAt && ( validateCancelledAt() || !!timeLine.authorizedToPaymentAt  || !!timeLine.paid_at),
          completed: seeTimeLine.authorizedToPaymentAt && ( validateCancelledAt() || !!timeLine.authorizedToPaymentAt || !!timeLine.paid_at)
      },
      {
          date: new Date(timeLine.paid_at!),
          label: "Factura Pagada",
          active: seeTimeLine.paidAt && ( validateCancelledAt() || !!timeLine.paid_at),
          completed: seeTimeLine.paidAt && ( validateCancelledAt() || !!timeLine.paid_at)
      },
  ];
  steps = steps.filter(step=> step.date !== null);

  const sortSteps = steps.findIndex(item => item.label === "Factura Anulada");
  
  (sortSteps !== -1) && steps.sort((a:any, b:any) => a.date - b.date);

  return {
    ColorlibStepIcon,
    changeSeeTimeLine,
    steps,
    seeTimeLine

  }
}
