import React, { Fragment, useEffect } from 'react';
import { ModalReplyGlosses } from './modal-reply-glosses/ModalReplyGlosses';
import { RouterGuard, Spinner } from 'shared/components';
import { useTabGlosses } from '../hooks/useTabGlosses';
import { DetailedGlosse } from './modal-reply-glosses/DetailedGlosse';
import { Box, TableContainer } from '@mui/material';
import { GridAuditGlosses } from './audit-glosses/GridAuditGlosses';
import styles from '../styles/tabGlosses.module.scss';
import { FormAuditGlosses } from './audit-glosses/FormAuditGlosses';
import { accesRoles } from 'shared/models';
import { AuditResultAcceptanceForm } from './AuditResultAcceptanceForm';
import { statesActivities } from 'shared/models/lineStates';

export const TabGlosses = ({ NumberInvoice }: any) => {

    const { idDocument,
        glosas,
        dataGlosas,
        glossesTotalValue,
        loading,
        openModal,
        idGlossa,
        readOnly,
        billingState,
        enableConsolidate,
        dispatch,
        allGlossConsolited,
        sumValuesAllValueAccept,
        sumValuesAllValueNotAccept, 
        sumValuesAllValueSustained,
        consolidateGlosas,
        onCloseSetReadOnly,
        convertDataGlossas,
        onOpenModalReplyGlossas,
        handleReplyGlossa,
        validateGlossesUpdatatables,
        checkUserRoleToEnableResultAcceptSection,
        acceptedConsolidatedValue
    } = useTabGlosses();

    useEffect(() => {
        dispatch.consolidateGlosas.getlistGlosasAsync(idDocument)
    }, [])

    

    return (
        <div className={styles.containerGlosses}>
            {
                glosas &&
                    glosas.length > 0 ?
                    <Fragment>
                        {
                            dataGlosas && <DetailedGlosse dataGlosas={dataGlosas} title='Detalle de Glosas' />
                        }
                        <GridAuditGlosses 
                            glosas={glosas} 
                            billingState={billingState} 
                            glossesTotalValue={glossesTotalValue} 
                            sumValuesAllValueAccept={sumValuesAllValueAccept}
                            sumValuesAllValueNotAccept={sumValuesAllValueNotAccept}
                            sumValuesAllValueSustained={sumValuesAllValueSustained}
                            idDocument={idDocument} 
                            handleReplyGlossa={handleReplyGlossa} 
                            validateGlossesUpdatatables={validateGlossesUpdatatables} 
                        />
                        {
                            allGlossConsolited &&
                            <RouterGuard roles={[...accesRoles.auditor, ...accesRoles.healthProvider ]} type='component'>
                                <FormAuditGlosses />
                            </RouterGuard>
                        }
                        {
                            checkUserRoleToEnableResultAcceptSection() &&
                            <AuditResultAcceptanceForm acceptedConsolidatedValue={acceptedConsolidatedValue}/>
                        }
                    </Fragment>
                    : 
                        statesActivities.payments.includes(billingState.code) ? (
                            <TableContainer
                                className={styles.gridGlosasResults}
                                data-testid="grid-glosas"
                            >
                                <Box className={styles.containerNotResults} data-testid="title-not-results">Esta Factura no tiene glosas gestionadas en el proceso de Auditoria</Box>
                            </TableContainer>
                        ) :
                        (
                            <TableContainer
                                className={styles.gridGlosasResultsNotResults}
                                data-testid="grid-glosas-not-results"
                            >
                                <Box className={styles.containerNotResults} data-testid="title-not-results">Aun no se ha cargado la información correspondiente de esta sección</Box>
                            </TableContainer>
                        )
            }

            {/* {(loading) && <Spinner />} */}

            <ModalReplyGlosses
                open={openModal}
                onOpen={onOpenModalReplyGlossas}
                onCloseSetReadOnly={onCloseSetReadOnly}
                billName={NumberInvoice}
                idGlossa={idGlossa}
                readOnly={readOnly}
                enableConsolidate={enableConsolidate}
            />

        </div>
    )
}
