import React, { useEffect, useRef } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RichTextEditor, Spinner } from 'shared/components';
import { InnerCountDown } from 'shared/components/InnerCountDown';
import styles from '../styles/modalResetAudit.module.scss'
import { useModalReset } from '../hooks/useModalReset';

interface Props {
    id: number;
    bill: string;
    onClose: ()=> void
}

const MemoInnerCountDown = React.memo(InnerCountDown);

export const ModalResetAudit = ({id, bill, onClose}: Props) => {

    const {isLoading, activeCode, activeIndexOtp, totalCaracteres, isDisableInputCode, otp, maxLength, richTextValue, onActiveResetAudit, errorInputOtp,  onChangeRichText, sendCodeResetAudit, onResetAudit, onSetIsDisableInputCode, handleOnChangeCode, handleKeyDown, cancelCodeOtp, onSetDisableConfirmButton, onSetRichTextValue} = useModalReset(id,onClose);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        onSetRichTextValue('<p><br></p>');
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeIndexOtp]);

  return (
    <>
        <Box className={styles.informationContainer} data-testid="modal-reset-audit">

            <IconButton className={styles.btnClose}>
                <CloseIcon onClick={onClose}/>
            </IconButton>

            <ErrorOutlineIcon className={styles.iconWarning}/>
            <Typography className={styles.titleWarning}>¡Atención!</Typography>


            { activeCode ? (
                <>
                    <Typography variant='body2' className={styles.descriptionText} data-testid="modal-counter-code">Por favor ingrese el código de confirmación enviado al correo electrónico de su supervisor, recuerde que este código vence en 30 minutos.</Typography>
                    <Typography variant='body2' className={styles.highlightedText}>
                        <MemoInnerCountDown setDisableInput={onSetIsDisableInputCode}/>
                    </Typography>
                    <Box className={styles.containerOtp}>
                        {otp.map((_, index:any) => {
                            return (
                                <React.Fragment key={index}>
                                    <input
                                        ref={index === activeIndexOtp ? inputRef : null}
                                        type="number"
                                        className={`${styles.inputOtp} ${errorInputOtp ? styles.inputOtpError : ''}`}
                                        onChange={(e)=> handleOnChangeCode(e, index)}
                                        onKeyDown={(e)=> handleKeyDown(e,index)}
                                        value={otp[index]}
                                        disabled={isDisableInputCode}
                                    />
                                    {index === otp.length - 1 ? null : (
                                        <span className={styles.separatorOtp} />
                                        )}
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </>
                )
                : (
                    <>
                        <Typography variant='body2' className={styles.descriptionText} data-testid="modal-text-editor">Ingrese el detalle para reiniciar auditoría de la factura {bill}</Typography>
                        <Typography variant='body2' className={styles.highlightedText}>Al confirmar se enviara un correo a su supervisor con un código de confirmación el cual deberá informar</Typography>
                        <Box className={styles.textRichDetailsContainer}>
                            <Box className={styles.textRichContainer}>
                                    <RichTextEditor
                                        onChange={onChangeRichText}
                                        readOnly={false}
                                        value={richTextValue}
                                    />
                            </Box>
                            <span className={styles.details}>{totalCaracteres}/{maxLength}</span>
                        </Box>

                        <Box className={styles.checkboxContainer}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        disableRipple 
                                        className={styles.checkbox}
                                        onChange={(event) => { onSetDisableConfirmButton(!event.target.checked) }}
                                    />
                                }
                                label={<span className={styles.checkboxLabel}>Confirmo que deseo reiniciar la auditoría</span>}
                            />
                        </Box>
                    </>

                )
            }

            <Box className={styles.buttonsContainer}>
                <Button
                    variant='contained'
                    className={styles.confirmButton}
                    data-testid='submit-reset-audit'
                    onClick={activeCode ? onResetAudit : sendCodeResetAudit}
                    disabled={activeCode ? onActiveResetAudit : false}
                >
                    Reiniciar auditoría
                </Button>
                <Button
                    variant='contained'
                    className={styles.cancelButton}
                    data-testid='cancel-reset-audit'
                    onClick={activeCode ? cancelCodeOtp : onClose}
                >
                    Cancelar
                </Button>
            </Box>
            {isLoading && <Spinner/>}
        </Box>
    </>
  )
}
