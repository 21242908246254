import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import './styles/_comboBoxCustom.scss';
import Images from 'shared/assets/index';

interface Props {
  handleChange: (value: any, idsDoc?: number[]) => void;
  placeholder: string;
  data: any[];
  idsDoc?: number[];
  currentValue?: any;
  isDisabled?: boolean;
  focusSelected?: boolean;
  useButtonIcon?: boolean;
}

export const ComboBoxCustom = ({ handleChange, placeholder, data, idsDoc, currentValue, isDisabled, focusSelected, useButtonIcon }: Props) => {
  const comboBoxRef = useRef<HTMLInputElement>(null);

  const [selectedValue, setSelectedValue] = useState(null);
  const [openComboBox, setOpenComboBox] = useState(false);

  const handleInputChange = (event: any, newValue: any) => {
    focusSelected && setSelectedValue(newValue);
    (newValue || event.target.textContent.length < 1) && handleChange(newValue, idsDoc);
  };

  useEffect(() => {
    if (openComboBox) {
      comboBoxRef.current?.focus();
    }
  }, [openComboBox])

  return (
    <div className='combobox-container' data-testid="container-combobox">
      {
        useButtonIcon ?
          <>
            <IconButton
              className='button-show-combobox'
              data-testid="btn-show-combobox"
              disabled={!!isDisabled}
              onClick={() => {
                setOpenComboBox(true);
              }}
            >
              <img className='show-combobox-image' src={Images.iconoAsignarUsuario} alt="Mostrar comboBox" />
            </IconButton>
            {
              openComboBox && !isDisabled &&
              <Box className='combobox'>
                <Autocomplete
                  open={openComboBox}
                  onBlur={() => { setOpenComboBox(false) }}
                  disablePortal
                  disabled={!!isDisabled}
                  data-testid="combo-box-custom"
                  popupIcon
                  options={data}
                  className="combobox-button-custom"
                  getOptionLabel={(option) => option.name}
                  value={selectedValue}
                  onChange={handleInputChange}
                  renderInput={(params) => <TextField {...params} inputRef={comboBoxRef} placeholder={currentValue?.name || placeholder} data-testid="search_comboBox" />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  noOptionsText="No se encontró sugerencias"
                  slotProps={{
                    clearIndicator: {
                      onClick: () => { 
                        handleChange('', idsDoc)
                        setSelectedValue(null)
                      }
                    }  
                  }}
                />
              </Box>
            }
          </> :
          <Autocomplete
            disablePortal
            disabled={!!isDisabled}
            data-testid="combo-box-custom"
            options={data}
            className="combobox-custom"
            getOptionLabel={(option) => option.name}
            value={selectedValue}
            onChange={handleInputChange}
            renderInput={(params) => <TextField {...params} placeholder={ currentValue?.name || placeholder } data-testid="search_comboBox" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="No se encontró sugerencias"  
            slotProps={{
              clearIndicator: {
                onClick: () => {
                  handleChange('', idsDoc)
                  setSelectedValue(null)
                }
              }
            }}
        />
      }
    </div>
  )
}
