import { useState } from 'react'
import dayjs from 'dayjs';
import { getEventTrace } from 'shared/api/services';

export const useTablaTrazabilidad = () => {

    const [ traceabilityDetails, setTraceabilityDetails ] = useState<[]>([])
    const [isLoading, setIsLoading] = useState(true);

    const convertServiceTraceabilityDetails = (eventTrace: any) => {
        setTraceabilityDetails(
            eventTrace.map((event: any) => {
                return {
                    id: event.id,
                    idFecha: dayjs(new Date(event.createdAt || '')).format('DD MMMM YYYY HH:mm:ss'),
                    idAccion: event.state.name,
                    idDescripcion: Object.values(event.eventDetails || []).join(', '),
                    idUsuario: event.createdBy
                }
            })
        )
        setIsLoading(false);
    }

    const callGetEventTrace = (idDocument: string) => {
        traceabilityDetails.length <= 0 && getEventTrace( idDocument || "" ).then((response: any) => {
            convertServiceTraceabilityDetails(response)
        }).catch(()=> setIsLoading(false))
    }

    return{
        callGetEventTrace,
        convertServiceTraceabilityDetails,
        traceabilityDetails,
        isLoading,
    }

}
