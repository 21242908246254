import { cleanFileName } from "shared/utils";
import { ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, GET_ELECTRONIC_DOCS, GLOSSES_AUDIT } from "..";
import { apiInstance } from "../config/apiInstance";
import { putUploadDocBlob } from "./putUploadDocBlob";
import { IAuditConsolidate, IPostAuditConsolidate } from "pages/read-documents/model/IAuditConsolidateGloses";

export const postAttachDocument = async (documentId: string, file: File): Promise<number> => {
    const response = await apiInstance.post(
        `v1/electronic-documents/${documentId}/generate-upload-link`,
        { fileNames: [cleanFileName(file.name)], }
    );

    const { link, id, originalName } = Array.isArray(response.data) ? response.data[0] : response.data;
    
    await putUploadDocBlob(link, id, originalName, file)

    return id;
}

export const getAttachDocument = async (documentId: string, fileId: number, originalName: string): Promise<File> => {
    const { data } = await apiInstance.get(
        `${GET_ELECTRONIC_DOCS}/${documentId}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}`,
        { params: { supportId: fileId }, responseType: 'blob' },
    );
    
    const fileBlob = new Blob([data], { type: 'application/pdf' });

    const newFile = new File([fileBlob], originalName, { type: 'application/pdf' });

    return newFile;
}

export const getAuditConsolidate = async (documentId: string): Promise<IAuditConsolidate> => {
    const { data } = await apiInstance.get(
        `${GLOSSES_AUDIT}/${documentId}`,
    );
    
    return {
        inNoAgreeDetail: data.inNoAgreeDetail,
        attachment: data.attachment
    }
}

export const postAuditConsolidate = async (documentId: string, request: IPostAuditConsolidate): Promise<boolean> => {
    await apiInstance.post(
        `${GLOSSES_AUDIT}/${documentId}`,
        request
    );
    
    return true;
}