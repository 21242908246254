import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'shared/store/store';
import Swal from "sweetalert2";
import { NumericFormat } from 'react-number-format';
import { CustomFileUploader, RichTextEditor, Spinner } from 'shared/components';
import { removeHTMLTagsFromText } from 'shared/utils';
import Image from 'shared/assets/index';
import _ from 'lodash';
import { GLOSSES_ANSWER } from 'shared/api';
import { createRoot } from 'react-dom/client';
import { SwalAlertContent } from '../SwalAlertContent';
import { IGlosa } from 'shared/models';
import { putData } from 'shared/api/services/putData';
import { postData } from 'shared/api/services/postdata';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { patchData } from 'shared/api/services/patchData';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import styles from './style/formReplyGlosses.module.scss';

interface Props{
  dataSingleGlosa: IGlosa;
  billName: string;
  lastGlossa: boolean;
  readOnly: boolean;
  documentsSupports: any;
  onCloseModal: ()=> void;
  callGetFiles: (idGlossa: number)=> void;
  downloadDocument: (id: number) => void;
  viewDocument: (id: number) => void;
}

export const FormReplyGlosse = ({onCloseModal, dataSingleGlosa, billName, lastGlossa, readOnly, documentsSupports, callGetFiles, downloadDocument, viewDocument}:Props) => {

    const {idDocument}= useParams();
    const dispatch = useDispatch<Dispatch>();
    const { response, loading } = useSelector((state: RootState) => state.linkSupports);
    const { callGetDetailedDocument } = useDetailedConsultation();
    const formikRef = React.useRef<FormikProps<any>>(null);
    const [totalCaracteres, setTotalCaracteres] = useState(0);
    const [richTextValue, setRichTextValue] = useState<string | null>(null);
    const [documents, setDocuments] = useState<any>(null);
    const [errors, setErrors] = useState(false);
    const typeAccept = ["PDF","JPG","PNG","JPEG","XLSX","XLS"];
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeFile = (files:any) => {
      const arrayFiles = Array.from(files)
      
      if(files.length > 5 || response?.length + files.length > 5){
        return errorCantidadArchivos()
      }

      if(files.length > 0){
        setDocuments(arrayFiles);
        dispatch.linkSupports.getLinkAsync({
          file: arrayFiles, 
          url: `v1/glosses/${idDocument}/generate-upload-link`
        })
      }
    };

    const removeFile = (doc:any) => {
      setDocuments(documents?.filter((x:any)=> x.name !== doc.originalName));
      dispatch.linkSupports.filterLinkState(doc.id);
    };

    const errorCantidadArchivos = () => {
      Swal.fire({
        title: 'Carga fallida',
        text: `El máximo de archivos permitidos es de 5`,
        icon: 'error',
        timer: 5500
      })
    }

    const validationSchema = () => Yup.object().shape({
      acceptedValue: Yup.string().max(14),
      sustainedValue: Yup.string().max(14),
      valueNotAccepted: Yup.string().max(14),
    });

    const changeRichText = (richText: string) => {
      const cleanText = removeHTMLTagsFromText(richText);
      setTotalCaracteres(cleanText.length)
      setRichTextValue(cleanText.length < 1 ? '<p><br></p>' : richText);
    }

    const valueGlossa = (value:string) => Number(value.toString().replace(/\$/g, '').replace(/\./g, '').replace(',', '.'))
    

    const confirmReplyGlossa = (paramsGlosa:any) => {
      setIsLoading(true);
      putData(`${GLOSSES_ANSWER}/${dataSingleGlosa.id!}`, paramsGlosa).then(async(res)=>{
        if(lastGlossa){
          return patchData(`${GLOSSES_ANSWER}/${idDocument}`).then(()=>{
            callGetDetailedDocument(idDocument || '');
            dispatch.consolidateGlosas.getlistGlosasAsync(idDocument).then(()=>{
              setIsLoading(false);
              Swal.clickCancel();
              onCloseModal();
            })
          })
        }
        dispatch.consolidateGlosas.getlistGlosasAsync(idDocument).then(()=>{
          setIsLoading(false);
          Swal.clickCancel();
          onCloseModal();
        })
      }).catch(()=>{
        setIsLoading(false);
      });
    }

    const handleSubmit = (values:any ) => {   
      const cleanText = removeHTMLTagsFromText(richTextValue!);

      if(!values.acceptedValue && !values.sustainedValue && !values.valueNotAccepted)
        return setErrors(true)

      if(!(documentsSupports.content.length > 0) && values.sustainedValue.slice(1) > 0 && documents === null)
        return Swal.fire({
          text: "Dede adjuntar por lo menos un documento",
          icon: "error",
          timer: 5500,
      })

      if(cleanText.length > 5000 || cleanText.length < 1){
        return Swal.fire({
            title: "Respuesta a glosa es obligatorio y caracteres máximos permitidos 5000",
            icon: "error",
            timer: 5000,
        });
      }

      setIsLoading(true);

      const paramsGlosa = {
        electronicDocumentId: idDocument,
        valueAccept: valueGlossa(values.acceptedValue),
        valueSustained: valueGlossa(values.sustainedValue),
        valueNotAccept: valueGlossa(values.valueNotAccepted),
        glossAnswer: richTextValue,
        attachmentIds: response?.map(res=> res.id) || []
      }
      const modalComponent = document.createElement("div");
      postData(`${GLOSSES_ANSWER}/${dataSingleGlosa.id!}`, paramsGlosa).then(()=>{
          createRoot(modalComponent).render(<SwalAlertContent
              description={ `¿Está seguro que desea responder a la glosa ${dataSingleGlosa.nameGlosa} de la factura ${billName} ?`}
              highlightedText={`${lastGlossa ? 'Recuerde que esta información será notificada al Auditor' : ''}`}
              useCheck={lastGlossa}
              checkText='Confirmo que he respondido la totalidad de las glosas'
              onCancel={() => { Swal.clickCancel(); }}
              onConfirm={()=>confirmReplyGlossa(paramsGlosa)}
              textBtn='Responder'
          />);
          Swal.fire({
              title: "¡Atención!",
              icon: "warning",
              html: modalComponent,
              showCancelButton: false,
              showConfirmButton: false,
          });
      }).finally(()=> setIsLoading(false))
    };

    const handleBlur = () => {
      const values = formikRef.current?.values

      if(values.acceptedValue || values.sustainedValue || values.valueNotAccepted)
        setErrors(false)
    }

    useEffect(() => {
      setRichTextValue(dataSingleGlosa.glossAnswer || ' ');
      setDocuments(null);

      return () => {
        setRichTextValue(null);
        dispatch.linkSupports.cleanState();
      }

    }, [dataSingleGlosa])    
    
    useEffect(() => {
      callGetFiles(dataSingleGlosa.id!)
    }, [dataSingleGlosa])

  return (
    <>
    {
      dataSingleGlosa &&

      <Formik
        innerRef={formikRef}
        initialValues={{
          acceptedValue: dataSingleGlosa.valueAccept.toString(),
          sustainedValue: dataSingleGlosa.valueSustained.toString(),
          valueNotAccepted: dataSingleGlosa.valueNotAccept.toString()
        }}
        onSubmit={(e)=>handleSubmit(e)}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form data-testid="form-justifyGlosa" className={styles.containerForm}>
            <Grid container className={styles.contentForm}>
                <div className={styles.containerTitleCard}>
                    <img src={Image.iconoResponder} alt="" />
                    <Typography className={styles.titleCard}>Responder Glosa</Typography>
                </div>
                <Typography className={styles.contentInformation}>
                    La respuesta a glosas se realizará conforme al Manual Único de Devoluciones, Glosas y Respuestas expedido por el Ministerio de Salud y Protección Social, el cual es de obligatoria adopción por los prestadores de servicios de salud según lo establecido en el artículo 57 de la Ley 1438 de 2011. <b> Recuerde que una vez termine la respuesta a la totalidad de las  glosas, éstas no podrán ser modificadas.</b> Tenga en cuenta que una vez responda la última Glosa de la factura  el sistema notificará al auditor para su posterior revisión. 
                </Typography>
              <Grid
                container
                direction={"row"}
              >
                <Grid item xs={12} md={4} className={styles.contentItem}>
                  <label htmlFor={"acceptedValue"} className={styles.spanForLabel}>
                  Valor Aceptado:
                  </label>

                  <Field name={"acceptedValue"} className={`${styles.inputItemValue}`}>
                    {({ field }: FieldProps) => (
                      <NumericFormat
                        {...field}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        className={`${styles.inputItemValue} ${errors && styles.inputItemError}`}
                        decimalScale={2}
                        maxLength={13}
                        value={formik.values.acceptedValue}
                        placeholder='0'
                        prefix="$"
                        onBlur={handleBlur}
                        disabled={readOnly}
                      />
                    )}
                  </Field>
                  <Box display="flex" justifyContent="space-between">
                    <span className={styles.spanCounter}>
                      {formik?.values?.["acceptedValue"]?.toString().replace(/[^\d.]/g, '').length || 0}
                      /10
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} className={styles.contentItem}>
                  <label htmlFor={"sustainedValue"} className={styles.spanForLabel}>
                    Valor sustentado
                  </label>

                  <Field name={"sustainedValue"} className={styles.inputItemValue}>
                    {({ field }: FieldProps) => (
                      <NumericFormat
                        {...field}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        className={`${styles.inputItemValue} ${errors && styles.inputItemError}`}
                        decimalScale={2}
                        maxLength={13}
                        value={formik.values.sustainedValue}
                        placeholder='0'
                        prefix="$"
                        onBlur={handleBlur}
                        disabled={readOnly}
                      />
                    )}
                  </Field>
                  <Box display="flex" justifyContent="space-between">
                    <span className={styles.spanCounter}>
                      {formik?.values?.["sustainedValue"]?.toString().replace(/[^\d.]/g, '').length || 0}
                      /10
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} className={styles.contentItem}>
                  <label htmlFor={"valueNotAccepted"} className={styles.spanForLabel}>
                    Valor no aceptado
                  </label>

                  <Field name={"valueNotAccepted"} className={styles.inputItemValue}>
                    {({ field }: FieldProps) => (
                      <NumericFormat
                        {...field}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        className={`${styles.inputItemValue} ${errors && styles.inputItemError}`}
                        decimalScale={2}
                        maxLength={13}
                        value={formik.values.valueNotAccepted}
                        placeholder='0'
                        prefix="$"
                        onBlur={handleBlur}
                        disabled={readOnly}
                      />
                    )}
                  </Field>
                  <Box display="flex" justifyContent="space-between">
                    <span className={styles.spanCounter}>
                      {formik?.values?.["valueNotAccepted"]?.replace(/[^\d.]/g, '').length || 0}
                      /10
                    </span>
                  </Box>
                </Grid>
                {
                  errors && 
                    <Grid item xs={12}>
                      <span className={styles.textInputError}>Es obligatorio diligenciar  por lo menos  uno de los siguientes campos: Valor Aceptado, Valor Sustentado o Valor en No aceptado.</span>
                    </Grid>
                }
                {
                    formik?.values?.["sustainedValue"] && formik?.values?.["sustainedValue"].slice(1).length > 0 &&
                      <Grid item xs={12} className={styles.contentItem}>
                      {
                        readOnly && documentsSupports ? 
                          
                            <Paper className={styles.containerTableResults}>
                              <div className={styles.containerTitleCard}>
                                  <img src={documentsSupports.icon} alt="" />
                                  <Typography className={styles.titleCard} data-testid="title-documents-supports">{documentsSupports.title}</Typography>
                              </div>
                              <TableContainer className={styles.tableResults} data-testid="table-documents-supports">
                                  <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                                      <TableHead>
                                          <TableRow className={styles.tablaHeader}>
                                              {
                                                  documentsSupports.columns.map((col:any,i:number)=>(
                                                      <TableCell 
                                                          key={i} 
                                                          className={styles.tableCell}
                                                          style={{ minWidth: col.minWidth }}
                                                      >
                                                          { col.headerName }
                                                      </TableCell>
                                                  ))
                                              }
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {
                                              documentsSupports.content.map((col:any,i:number)=>(
                                                  <TableRow key={i} data-testid={"table-results-body"}>
                                                      <TableCell className={styles.tableCell}>{col.originalName}</TableCell>
                                                      <TableCell className={styles.tableCell}>{col.createdAt}</TableCell>

                                                      <TableCell className={styles.tableCell}>
                                                          <Tooltip title={'descargar'} placement="top" arrow>
                                                              <IconButton aria-label="download" data-testid="download-support" onClick={()=>downloadDocument(dataSingleGlosa.id!)}>
                                                                  <DownloadForOfflineIcon />
                                                              </IconButton>
                                                          </Tooltip>
                                                          <Tooltip title={'Visualizar'} placement="top" arrow>
                                                              <IconButton aria-label="view" data-testid="view-support" onClick={()=>viewDocument(col.id!)}>
                                                                  <VisibilityIcon />
                                                              </IconButton>
                                                          </Tooltip>
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                          }
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                                  
                          </Paper>
                          :  
                          <Box className={styles.contentLinkSupports}>
                            <label htmlFor={"valueNotAccepted"} className={styles.spanForLabel}>
                            Adjuntar Soportes:
                            </label>
                            <CustomFileUploader 
                              fileLength={10}
                              originalName={response}
                              typeAccept={typeAccept}
                              document={documents}
                              handleChangeFile={handleChangeFile}
                              removeFile={removeFile}
                              filesAllowed={5}
                              multiple={true}
                            />
                          </Box>
                      }
                      </Grid>
                }
                <Grid item xs={12} className={styles.contentItem}>
                    <Box className={styles.textRichDetailsContainer}>
                        <Typography variant='h6' className={styles.detailsLabel}>
                            Respuesta Glosa
                        </Typography>
                        <Box className={styles.textRichContainer}>
                          {
                            richTextValue &&
                            <RichTextEditor
                                onChange={changeRichText}
                                readOnly={readOnly}
                                value={richTextValue}
                            />
                          }
                        </Box>
                        <span className={styles.details}>{totalCaracteres}/{5000}</span>
                    </Box>
                </Grid>
              </Grid>
              {
                !readOnly && (
                  <Box className={styles.buttonsContainer}>
                        <Button
                            variant='contained'
                            className={styles.confirmButton}
                            data-testid='reply_glosses'
                            type='submit'
                        >
                            Responder
                        </Button>
                        <Button
                            variant='contained'
                            className={styles.cancelButton}
                            data-testid='cancel_reply_glosses'
                            onClick={onCloseModal}
                        >
                            Cancelar
                        </Button>
                    </Box>
                )
              }
            </Grid>
          </Form>
        )}
      </Formik>
    }
    {(loading || isLoading) && <Spinner/>}
    </>
  );
}
