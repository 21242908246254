import React, { useRef } from 'react';
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from 'formik';
import { Box, Button} from '@mui/material';
import { Dispatch } from 'shared/store/store';
import { useDispatch } from 'react-redux';
import styles from '../styles/modalAbortDocuments.module.scss';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { useParams } from 'react-router-dom';

export const ModalAbortDocument: React.FC<{onClose: ()=> void, id:number}> = ({onClose, id}) => {
  const dispatch = useDispatch<Dispatch>();
  const {idDocument}= useParams();
  const { callGetDetailedDocument } = useDetailedConsultation();
  const formRef = useRef<FormikProps<any>>(null);
  const validationSchema = () => Yup.object().shape({
      description: Yup.string().max(300)
  })

  const submitCancelDocument = (values:any) => {
    dispatch.electronicDocuments.putCancelDocument({
        url:`${id}/cancel`,
        cancelReason: values.description,
      }).then(()=>{
        dispatch.electronicDocuments.getElectronicDocumentAsync({id}).then(()=>{
          idDocument && callGetDetailedDocument(idDocument);
          onClose();
        })
    })
  }

  return (
    <>
      <Box>
          <Formik
              innerRef={formRef}
              initialValues={{
                  description: "",
              }}
              onSubmit={(values) => submitCancelDocument(values)}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
          >
              {
                  formik => (
                      <Form data-testid='form-abort-document'>
                          <Field
                              name="description"
                              as="textarea"
                              placeholder={"Motivo del rechazo"}
                              className={styles.fieldDocRejected}
                              data-testid='text-description'  
                              id="descripcion-form-items"
                              maxLength={300}
                          />

                          <div className={styles.contentButtons}>
                              <span className={styles.spanContador}>
                                  {formik?.values?.description?.length || 0}/300
                              </span>
                              <Button 
                                type="button" 
                                variant='contained' 
                                id='btn-retornar'
                                data-testid='btn-cancel'  
                                onClick={()=>onClose()}
                                >
                                  Cancelar
                              </Button>
                              <Button 
                                disabled={formik?.values?.description?.length < 1} 
                                type='submit' 
                                variant='contained' 
                                data-testid='btn-submit'
                                id='btn-aceptar'>
                                  Aceptar
                              </Button>
                          </div>
                      </Form>
                  )
              }
          </Formik>
      </Box>
    </>
  )
}
