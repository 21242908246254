export const getCharsTotal = ()=>{
    const condiciones = [
      { ancho: 300, valor: 90 },
      { ancho: 400, valor: 120 },
      { ancho: 495, valor: 190 },
      { ancho: 599, valor: 235},
      { ancho: 695, valor: 155 },
      { ancho: 780, valor: 120 },
      { ancho: 900, valor: 185 },
      { ancho: 1000, valor: 80 },
      { ancho: 1150, valor: 85 },
      { ancho: 1250, valor: 90 },
      { ancho: 1350, valor: 105 },
      { ancho: 1450, valor: 120 },
      { ancho: 1500, valor: 135 },
      { ancho: 1600, valor: 150 },
      { ancho: 1700, valor: 190 },
      { ancho: Infinity, valor: 200 },
    ];
    
    const anchoVentana = window.innerWidth;
    const condicionCumplida = condiciones.find(condicion => anchoVentana < condicion.ancho);
    return condicionCumplida?.valor || 140;
  }