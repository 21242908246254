import { INotification } from "shared/models/store/INotifications"

export const convertNotifications = (options: INotification[], page:number, total:number, changeTab?: boolean) => {
    const option =  options?.map((option )=>{
        return{
            id: option.id,
            title: option.title,
            message: option.message,
            status: option.status,
            createdAt: option.createdAt,
            electronicDocumentId: option.electronicDocumentId,
            action: option.action
        }
    })
    return {
        notifications: option,
        page,
        total,
        changeTab
    }
}