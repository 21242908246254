import React, { Fragment } from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { IGlosa, accesRoles } from 'shared/models';
import { CellRenderExpand } from 'pages/consolidate-glosas/components/CellRenderExpand';
import ReplyIcon from '@mui/icons-material/Reply';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Image from 'shared/assets/index';
import { contentGridGlosas } from 'pages/detailed-consultation/helpers/contentTableRowDetailed';
import { RouterGuard } from 'shared/components';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { patchData } from 'shared/api/services/patchData';
import { GLOSSES } from 'shared/api';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { activities } from 'shared/models/lineStates';
import styles from '../../styles/tabGlosses.module.scss';
import { IbillingState } from 'shared/models/store/IAuditDetails';

interface Props {
    glosas: IGlosa[];
    idDocument: string | undefined;
    glossesTotalValue: number | null;
    billingState: IbillingState;
    sumValuesAllValueAccept: number;
    sumValuesAllValueNotAccept: number;
    sumValuesAllValueSustained: number;
    validateGlossesUpdatatables: (col: IGlosa) => boolean;
    handleReplyGlossa: (col: IGlosa, read?: boolean | undefined, enable?: boolean | undefined) => void;
}

export const GridAuditGlosses = ({glosas, idDocument, glossesTotalValue, billingState, sumValuesAllValueAccept, sumValuesAllValueNotAccept, sumValuesAllValueSustained, handleReplyGlossa, validateGlossesUpdatatables}:Props) => {

    const dispatch = useDispatch<Dispatch>();
    const {callGetDetailedDocument} = useDetailedConsultation();
    const replyGlosaReadOnly = true;
    const enableConsolidateGlossas = true;

    const handleConsolidateGlossa = (col:IGlosa) => {
        patchData(`${GLOSSES}/${col.idGlosa}/consolidate/electronic-documents/${idDocument}`).then(()=>{
            dispatch.consolidateGlosas.getlistGlosasAsync(idDocument);
            callGetDetailedDocument(idDocument || '');
        });
    };
    const isGlossaConsolidate = (col:IGlosa)=> ['8'].includes(col.statusGlosa.code!);
  return (
    <Fragment>
        <Box className={styles.gridGlosasResults} data-testid="grid_audit_glosses">
            <div className='containerTitleCardTabs noPadding'>
                <img src={Image.iconoResumen} alt="" />
                <Typography>Tabla Resumen de Glosas</Typography>
            </div>
            <TableContainer
                style={{ marginTop: 10 }}
                data-testid="grid-glosas"
            >
                <Table
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow className={styles.tableHeader}>
                            {contentGridGlosas.map((col: any, i: number) => (
                            <TableCell
                                key={i}
                                className={styles.tableCell}
                                style={{ minWidth: col.minWidth }}
                            >
                                {col.headerName}
                            </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {glosas.map((col: IGlosa, i: number) => (
                                <TableRow key={i} data-testid={"table-results-body"}>
                                    <TableCell className={styles.tableCell}>
                                        {col.id}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        {col.codeGlosa}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        {col.nameGlosa}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        ${
                                            Number(col.valueGlosa || 0).toLocaleString("es-CO")
                                        }
                                    </TableCell>
                                    <CellRenderExpand col={col.justification}/>
                                    <TableCell className={styles.tableCell}>
                                        {col.statusGlosa.name}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        ${
                                            Number(col.valueAccept || 0).toLocaleString("es-CO")
                                        }
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        ${
                                            Number(col.valueSustained || 0).toLocaleString("es-CO")
                                        }
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        ${
                                            Number(col.valueNotAccept || 0).toLocaleString("es-CO")
                                        }
                                    </TableCell>

                                    <TableCell className={styles.tableCell}>
                                        {
                                            validateGlossesUpdatatables(col) &&
                                            <RouterGuard roles={[...accesRoles.auditor, ...accesRoles.healthProvider]} type='component'>
                                                <Tooltip title={"ver glosa"} placement="top" arrow>
                                                    <IconButton
                                                        aria-label="view"
                                                        data-testid="view-glosa"
                                                        onClick={() => handleReplyGlossa(col, replyGlosaReadOnly)}
                                                    >
                                                        <VisibilityIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </RouterGuard>
                                        }
                                        {
                                            billingState.code === activities.facturaGlosasRespondidas &&
                                                ['3','6','7'].includes(col.statusGlosa.code!) && 
                                                    <RouterGuard roles={accesRoles.auditor} type='component'>
                                                        <Tooltip title={"Consolidar glosa"} placement="top" arrow>
                                                            <IconButton
                                                                aria-label="consolidate"
                                                                data-testid="consolidate-glosa"
                                                                onClick={() => handleConsolidateGlossa(col)}
                                                                >
                                                                <CheckBoxOutlineBlankIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </RouterGuard>
                                        }
                                        {
                                            billingState.code === activities.facturaGlosasRespondidas &&
                                                ['4','5'].includes(col.statusGlosa.code!) && 
                                                    <RouterGuard roles={accesRoles.auditor} type='component'>
                                                        <Tooltip title={"Consolidar glosa"} placement="top" arrow>
                                                            <IconButton
                                                                aria-label="consolidate"
                                                                data-testid="consolidate-glosa"
                                                                onClick={() => handleReplyGlossa(col, replyGlosaReadOnly, enableConsolidateGlossas)}
                                                                >
                                                                <ReplyIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </RouterGuard>
                                        }
                                        {
                                            !isGlossaConsolidate(col) &&
                                            <RouterGuard roles={accesRoles.healthProvider} type='component'>
                                                <Tooltip title={"Responder glosa"} placement="top" arrow>
                                                    <IconButton
                                                        aria-label="update"
                                                        data-testid="update-glosa"
                                                        onClick={() => handleReplyGlossa(col)}
                                                    >
                                                        <ReplyIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </RouterGuard>
                                        }
                                        {
                                            isGlossaConsolidate(col) && 
                                            <RouterGuard roles={accesRoles.reader} type='component'>
                                                <IconButton
                                                    aria-label="consolidate"
                                                    data-testid="consolidate-glosa"
                                                    disabled
                                                    >
                                                    <CheckBoxIcon/>
                                                </IconButton>
                                            </RouterGuard>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow data-testid={"table-results-body"}>
                                        <TableCell className={styles.tableCell} colSpan={3}>Totales...</TableCell>
                                        <TableCell className={styles.tableCell} colSpan={3}>${glossesTotalValue?.toLocaleString("es-CO")}</TableCell>
                                        <TableCell className={styles.tableCell} colSpan={1}>${sumValuesAllValueAccept?.toLocaleString("es-CO")}</TableCell>
                                        <TableCell className={styles.tableCell} colSpan={1}>${sumValuesAllValueSustained?.toLocaleString("es-CO")}</TableCell>
                                        <TableCell className={styles.tableCell} colSpan={1}>${sumValuesAllValueNotAccept?.toLocaleString("es-CO")}</TableCell>
                                        <TableCell className={styles.tableCell} colSpan={1}></TableCell>
                                </TableRow>
                            </TableFooter>
                </Table>
            
            </TableContainer>
        </Box>

            <Box className={styles.contentTotalGlossas} data-testid="content-values">
                <div className={styles.namePayment}>Total Glosas</div>
                <div className={styles.separator}></div>
                <div className={styles.valuePayment} data-testid="value-glossa">${glossesTotalValue?.toLocaleString("es-CO")}</div>   
            </Box>
    </Fragment>
  )
}
