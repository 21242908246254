import { useState } from 'react'
import Image from 'shared/assets/index'
import { contentDataSupports } from '../helpers/contentTableRowDetailed';
import { useParams } from 'react-router-dom';
import { ITabSupports } from '../Models/IContentTabs';
import { getData } from 'shared/api/services/getData';
import { ELECTRONIC_SUPPORTS_DOWNLOAD, ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, GET_ELECTRONIC_DOCS, GET_ELECTRONIC_SUPPORTS } from 'shared/api';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';

export const useTabSupports = () => {

    const dispatch = useDispatch<Dispatch>();

    const [documents, setDocuments] = useState<ITabSupports>({
        icon:'',
        title: '',
        columns: [],
        content: [],
    })
    const [isLoading, setIsLoading] = useState(true); 
    const { idDocument } = useParams();

    const convertServiceGetSupports = (dataDocs: any) => {    
        const data = {
            icon: Image.iconoCheck,
            title: 'Soportes de  la prestación del Servicio',
            columns: contentDataSupports,
            content: dataDocs?.map((item:any, index:number) => {
                return {
                    id: item.id || '',
                    createdAt: item.createdAt || "",
                    status: item.status.name || "",
                    createdBy: item.createdBy || "",
                    originalName: item.originalName || "",
                }
            }) || []
        };    
        setDocuments(data);
        setIsLoading(false);
    }

    const callGetSupports = (idDocument: string) => {
        getData( `${GET_ELECTRONIC_DOCS}/${idDocument}${GET_ELECTRONIC_SUPPORTS}`).then((response: any) => {
            response?.length > 0 && convertServiceGetSupports(response)
            setIsLoading(false)
        }).catch(()=> setIsLoading(false))
    }

    const downloadSupports = ()=>{
        dispatch.electronicDocuments.getFileExcelToExport({
            url:`${idDocument}${ELECTRONIC_SUPPORTS_DOWNLOAD}`,
            text:"SOPORTES descargados con Exito"
        })
    }
    const downloadDocument = (id:number)=>{
        dispatch.electronicDocuments.getFileExcelToExport({
            url:`${idDocument}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}?supportId=${id}`,
            text:"SOPORTE descargado con Exito"
        })
    }
    
    const viewDocument = (id:any)=>{
        window.open(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/file/${id}`, "_blank", "noreferrer")
    }

    return {
        isLoading,
        documents,
        callGetSupports,
        convertServiceGetSupports,
        downloadDocument,
        viewDocument,
        downloadSupports,
    }
}
