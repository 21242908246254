import { Box, IconButton } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useCheckRoleAccess } from 'shared/hooks/useCheckRoleAccess';
import { IRespDocuments, accesRoles } from 'shared/models';
import Image from 'shared/assets/index';
import SaveIcon from '@mui/icons-material/Save';
import { Spinner } from 'shared/components';
import { GET_DATA_PAYMENT_LINE, GET_ELECTRONIC_DOCS } from 'shared/api';
import { getData } from 'shared/api/services';
import { putData } from 'shared/api/services/putData';
import styles from '../styles/provitionServiceDateField.module.scss';

interface Props {
    index: number;
    property: string;
    value: string;
    docState?:IRespDocuments,
}

export const LinePaymentField = ({ index, property, value, docState }: Props) => {

    const { idDocument } = useParams();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [userHasPermission, setUserHasPermission] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentList, setPaymentList] = useState<any[]>([]);
    const [payLine, setPayLine] = React.useState('');
    const [currentValue, setcurrentValue] = useState('')

    const { checkRoles } = useCheckRoleAccess();

    const handleChangeSelect = (event: any) => {
        setPayLine(event.target.value);
      };

    const editButton = () => {
        return (
            <IconButton
                className={styles.editButton}
                onClick={() => {
                    setIsEditing(!isEditing);
                }}
                disableRipple
                disabled={docState?.state.code === "1.3" || docState?.state.code === "1.4"}
                data-testid='icon_edit'
            >
                <img src={Image.iconoEditar} alt="Editar fecha" />
            </IconButton>
        )
    }

    const onSave = () => {
        setIsLoading(true);
        putData( `${GET_ELECTRONIC_DOCS}/${idDocument}/payment-line`, { "paymentLineId": payLine } )
            ?.then((res)=>{
                const paylineResponse = res.data.paymentLine.id
                const namePayline = paymentList.find((pay:any) => pay.id === paylineResponse).name
                setcurrentValue(namePayline)
                setPayLine(res.data.paymentLine.id)
                setIsEditing(false);
            }).finally(()=>{
                setIsLoading(false);
            })
    }

    useEffect(() => {
        async function fetchData() {
          const response = await getData(GET_DATA_PAYMENT_LINE);
          setPaymentList(response);
        }
        fetchData();
      }, [])

    useEffect(() => {
        setUserHasPermission(checkRoles(accesRoles.auditor));
    }, [])

    useEffect(() => {
        return () => {
            setcurrentValue('')
        }
    },[])

  return (
    <Fragment >
        {
            userHasPermission && isEditing ?
                <Box className={styles.fieldEditionContainer} data-testid='field_edition_container'>
                    <select
                        id="select-payline"
                        value={payLine}
                        onChange={handleChangeSelect}
                        data-testid='select_pay_line'
                        className={styles.selectPaymentLine}
                    >
                        <option value="">--</option>
                        {paymentList.map((line) => (  
                            <option
                            key={line.id}
                            value={line.id}
                            data-testid={`menu_item_${line.id}`}
                            >
                            {line.name}
                            </option>
                        ))}
                    </select>
                    <Box className={styles.buttonsContainer}>
                        { editButton() }

                        <IconButton
                            className={styles.saveButton}
                            disableRipple
                            onClick={onSave}
                            data-testid='icon_save'
                        >
                            <SaveIcon />
                        </IconButton>
                    </Box>
                </Box>
                :
                <>
                    <Box className={styles.inputEditonDetailsContainer} data-testid='content_line_payment'>
                        <input
                            data-testid={`input_value_${index}`}
                            className={styles.inputDetails}
                            type='text' 
                            disabled 
                            value={currentValue ? currentValue : value}
                        />
                        {
                            userHasPermission && editButton()
                        }
                    </Box>
                </>
        }
        {isLoading && <Spinner />}
    </Fragment>
  )
}
