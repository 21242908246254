import { Box, SxProps } from "@mui/material";
import styles from '../styles/tabPanel.module.scss'

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, sx={p:3}, className, ...other } = props;

    return (
        <div
            className={className || styles.tabPanelContenedor}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            data-testid='tab_panel'
        >
            <Box sx={sx} className={className ? 'boxTabPanel' : styles.boxContainer} data-testid='contenido_box'>
                {children}
            </Box>
        </div>
    );
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    sx?: SxProps<any>;
    className?: string;
}
