import {ThemeProvider} from '@emotion/react'
import {CssBaseline} from '@mui/material'
import {hexToRGB, theme} from './shared/utils'
import './styles/index.scss'
import {RouterProvider} from 'react-router-dom';
import {routerConfig} from 'routes/Router';
import React from "react";
import {getQueryParam} from "./shared/auth/oauh2-with-pkce/util";
import { ModalMessageComponent } from 'shared/components/ModalMessage.component';


const root = document.querySelector(':root') as HTMLElement;

const colorRGB = hexToRGB(process.env.REACT_APP_COLOR || '#119735');

root.style.setProperty('--general-color-r', colorRGB.red.toString())
root.style.setProperty('--general-color-g', colorRGB.green.toString())
root.style.setProperty('--general-color-b', colorRGB.blue.toString())
root.style.setProperty('--general-color-filter', process.env.REACT_APP_COLOR_FILTER || 'brightness(0) saturate(100%) invert(31%) sepia(97%) saturate(1700%) hue-rotate(122deg) brightness(92%) contrast(87%)')

function App() {
    const userType: string = getQueryParam('LT');

    if(!!userType) {
        localStorage.setItem('LT', userType);
    } else if(!(!!localStorage.getItem('LT'))) {
        localStorage.setItem('LT', 'E');
    }

  return (
    <ThemeProvider theme={ theme }>
      <ModalMessageComponent/>
      <CssBaseline />
      
      <RouterProvider router={routerConfig}/>
    </ThemeProvider>
  )
}

export default App
