import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'shared/store/store';
import { IGlosa, accesRoles } from 'shared/models';
import { getData } from 'shared/api/services';
import { GLOSSES } from 'shared/api';
import Image from 'shared/assets/index';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useAuth } from 'shared/auth';
import { statesActivities } from 'shared/models/lineStates';
import { exportFileGlosses } from 'shared/api/services/exportFileGlosses';
import { downloadDocument, downloadsTitle } from 'shared/utils';
dayjs.locale("es");

const codesGlossasUpdatables = [{
    code: '3',
    name: 'Aceptada'
},
{
    code: '4',
    name: 'Sustentada',
},
{
    code: '5',
    name: 'Sustentada Parcial',
},
{
    code: '6',
    name: 'No Acepta',
},
{
    code: '7',
    name: 'Aceptada Parcial Consolidada',
},
{
    code: '8',
    name: 'Consolidada',
},
]

export const useTabGlosses = () => {

    const dispatch = useDispatch<Dispatch>();
    const { consolidateGlosas, auditDetails, invoiceData } = useSelector((state: RootState) => state);
    const { content } = invoiceData.dataGeneralParameters[0];
    const { glosas, glossesTotalValue, edocumentPaymentAuthorization, allGlossConsolited, sumValuesAllValueAccept, sumValuesAllValueNotAccept, sumValuesAllValueSustained } = consolidateGlosas;
    const { billingState, valuesPaymentConsolidate } = auditDetails;
    const { loading } = useSelector((rootState: RootState) => rootState.loading.models.consolidateGlosas);
    const { idDocument } = useParams();
    const { authService } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [enableConsolidate, setEnableConsolidate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataGlosas, setDataGlosas] = useState<any>(null);
    const [idGlossa, setIdGlossa] = useState(0);
    const [readOnly, setReadOnly] = useState(false);
    const [documentsSupports, setDocumentsSupports] = useState<any | null>(null)
    const enableConsolidateGlossas = true;

    const onOpenModalReplyGlossas = (open: boolean) => {
        setOpenModal(open);
    };
    const onCloseSetReadOnly = () => {
        setReadOnly(false);
    };

    const contentDataGlossas = [
        { label: 'Fecha', idLabel: 'dateGlossa' },
        { label: 'Total', idLabel: 'totalGlossa' },
        { label: 'Auditor', idLabel: 'auditGlossa' },
        { label: 'Observaciones', idLabel: 'justification' },
    ];

    const handleReplyGlossa = (col: IGlosa, read?: boolean, enable?: boolean) => {
        const isReadOnly = validateGlossesUpdatatables(col);
        read && setReadOnly(isReadOnly);
        (enable || isGlossaConsolidate(col)) ? setEnableConsolidate(enableConsolidateGlossas) : setEnableConsolidate(!enableConsolidateGlossas);
        onOpenModalReplyGlossas(true);
        setIdGlossa(col.id!);
    };

    const validateGlossesUpdatatables = (col: IGlosa) => codesGlossasUpdatables.some((code: any) => code.code === col.statusGlosa.code);
    const isGlossaConsolidate = (col: IGlosa) => (typeof col.glossSupport === 'boolean');

    const convertDataGlossas = () => {
        const data = {
            columns: contentDataGlossas,
            content: {
                dateGlossa: edocumentPaymentAuthorization.createdAt ? dayjs(new Date(edocumentPaymentAuthorization.createdAt)).format('DD MMMM YYYY HH:mm') : "",
                totalGlossa: glossesTotalValue?.toLocaleString("es-CO"),
                auditGlossa: edocumentPaymentAuthorization.createdBy || "",
                justification: edocumentPaymentAuthorization.glossesGeneralDetail || "",
            }
        }
        setDataGlosas(data);
    }

    const acceptedConsolidatedValue = !!valuesPaymentConsolidate?.initialValues.acceptedConsolidatedValue?.value;

    const checkUserRoleToEnableResultAcceptSection = () => {
        const userRoles = authService.getUserRoles();

        const isAudithor = userRoles.some(role => accesRoles.auditor.includes(role));
        const isHealthProviderRol = userRoles.some(role => accesRoles.healthProvider.includes(role));

        const billingCorrectState = statesActivities.acceptAuditConsolidation.includes(billingState.code);

        if (isAudithor) {
            const hasAcceptanceDate = !!content.fechaAceptacionResultado;
        
            return hasAcceptanceDate && billingCorrectState;
        }
        
        return isHealthProviderRol && billingCorrectState;
    }

    const convertServiceGetFiles = (dataDocs: any) => {
        const data = {
            icon: Image.iconoCheck,
            title: 'Soportes Adjuntos',
            columns: [
                { field: 'originalName', headerName: 'Nombre del soporte', minWidth: 180 },
                { field: 'createdAt', headerName: 'Fecha', minWidth: 160 },
                { field: 'actions', headerName: 'Acciones ', minWidth: 160 },
            ],
            content: dataDocs?.map((item: any, index: number) => {
                return {
                    id: item.id || '',
                    createdAt: item.createdAt || "",
                    status: item.status.name || "",
                    createdBy: item.createdBy || "",
                    originalName: item.originalName || "",
                }
            }) || []
        };
        setDocumentsSupports(data);
    }

    const callGetFiles = (idGlossa: number) => {
        setIsLoading(true);
        getData( `${GLOSSES}/${idGlossa}/associated-support`).then((response: any) => {
            convertServiceGetFiles(response)
        }).finally(()=>setIsLoading(false))
    }


    const downloadSupports = async (id: number) => {
        const response = await exportFileGlosses({
            url: `${GLOSSES}/${id}/download`,
        })
        downloadDocument(response, downloadsTitle.successDocument, "SOPORTE descargado con Exito", 'success');
    }

    const viewDocument = (id:any)=>{
        window.open(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/file/${id}`, "_blank", "noreferrer")
    }

    useEffect(() => {
        convertDataGlossas();
    }, [glosas, glossesTotalValue])

    return {
        loading,
        isLoading,
        dataGlosas,
        glosas,
        glossesTotalValue,
        idDocument,
        openModal,
        idGlossa,
        readOnly,
        billingState,
        enableConsolidate,
        documentsSupports,
        dispatch,
        valuesPaymentConsolidate,
        allGlossConsolited,
        sumValuesAllValueNotAccept,
        sumValuesAllValueSustained,
        sumValuesAllValueAccept,
        acceptedConsolidatedValue,
        consolidateGlosas,
        viewDocument,
        onCloseSetReadOnly,
        handleReplyGlossa,
        validateGlossesUpdatatables,
        convertDataGlossas,
        onOpenModalReplyGlossas,
        checkUserRoleToEnableResultAcceptSection,
        downloadSupports,
        callGetFiles
    }
}