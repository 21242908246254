import deb from "lodash";


export const debounceFunction = deb.debounce((val: () => void) => {
    val();
}, 500)

export const debounceFunction400 = deb.debounce((val: () => void) => {
    val();
}, 400)

export const debounceFunction700 = deb.debounce((val: () => void) => {
    val();
}, 800)

export const debounceFunction10000 = deb.debounce((val: () => void) => {
    val();
}, 10000)
