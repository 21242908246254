import { CreditNoteType } from "pages/detailed-consultation/Models/ECreditNoteType";
import { RESULT_ACCEPT_VALUE } from "..";
import { apiInstance } from "../config/apiInstance";

export const postResultAccepted = async (documentId: string, type: CreditNoteType) => {
    await apiInstance.post(
        `${RESULT_ACCEPT_VALUE}/${documentId}`,
        { type }
    );

    return true;
}
