import { Box, Grid, Typography } from '@mui/material';
import style from '../styles/detailedConsultation.module.scss'
import { ComboBoxCustom } from 'shared/components/ComboBoxCustom';
import { RouterGuard } from 'shared/components';
import { accesRoles, IDocumentTotals, IElectronicDocumentLine, IRespDocuments } from 'shared/models';
import { useTableResults } from 'shared/hooks/useTableResults';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentLinesTable } from "./DocumentLinesTable";
import { ProvitionServiceDateField } from './ProvitionServiceDateField';
import { LinePaymentField } from './LinePaymentField';
import { ViewDetailButton } from './ViewDetailButton';
import Image from 'shared/assets/index';

interface Props {
    detailedParameters: any;
    documentLines?: IElectronicDocumentLine[];
    documentTotals?: IDocumentTotals;
    showDocumentLines?: boolean;
    docState?:IRespDocuments,
}

export const TabDetails = ({ detailedParameters, documentLines, documentTotals, showDocumentLines, docState }: Props) => {

    const { handleUserAssignment, validateUserAuditor } = useTableResults();
    const { idDocument } = useParams();
    const navigate = useNavigate();
    
    return (
        <>
            {
                detailedParameters.length > 0 && detailedParameters.map((item: any, index: number) =>
                    <div className={`${style.containerBody} containerForm`} key={index} data-testid={'tabs-details'}>
                        <div className='containerTitleCardTabs'>
                            <img src={item.icon} alt="" />
                            <Typography>{item.title}</Typography>
                        </div>

                        {
                            item.hasContent ?
                                <Grid container direction={"row"} className={style.contentDetails}>
                                    {
                                        item.columns?.map((column: any, index: number) => {
                                            if (column.idLabel === 'assignedTo') {
                                                return (
                                                    <RouterGuard roles={accesRoles.usersSSSA} type="component" key={index}>
                                                        <Grid item xs={12} sm={4} md={3} className={style.field} key={index} >
                                                            <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                            <ComboBoxCustom
                                                                handleChange={handleUserAssignment}
                                                                data={item.content.users}
                                                                idsDoc={[Number(idDocument)]}
                                                                currentValue={item.content.currentUser}
                                                                placeholder='Asignar'
                                                                focusSelected={true}
                                                                isDisabled={docState?.state.code === "1.3" || docState?.state.code === "1.4" || validateUserAuditor()}
                                                            />
                                                        </Grid>
                                                    </RouterGuard>
                                                )
                                            }

                                            if (column.idLabel === 'contrato') {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index} >
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <div style={{ display: 'inline-flex' }}>
                                                            <label><input type='radio' name="contrato" value="Si" disabled checked={item.content.contrato == "Si"}></input>Si</label>
                                                            <label><input type='radio' name="contrato" value="No" disabled checked={item.content.contrato == "No"}></input>No</label>
                                                        </div>
                                                    </Grid>
                                                )
                                            }

                                            if (['fechaInicioServicios', 'fechaFinalServicios'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index}>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <ProvitionServiceDateField 
                                                            value={item.content[column.idLabel]}
                                                            property={column.idLabel}
                                                            index={index}
                                                            docState={docState}
                                                            />
                                                    </Grid>
                                                )
                                            }
                                            
                                            if (['lineaDePago'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index}>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <LinePaymentField
                                                            value={item.content[column.idLabel]}
                                                            property={column.idLabel}
                                                            index={index}
                                                            docState={docState}
                                                            />
                                                    </Grid>
                                                )
                                            }

                                            if (['nroContrato'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index}>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <ViewDetailButton
                                                            index={index}
                                                            value={item}
                                                            column={column}
                                                            accesRoles={accesRoles.auditor}
                                                            titleTooltip='Ver detalle del  contrato'
                                                            onClickIcon={() => navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/ver_detalle_contrato/${item?.content[column.idLabel]}`) }
                                                            disabledButton={false}
                                                            typeIcon={Image.iconoVer}
                                                        />
                                                    </Grid>
                                                )
                                            }

                                            return (
                                                <Grid item xs={12} sm={4} md={3} className={style.field} key={index} >
                                                    <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                    <input data-testid={`input_value_${index}`} className={style.inputDetails} type='text' disabled value={
                                                        item.content[column.idLabel]
                                                    } />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid> :
                                <Box className={style.containerResults}>Aún no se ha cargado la información correspondiente de esta sección</Box>
                        }

                    </div>

                )
            }
            {showDocumentLines ? <DocumentLinesTable documentLines={documentLines} documentTotals={documentTotals} /> : null}
        </>
    )
}
