import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Spinner } from 'shared/components';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styles from '../styles/tabDocumentsRelated.module.scss'
import { useTabSupports } from '../hooks/useTabSupports';
import { IContentTabSupports } from '../Models/IContentTabs';
import { EdocumentsOpened } from '../helpers/enumDocumentsOpened';

export const TabSupports = () => {

    const { idDocument } = useParams();
    const { isLoading, documents, callGetSupports, downloadSupports, downloadDocument, viewDocument } = useTabSupports()

    const validateTypeDocument = (value:string) => {
        const typeDoc = value.split('.')
        return Object.values(EdocumentsOpened).includes(typeDoc[typeDoc.length -1])
    }
    
    useEffect(() => {
        callGetSupports(idDocument || "")
    },[])


    return (
        <>
            {
                documents.content.length > 0 ? (
                    <>
                        <Button 
                            color='primary' 
                            variant='contained' 
                            sx={{display:"block", marginLeft: "auto", marginTop:"1rem", marginBottom:"1rem", borderRadius:100}}
                            onClick={()=>downloadSupports()}
                            data-testid='btn-download-supports'
                        >
                            Descargar Soportes
                        </Button>
                        <Paper className={styles.containerTableResults}>
                            <div className='containerTitleCardTabs'>
                                <img src={documents.icon} alt="" />
                                <Typography data-testid="title-documents-supports">{ documents.title }</Typography>
                            </div>
                            <TableContainer className={styles.tableResults} data-testid="table-documents-supports">
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                                    <TableHead>
                                        <TableRow className={styles.tablaHeader}>
                                            {
                                                documents.columns.map((col:any,i:number)=>(
                                                    <TableCell 
                                                        key={i} 
                                                        className={styles.tableCell}
                                                        style={{ minWidth: col.minWidth }}
                                                    >
                                                        { col.headerName }
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            documents.content.map((col:IContentTabSupports,i:number)=>(
                                                <TableRow key={i} data-testid={"table-results-body"}>
                                                    <TableCell className={styles.tableCell}>{col.id}</TableCell>
                                                    <TableCell className={styles.tableCell}>{col.originalName}</TableCell>
                                                    <TableCell className={styles.tableCell}>{col.createdAt}</TableCell>
                                                    <TableCell className={styles.tableCell}>{col.status}</TableCell>
                                                    <TableCell className={styles.tableCell}>{col.createdBy}</TableCell>

                                                    <TableCell className={styles.tableCell}>
                                                        <Tooltip title={'descargar'} placement="top" arrow>
                                                            <IconButton aria-label="download" data-testid="download-support" onClick={()=>downloadDocument(col.id)}>
                                                                <DownloadForOfflineIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {
                                                            validateTypeDocument(col.originalName) ?
                                                            <Tooltip title={'Ver documento'} placement="top" arrow>
                                                                <IconButton aria-label="view" data-testid="view-support" onClick={()=>viewDocument(col.id)} disabled={!validateTypeDocument(col.originalName)}>
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            : 
                                                            <IconButton aria-label="view" data-testid="view-support" onClick={()=>viewDocument(col.id)} disabled={!validateTypeDocument(col.originalName)}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                                
                        </Paper>
                    </>
                    )
                : (
                    <Box className={styles.containerNotResults} data-testid="title-not-results">Aún no se ha cargado la información correspondiente de esta sección</Box>
                )
            }
            {(isLoading) && <Spinner/>}
        </>

    )
}
