const isUnderMaintenance: boolean = process.env.REACT_APP_UNDERMAINTENANCE === 'true';
const arrayMatches = (process.env.REACT_APP_USERSWITHACCESS || '').match(/\[.*?\]/);

const usersWithAccess: string[] = arrayMatches ? JSON.parse((process.env.REACT_APP_USERSWITHACCESS || '').replace(/'/g, "\"")) : [];

export const checkUnderMaintenance = (emailUser: string) => {
    if (!isUnderMaintenance) {
        return false;
    }

    if (isUnderMaintenance && !usersWithAccess.includes(emailUser)) {
        return true;
    }
};