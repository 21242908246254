import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { IInvoiceData } from "shared/models/store/IInvoiceData";
import Image from 'shared/assets/index';

export const invoiceData = createModel<RootModel>()({
    state: {
        dataGeneralParameters: [{
            icon: Image.iconoDocumento,
            title: 'Información General de la Factura',
            hasContent: true,
            columns: [],
            content: {
                nroFactura: '',
                fechaExpedicion: '',
                cunFactura: '',
                numeroRadicado: '',
                fechaRadicado: '',
                estado: '',
                actividad: '',
                currentUser: '',
                users: '',
                contrato: '',
                nroContrato: '',
                lineaDePago: '',
                fechaInicioServicios: '',
                fechaFinalServicios: '',
                assignedTo: '',
                totalFactura: '',
                saldoFactura: '',
                valorCuotaRecuperacion: '',
                valorRecuperado: '',
                fechaAceptacionResultado: '',
                acceptedValue: undefined,
                authorizedPaymentValue: undefined
            }
        }],
    } as IInvoiceData,
    reducers: {
        addDataGeneralParameters(state, { columns, content }) {
            return {
                ...state,
                dataGeneralParameters: [{
                    ...state.dataGeneralParameters[0],
                    columns,
                    content
                }]
            }
        },
        modifyFechaInicioServicios(state, newDate) {
            return {
                ...state,
                dataGeneralParameters: [{
                    ...state.dataGeneralParameters[0],
                    content: {
                        ...state.dataGeneralParameters[0].content,
                        fechaInicioServicios: newDate
                    }
                }]
            }
        },
        modifyFechaFinalServicios(state, newDate) {
            return {
                ...state,
                dataGeneralParameters: [{
                    ...state.dataGeneralParameters[0],
                    content: {
                        ...state.dataGeneralParameters[0].content,
                        fechaFinalServicios: newDate
                    }
                }]
            }
        },
    }
});
