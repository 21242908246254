import { TableCell } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { FC, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../styles/_renderCellExpand.scss';


export const CellRenderExpand:FC<{col:string}> = ({col}) => { 

    const [renderCell, setRenderCell] = useState(false);

    const expandCell = () => {
        setRenderCell(!renderCell)
    }

    return(
            <TableCell className='tableCell'>
                { 
                    col.length > 250 && renderCell 
                        ? <> {col} <span onClick={expandCell} className='btnExpandCell'> <KeyboardArrowUpIcon/> </span> </>   
                        :
                            col.length > 250 
                            ? <> {col.slice(0,250)} <span onClick={expandCell} className='btnExpandCell'> Mostrar más </span> </>
                            : col 
                        
                } 
            </TableCell>
    )
}
