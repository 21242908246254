import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dispatch } from "shared/store/store";

export const useNavigateToCustom = () => {
    const dispatch = useDispatch<Dispatch>();
    const navigate = useNavigate();

    const navigateHome = () => {
		navigate('/');
	}

    const navigateToInvoices = () => {
		dispatch.menuDrawer.changeSelectedIndex(1);
		
		navigate('/consulta_documentos_electronicos');

        setTimeout(() => {
            dispatch.electronicDocuments.cleanState();
        });
	}


    return {
        navigateToInvoices,
        navigateHome
    }
}
