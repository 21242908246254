import React, { useEffect, useState } from 'react';
import Image from 'shared/assets/index';
import styles from './style/formReplyGlosses.module.scss';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { IGlosa } from 'shared/models';
import { RichTextEditor, Spinner } from 'shared/components';
import { removeHTMLTagsFromText } from 'shared/utils';
import Swal from 'sweetalert2';
import { createRoot } from 'react-dom/client';
import { SwalAlertContent } from '../SwalAlertContent';
import { putData } from 'shared/api/services/putData';
import { GLOSSES } from 'shared/api';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';

interface Props{
    onCloseModal: ()=> void;
    dataSingleGlosa: IGlosa;
    billName: string;
    idDocument: string;
  }

export const FormConsolidateGlossas = ({onCloseModal, dataSingleGlosa, billName, idDocument}:Props) => {
    const dispatch = useDispatch<Dispatch>();
    const {callGetDetailedDocument,provider} = useDetailedConsultation();
    const [valueRadioButton, setValueRadioButton] = useState<null | string>(null);
    const [totalCaracteres, setTotalCaracteres] = useState(0);
    const [richTextValue, setRichTextValue] = useState<string | null>(' ');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string | null>(null);

    const isReadOnly = ()=> dataSingleGlosa.statusGlosa.code === '8';
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueRadioButton((event.target as HTMLInputElement).value);
        setErrors(null);
    };

    const changeRichText = (richText: string) => {
        const cleanText = removeHTMLTagsFromText(richText);
        setTotalCaracteres(cleanText.length);
        setRichTextValue(cleanText.length < 1 ? '<p><br></p>' : richText);
    }

    const confirmConsolidateGlossa = () => {
        setIsLoading(true);
        const data = {
            answerConsolited: richTextValue,
            glossSupport: valueRadioButton
        };
        putData(`${GLOSSES}/${dataSingleGlosa.id}/consolidate/electronic-documents/${idDocument}`, data).then(()=>{
            dispatch.consolidateGlosas.getlistGlosasAsync(idDocument);
            callGetDetailedDocument(idDocument || '');
            onCloseModal();
            Swal.clickCancel();
        }).finally(()=> setIsLoading(false));
    }

    const handleSubmit = (e:any ) => {   
        e.preventDefault();
        const cleanText = removeHTMLTagsFromText(richTextValue!);
        const modalComponent = document.createElement("div");
  
        if(valueRadioButton === null)
          return setErrors("Es obligatorio seleccionar una opción")
      
        if(cleanText.length > 5000 || cleanText.length < 200){
          return Swal.fire({
              title: "El campo consolidar debe tener mínimo 200 caracteres y máximo 5000",
              icon: "error",
              timer: 5000,
          });
        }
  
        
        createRoot(modalComponent).render(
            <SwalAlertContent
                description={ `¿Está seguro que desea consolidar glosa ${dataSingleGlosa.nameGlosa} de la factura ${billName} ?`}
                highlightedText={''}
                useCheck={true}
                checkText='Confirmo que deseo consolidar Glosas'
                onCancel={() => { Swal.clickCancel(); }}
                onConfirm={()=>confirmConsolidateGlossa()}
                textBtn='Responder'
            />
        );
        Swal.fire({
            title: "¡Atención!",
            icon: "warning",
            html: modalComponent,
            showCancelButton: false,
            showConfirmButton: false,
        });
      };

      useEffect(() => {
        setValueRadioButton(dataSingleGlosa?.glossSupport?.toString() || null)
        setRichTextValue(dataSingleGlosa.answerConsolited || ' ')
  
        return () => {
            setValueRadioButton(null)
            setRichTextValue('')
        }
  
      }, [dataSingleGlosa])  

  return (
    <form className={styles.containerForm} onSubmit={e=> handleSubmit(e)}>
        <Grid container className={styles.contentForm}>
            <div className={styles.containerTitleCard}>
                <img src={Image.iconoResponder} alt="" />
                <Typography className={styles.titleCard}>Consolidar Glosa</Typography>
            </div>
            <Typography className={styles.contentInformation}>
                La respuesta a glosas se realizará conforme al Manual Único de Devoluciones, Glosas y Respuestas expedido por el Ministerio de Salud y Protección Social, el cual es de obligatoria adopción por los prestadores de servicios de salud según lo establecido en el artículo 57 de la Ley 1438 de 2011. <b> Recuerde que una vez termine la respuesta a la totalidad de las  glosas, éstas no podrán ser modificadas.</b>
            </Typography>

            <Grid
                container
                direction={"row"}
            >
                <Grid item xs={12} className={styles.contentItem}>
                    <FormControl className={styles.containerradioGroup}>
                        <FormLabel id="consolidate-group-label">
                            ¿El prestador {provider.name} logró sustentar la glosa {dataSingleGlosa.nameGlosa} de la factura {billName} ?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="consolidate-group-label"
                            name="consolidate-group-label"
                            className={styles.radioGroup}
                            value={valueRadioButton}
                            onChange={handleChange}
                            aria-disabled={true}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="SI" disabled={isReadOnly()} />
                            <FormControlLabel value="false" control={<Radio />} label="NO" disabled={isReadOnly()} />
                        </RadioGroup>
                    </FormControl>
                    {
                        errors && 
                            <Grid item xs={12}>
                                <span className={styles.textInputError}>{errors}</span>
                            </Grid>
                    }
                </Grid>
                <Grid item xs={12} className={styles.contentItem}>
                    <Box className={styles.textRichDetailsContainer}>
                        <Typography variant='h6' className={styles.detailsLabel}>
                            Consolidar Glosa - Obligatorio
                        </Typography>
                        <Box className={styles.textRichContainer}>
                            <RichTextEditor
                                onChange={changeRichText}
                                readOnly={isReadOnly()}
                                value={richTextValue}
                            />
                        </Box>
                        <span className={styles.details}>{totalCaracteres}/{5000}</span>
                    </Box>
                </Grid>
                {
                    !isReadOnly() && 
                        <Box className={styles.buttonsContainer}>
                            <Button
                                variant='contained'
                                className={styles.confirmButton}
                                data-testid='reply_glosses'
                                type='submit'
                            >
                                Consolidar
                            </Button>
                            <Button
                                variant='contained'
                                className={styles.cancelButton}
                                data-testid='cancel_reply_glosses'
                                onClick={onCloseModal}
                            >
                                Cancelar
                            </Button>
                        </Box>
                }
            </Grid>
        </Grid>
        {(isLoading) && <Spinner/>}
    </form>
  )
}
