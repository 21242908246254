import { downloadDocument } from "shared/utils";
import { REPORTS } from "..";
import { apiInstance } from "../config/apiInstance";
import { downloadsTitle } from "shared/utils/data/infoDownloadDocument";

export const getDownloadReports = async(payload:Ipayload)=> {
  const response = await apiInstance.get(`${REPORTS}/${payload.id}/export${payload.params ? '?searchTerm='+payload.params : ''}`, { 
    responseType: 'blob',
    timeout: 0,
    headers:{
      'X-Timezone-Id': 'America/Bogota', 
      'Accept-Language': 'es-CO',
    }});
  downloadDocument(response, downloadsTitle.successDocument, payload.text, 'success');
}

type Ipayload = {
    id: number;
    text: string;
    params?: string;
}