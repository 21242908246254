import { Layout } from 'shared/utils'
import Image from 'shared/assets/index';
import { IconButton } from '@mui/material';
import style from '../styles/detailedConsultation.module.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IContract, getAssociatedInvoices, getContractNumbers } from 'shared/api/services';
import { Spinner } from 'shared/components';
import { ContractPerformanceIndicators, GeneralContractInformation } from './conctact-details.tsx';
import { DataCustomizedComponent } from 'shared/components/DataCustomized.component';
import { IRowsDataCustomized, Idata } from 'shared/components/interfaces';

import { IDataContractPerfomanceIndicatos } from '../Models/IContractPerfomanceIndicatos';

export const SeeContractDetails = () => {

    const  navigate = useNavigate()
    const { idContract, idDocument } = useParams()
    const onNavigateBack = () => navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}`);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<IContract>({} as IContract)
    const [dataAssociatedInvoices, setDataAssociatedInvoices] = useState<Idata<IRowsDataCustomized>>({
        columns: [],
        rows: []
    })

    useEffect(() => {
        setLoading(true)
        
        getContractNumbers(idContract ?? '')?.then((response) => {
            setData(response)
            setLoading(false)
        }).catch((error) => {
            console.log("error:", error)
            setLoading(false)    
        });

        getAssociatedInvoices(idContract ?? '')?.then((response) => {
            const dataMapper: IRowsDataCustomized[] = response.map((invoice, index) => {
                return {
                    id: index,
                    invoiceNumber: invoice.documentNumber ? invoice.documentNumber : '-',
                    invoiceDate: invoice.issuedAt ? new Date(invoice.issuedAt).toLocaleDateString("en-US") : '-',
                    state: invoice.state ? invoice.state : '-',
                    fileNumber: invoice.filingNumber ? invoice.filingNumber : '-',
                    filingDate: invoice.filingNumberCreatedAt ? new Date(invoice.filingNumberCreatedAt).toLocaleDateString("en-US") : '-',
                    assignedAuditor: invoice.auditorName ? invoice.auditorName : '-',
                    auditDate: invoice.auditAt ? new Date(invoice.auditAt).toLocaleDateString("en-US") : '-',
                    invoiceTotal: `${invoice.totalValue ? invoice.totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    valueAssessed: `${invoice.endorsedValue ? invoice.endorsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    glossedValue: `${invoice.glossedValue ? invoice.glossedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    acceptedValue: `${invoice.valueAccept ? invoice.valueAccept.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    valueNotAccepted: `${invoice.valueNotAccept ? invoice.valueNotAccept.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    sustainedValue: `${invoice.valueSustained ? invoice.valueSustained.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    valuePaid: `${invoice.valuePaid ? invoice.valuePaid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    amountCreditNotes: `${invoice.creditTotalValue ? invoice.creditTotalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    debitNoteValue: `${invoice.debitTotalValue ? invoice.debitTotalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                }
            })
            setDataAssociatedInvoices({
                columns: [
                    {
                        field: 'invoiceNumber',
                        headerName: 'Número de Factura',
                        width: 200,
                        sortable: false,
                    },
                    {
                        field: 'invoiceDate',
                        headerName: 'Fecha Factura',
                        width: 200,
                        sortable: false,
                    },
                    {
                        field: 'state',
                        headerName: 'Estado',
                        width: 200,
                        sortable: false,
                    },
                    {
                        field: 'fileNumber',
                        headerName: 'Número de radicado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'filingDate',
                        headerName: 'Fecha de Radicado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'assignedAuditor',
                        headerName: 'Auditor Asignado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'auditDate',
                        headerName: 'Fecha de Auditoria',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'invoiceTotal',
                        headerName: 'Total factura',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'valueAssessed',
                        headerName: 'Valor Avalado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'glossedValue',
                        headerName: 'Valor Glosado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'acceptedValue',
                        headerName: 'Valor Aceptado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'valueNotAccepted',
                        headerName: 'Valor No Aceptado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'sustainedValue',
                        headerName: 'Valor Sustentado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'valuePaid',
                        headerName: 'Valor Pagado',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'amountCreditNotes',
                        headerName: 'Valor Notas Crédito',
                        width: 200,
                        sortable: false,
                       
                    },
                    {
                        field: 'debitNoteValue',
                        headerName: 'Valor Nota Debito',
                        width: 200,
                        sortable: false,
                    }
            
                ],
                rows: dataMapper
            })
        }).catch((error) => {
            console.log("error:", error)
        })
    },[])

    const dataPerformanceIndicators: IDataContractPerfomanceIndicatos = {
        title: 'Información del contrato',
        values: [
            {
                percentFacturasRadicadas: {
                    percent: parseFloat(data?.invoiceFiledPercentage ?? '0') ,
                    title: 'Porcentaje de Facturas Radicadas',
                    icon: 'iconoPercent',
                    md: 3
                },
                cantidadFacturasRadicadas: {
                    value: parseFloat(data?.numberInvoicesFiled  ?? '0') ,
                    title: 'Cantidad de Facturas Radicadas',
                    icon: 'iconNumber',
                    md: 4
                },
                valorTotalFacturasRadicadas: {
                    value: `${data.totalValueInvoicesFiled ? data.totalValueInvoicesFiled.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'): '0'}` ,
                    title: 'Valor Total de Facturas Radicadas',
                    icon: 'iconoMoney',
                    md: 4
                },
            },
            {
                percentEjecucionFisica: {
                    percent: parseFloat(data?.physicalExecutionPercentage  ?? '0') ,
                    title: 'Porcentaje de Ejecución Física',
                    icon: 'iconoPercent',
                    md: 3
                },
                cantidadFacturasAuditadas: {
                    value: parseFloat(data?.numberInvoicesAudited  ?? '0'),
                    title: 'Cantidad de Facturas Auditadas',
                    icon: 'iconNumber',
                    md: 4
                },
                valorTotalAuditado: {
                    value:  `${data?.totalValueInvoicesAudited ? data?.totalValueInvoicesAudited.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}`,
                    title: 'Valor Total Auditado',
                    icon: 'iconoMoney',
                    md: 4
                },
            },
            {
                percentEjecucionFinanciera: {
                    percent: parseFloat(data?.financialExecutionPercentage  ?? '0'),
                    title: 'Porcentaje de ejecución financiera',
                    icon: 'iconoPercent',
                    md: 3
                },
                cantidadFacturasConciliacion: {
                    value: parseInt(data?.numberInvoicesConciliated  ?? '0'),
                    title: 'Cantidad de facturas en Conciliación',
                    icon: 'iconNumber',
                    md: 4
                },
                valorTotalGlosasConciliacion: {
                    value: `${data?.totalValueConciliatedGlosses ? data?.totalValueConciliatedGlosses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}` ,
                    title: 'Valor Total de Glosas en Conciliación',
                    icon: 'iconoMoney',
                    md: 4
                },
            },
            {
                percentGlosado: {
                    percent: parseFloat(data?.glossedPercentage  ?? '0'),
                    title: 'Porcentaje Glosado',
                    icon: 'iconoPercent',
                    md: 3
                },
                cantidadFacturasPagadas: {
                    value: parseInt(data?.numberInvoicesPaid  ?? '0'),
                    title: 'Cantidad de Facturas Pagadas',
                    icon: 'iconNumber',
                    md: 4
                },
                valorTotalFacturasPagadas: {
                    value: `${data?.totalValueInvoicesPaid  ? data?.totalValueInvoicesPaid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'}`,
                    title: 'Valor Total de Facturas Pagadas',
                    icon: 'iconoMoney',
                    md: 4
                },
            },
        ]
    }

    return (
        <Layout>
            <div className={style.containerSeeContact} data-testid="see-contact">
                <section className={style.conteinerHeader}>
                    <IconButton
                        className={`${style['viewButton']} ${style['noHover']}`}
                        onClick={onNavigateBack}
                        data-testid="button-back"
                    >
                        <img src={Image.iconoCerrar} className={`${style['sizeImg']}`} alt="cerrar" />
                    </IconButton>
                </section>

                <section className={style.containerInfo}>
                    <GeneralContractInformation data={data}/>

                    <ContractPerformanceIndicators data={dataPerformanceIndicators} />
   
                {
                    dataAssociatedInvoices?.rows?.length > 0 &&
                        <DataCustomizedComponent
                            titleCard="Facturas Asociadas al Contrato"
                            data={dataAssociatedInvoices}
                            nameRowsDownloadedFileXlX={
                                [
                                    { invoiceNumber: 'Numero_de_factura'},
                                    { invoiceDate: 'Fecha_factura'},
                                    { state: 'Estado' },
                                    { fileNumber: 'Numero_de_radicado'},
                                    { filingDate: 'Fecha_de_radicado'},
                                    { assignedAuditor: 'Auditor_asignado'},
                                    { auditDate: 'Fecha_de_auditoria'},
                                    { invoiceTotal: 'Total_factura'},
                                    { valueAssessed: 'Valor_avalado'},
                                    { glossedValue: 'Valor_glosado'},
                                    { acceptedValue: 'Valor_aceptado'},
                                    { valueNotAccepted: 'Valor_no_aceptado'},
                                    { sustainedValue: 'Valor_sostenido'},
                                    { valuePaid: 'Valor_pagado'},
                                    { amountCreditNotes: 'Valor_notas_credito'},
                                    { debitNoteValue: 'Valor_nota_debito' }
                                ]
                                
                            }
                            nameFileXLX="Reporte_facturas"
                        /> 
                }
                </section>
            </div>
            {loading && <Spinner/>}
        </Layout>
    )
}