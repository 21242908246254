import React, { useState, useEffect, Fragment } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useFormik, FormikErrors } from 'formik'
import { DatePicker } from '@mui/x-date-pickers'
import CheckIcon from '@mui/icons-material/Check';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/es'
import Swal from 'sweetalert2'
import { IDropdown, IFormValues, IParamsUrl, accesRoles } from 'shared/models'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'shared/store/store'
import { IPropsFormBills } from '../model/IPropsFormBills'
import { ModalStates } from './ModalStates'
import { ComboBoxCustom } from 'shared/components/ComboBoxCustom';
import { GET_ELECTRONIC_DOCS } from 'shared/api';
import { validateDateRangeByMonth } from '../../../shared/utils/helpers/validateDateRange';
import { RouterGuard } from 'shared/components';
import { useAuth } from 'shared/auth';
import styles from '../styles/readDocuments.module.scss'
import '../styles/_readDocumentsMUI.scss';

export const FormReadDocument = ({ dataProvider, dataFields }:IPropsFormBills) => {

    const dispatch = useDispatch<Dispatch>();
    const { invoiceStates } = useSelector((state: RootState) => state);
    const [valueDateFrom, setValueDateFrom] = useState<Dayjs | null>(null);
    const [valueDateTo, setValueDateTo] = useState<Dayjs | null>(null);
    const [userId, setUserId] = useState("");
    const [openModalStates, setOpenModalStates] = useState(false);
    const {authService} = useAuth();
    const userRoles = authService.getUserRoles();
    const userRolIsHealthProvider = userRoles.some(role => accesRoles.healthProvider.includes(role));

    useEffect(() => {
        dayjs.extend(utc)
        //los últimos 4 meses  en este rango, según la fecha actual
        const currentDate = dayjs();
        const fromDate = currentDate.subtract(4, 'month');
        const toDate = currentDate.endOf('month');

        setValueDateFrom(fromDate);
        setValueDateTo(currentDate);
    }, [])

    useEffect(() => {
		dispatch.menuDrawer.changeSelectedIndex(1);
	}, [])

    const onCloseModal = () => {
        setOpenModalStates(false)
    }

    const validate = () => {
        const errors: FormikErrors<IFormValues> = {};
        const showError = (error: string) =>{
            errors.idEntidad = "Requerido"
            Swal.fire({
                title: "Datos incompletos",
                text: error,
                icon: "error",
                timer: 8500,
              });
        }

        if((valueDateFrom === null || valueDateTo === null) && (Object.values(values).every(x=> x === '') && userId === '' && invoiceStates.every((value)=> value.activities.every(x=> !x.selected)))){
            showError("Debe especificar las fechas de la consulta")
        }else if ((valueDateFrom?.isAfter(valueDateTo) || !valueDateFrom?.isValid() || !valueDateTo?.isValid()) && (Object.values(values).every(x=> x === '') && userId === '' && invoiceStates.every((value)=> value.activities.every(x=> !x.selected)))) {
            showError("Las fechas tienen un formato incorrecto")
        }
        
        //Si el rango de fecha es mayor a 4 meses, no se ha aplicado ningun otro filtro, el usedId es igual a vacio y el invoiceStates todos sus selected son falsos, muestre el error.
        if (Object.values(values).every(x=> x === '') && userId === '' && invoiceStates.every((value)=> value.activities.every(x=> !x.selected)) && validateDateRangeByMonth(valueDateFrom, valueDateTo, 4) ) {
            errors.range = 'Por favor introduzca un rango de  fechas que no supere  4 meses'
        }
        return errors;
    }

    const buildUrl = (valuesUrl:IFormValues) => {
      const activitiesFilter = invoiceStates.flatMap((value)=> value.activities.filter(x=> x.selected)).map((activity)=> ({
        states: activity.titulo
      }))
      const queryString = activitiesFilter.map(item => `states=${item.states}`).join('&');
      const issuedAtStart = valueDateFrom ? dayjs.utc(valueDateFrom).format() : '';
      const issuedAtEnd = valueDateTo ? dayjs.utc(valueDateTo).format() : '';

      const params: IParamsUrl = {
        documentNumber: valuesUrl.documentNumber,
        typeDocument: valuesUrl.typeDocument,
        issuedAtStart,
        issuedAtEnd,
        userId,
        stateId: valuesUrl.state,
        providerCode: valuesUrl.idEntidad,
        employerIdNumber: valuesUrl.nitEntidad,
        contractNumber: valuesUrl.contractNumber,        
        states: ""
      }
      return {params,url:`${GET_ELECTRONIC_DOCS}/page?${queryString}`}
    }

    const { handleChange, handleSubmit, values, errors} = useFormik({
        initialValues: {
            settled: '',
            documentNumber: '' ,
            typeDocument: '',
            idEntidad: dataProvider?.code ? dataProvider?.code : '',
            nitEntidad: dataProvider?.employerIdNumber ? dataProvider?.employerIdNumber :  '',
            state: '',
            assigned: '',
            tasks: '',
            contractNumber:'',
            range:''
        }as IFormValues,
        onSubmit: (vls) => {
            const result = buildUrl(vls)
            dispatch.electronicDocuments.getElectronicDocuments(result.params, result.url);
        },
        validate,
        validateOnChange: false,
        enableReinitialize: true,
    })

    const getValue = (name:string)=> {
        const valueField: any = Object.entries(values).find(([key, value])=> key === name);
        return valueField[1]
    } 

    const renderInput =(params:any) => {
      return(
        <Box sx={{display:'flex', alignItems: 'center'}} className={styles.dataPicker}>
            <TextField 
                {...params} 
                onKeyDown={(e:any) => { e.which === 13 && e.preventDefault() }}
                data-testid='datapicker_date_from'
            />
        </Box>
      )
    }

    const validateActivitiesSelected = () =>{ 
        return invoiceStates.some((value)=> value.activities.some(x=> x.selected))
    }

    const validateFieldDisabled = (name: string, isEnabled: boolean) => {
        if(name === 'idEntidad') return !!dataProvider?.code
        if(name === 'nitEntidad') return !!dataProvider?.employerIdNumber

        return !isEnabled
    }

    const handleUserAssignment = (value:any) => {
        setUserId(value.id);
    }


    return (
        <>
            <form className={`${styles.formReadDocuments} form-documents`} noValidate onSubmit={handleSubmit} data-testid='form-read-documents'>
                <Typography className={'titleMain'}>Consulta de Factura</Typography>
                <Grid container direction={"row"} className={styles.containerReadDocuments}>
                    {
                        dataFields.map((field,i)=> (
                            <React.Fragment key={i}>
                                {
                                    field.type === 'Autocomplete' && userRolIsHealthProvider ? (
                                        <RouterGuard roles={accesRoles.usersSSSA} type='component'>
                                            <></>
                                        </RouterGuard>
                                    ) : (
                                        <Grid item xs={12} md={6} lg={3} className={ styles.containerGridItem } key={i}>
                                            {
                                                field.type === 'text' &&
                                                    <>
                                                        <label htmlFor={field.id}>
                                                            {field.label}
                                                        </label>
                                                        <input 
                                                            type='text'
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            id={field.id}
                                                            data-testid={field.testId}
                                                            value={ getValue(field.name) }
                                                            onChange={handleChange}
                                                            disabled={validateFieldDisabled(field.name, field.isEnable)}
                                                        />
                                                    </>
                                            }
                                            {
                                                field.type === 'Select' &&
                                                    <>
                                                        <label htmlFor={field.id}>
                                                            {field.label}
                                                        </label>
                                                        <select 
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            id={field.id}
                                                            data-testid={field.testId}
                                                            value={getValue(field.name)}
                                                            onChange={handleChange}
                                                            disabled={!field.isEnable}
                                                        >
                                                            <option value="" disabled hidden> {field.placeholder}</option>
                                                            {
                                                                field?.options?.map((opcion: IDropdown) => (
                                                                    <option key={opcion.id} value={opcion.id}>
                                                                        {opcion.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </>
                                            } 
                                            {
                                                field.type === 'datePicker' &&
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                                                    <DatePicker
                                                        label={field.label}
                                                        toolbarPlaceholder={field.label}
                                                        className={styles.contentDatePicker}
                                                        value={field.name === 'dateTo' ? valueDateTo : valueDateFrom}
                                                        onChange={(newValue) => {
                                                            field.name === 'dateTo' ?
                                                            setValueDateTo(newValue) : setValueDateFrom(newValue);
                                                        }}
                                                        data-testid={field.testId}
                                                        renderInput={(params) => renderInput(params)}
                                                    />
                                                </LocalizationProvider>
                                            }
                                            {
                                                field.type === 'Autocomplete' &&
                                                <Fragment>
                                                    <span className={styles.spanForLabel}>
                                                        {field.label}
                                                    </span>
                                                    <ComboBoxCustom handleChange={handleUserAssignment} data={field.options!} placeholder='Seleccione un usuario' focusSelected={true}/>
                                                </Fragment>
                                            } 
                                        </Grid>
                                    )
                                }
                            </React.Fragment>
                        ))
                    }
                    <Grid item xs={12} md={6} lg={3} className={ styles.containerGridItem }>
                        <label htmlFor='selectStates'>
                            Estados
                        </label>
                        <input
                            className={styles.selectStates}
                            onClick={()=>setOpenModalStates(true)}
                            value={validateActivitiesSelected() ? 'Actividades seleccionadas' : 'Seleccione Actividades' }
                            readOnly={true}
                            id='selectStates'
                            data-testid='select_states'
                        />
                        {
                            validateActivitiesSelected() 
                                ? <CheckIcon className={styles.checkIcon} data-testid="check_icon"/>
                                : <KeyboardArrowDownIcon className={styles.iconArrow} onClick={()=>setOpenModalStates(true)} data-testid='keyboard_arrow_down_icon'/>
                        }
                        

                    </Grid>
                    <Grid container className={styles.buttonContainer}>
                        {
                            errors.range && <div><Typography className={styles.errorMessage} data-testid='text_error_range'>{errors.range}</Typography></div> 
                        }
                        <div className={styles.buttons}>
                            <Button type='submit' variant='contained' color='secondary' className={styles.buttonSubmit} data-testid="btn-submit">
                                Consultar
                            </Button>
                        </div>
                    </Grid>
                </Grid>

            </form>
            <ModalStates
                onCloseModal={onCloseModal}
                openModal={openModalStates}
            />
        </>
    )
}
