import { IDropdown, IOptionsDropdowns } from "shared/models/store/IOptionsDropdowns"


export const convertOptionsDropdowns = (values: IDropdown[]) => {
    const filterOptions = (array:any)=> {
        let conjunto = new Set(array.map((obj:any) => JSON.stringify(obj)));
    
        let objetosFiltrados = Array.from(conjunto).map((str:any) => JSON.parse(str));
    
        return objetosFiltrados;
    }
  
    const options = filterOptions(values);

    const option =  options?.map((option )=>{
        return{
            id: option.id,
            role: option.rolName || '',
            code: option.code || '',
            name: option.name || '',
            email: option.email || ''
        }
    })
    return option
}