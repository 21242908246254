export const contentGridGlosas: typeContentTableRowDetailed[] = [
    { field: 'id', headerName: 'Id', minWidth: 60},
    { field: 'codeGlosa', headerName: 'Código' , minWidth: 120},
    { field: 'nameGlosa', headerName: 'Nombre de la Glosa' , minWidth: 200},
    { field: 'statusGlosa', headerName: 'Estado' , minWidth: 160},
    { field: 'valueGlosa', headerName: 'Valor de la Glosa' , minWidth: 160},
    { field: 'justification', headerName: 'Justificación de la glosa', minWidth: 360},
    { field: 'idUsuario', headerName: 'Acciones' , minWidth: 105},
]

type typeContentTableRowDetailed = {
    field: string,
    headerName: string,
    minWidth: number,
}