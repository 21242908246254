import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { POST_PAYMENT_AUTHORIZATION_DETAILS } from 'shared/api';
import { getData } from 'shared/api/services';
import { useParams } from 'react-router';
import styles from './styles/ModalDocumentPdf.module.scss';
import { Spinner } from './Spinner';

interface Props {
    idPayment: string;
    openIframePdf: boolean;
    handleOpenIframePdf: ()=> void;
}

export const ModalDocumentsPdf = ({idPayment, openIframePdf, handleOpenIframePdf}:Props) => {
    const { idDocument } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [documentPdf, setDocumentPdf] = useState();
    const [errorPdf, setErrorPdf] = useState(false);

    const callServicePdf = ()=>{
        getData( `${POST_PAYMENT_AUTHORIZATION_DETAILS}/${idDocument}/download/${idPayment}`).then((response: any) => {
          response && setDocumentPdf(response.pdfBase64);
          setIsLoading(false);
        }).catch(()=> {
            setIsLoading(false);
            setErrorPdf(true);
        });
      }

      useEffect(() => {
        callServicePdf();
      }, []);      

    return (
        <>
            <Modal data-testid='iframe-documentos' open={openIframePdf} onClose={handleOpenIframePdf}>
                <div id='iframeDocumentos' className={styles.documentModal}>
                    <Button onClick={handleOpenIframePdf} className={styles.btnCloseModal}>
                        <CloseFullscreenIcon/>
                    </Button>
                    <Box className={styles.contentDocument}>
                        {
                            documentPdf ?
                                <iframe
                                    src={'data:application/pdf;base64,' + documentPdf} height="100%" width="100%" style={{ overflow: 'auto' }}
                                ></iframe>
                            :
                                errorPdf ?
                                <Box onMouseOver={handleOpenIframePdf} height={'100%'} width={'100%'}></Box>
                                : <span>Completando descarga pdf ...</span>
                        }
                    </Box>
                </div>
            </Modal>
            {(isLoading) && <Spinner/>}
        </>
    )
}
