import { apiInstance } from "../config/apiInstance";


export const getCups = async (idContract: string): Promise<any> => {
    const response = await apiInstance.get(`v1/validation/cups/${idContract}`);
    return response.data;
}

export const getCums = async (idContract: string): Promise<any> => {
    const response = await apiInstance.get(`v1/validation/cums/${idContract}`);
    return response.data;
}