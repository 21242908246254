import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { RootState } from "shared/store/store";
import styles from './styles/PaymentDetailsForm.module.scss';
import { RichTextEditor } from "./RichTextEditor";
import { removeHTMLTagsFromText } from "shared/utils";
import { paymentsTransform } from "shared/utils/helpers/paymentsTransform";

interface Props {
    title: string;
    image: string;
    description: string;
    maxLength: number;
    richTextLabel: string;
    dataRichText?: string;
    onChangeRichText: (richText: string) => void;
    onBlur?: () => void;
}

interface IPayments {
    name: string;
    value: string;
}

export const PaymentDetailsForm = ({ title, image, description, maxLength, richTextLabel, dataRichText, onChangeRichText, onBlur }: Props) => {

    const { paymentInformation } = useSelector((state: RootState) => state.auditDetails);
    const [totalCaracteres, setTotalCaracteres] = useState(0);
    const [richTextValue, setRichTextValue] = useState('');
    const [payments, setPayments] = useState<IPayments[]>([]);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setRichTextValue(dataRichText ?? '<p><br></p>')
    }, [])
    

    useEffect(() => {
        const cleanText = removeHTMLTagsFromText(richTextValue);
        setTotalCaracteres(cleanText.length);
    }, [richTextValue])

    useEffect(() => {
        if (paymentInformation?.length === undefined || paymentInformation.length === 0) return;
        setPayments(paymentsTransform(paymentInformation!));
    }, [paymentInformation])

    
    
    const changeRichText = (richText: string) => {
        setRichTextValue(richText.length < 1 ? '<p><br></p>' : richText);
        onChangeRichText(richText);
    }

    return (
        <Fragment>
            <Box className='containerTitleCardTabs noPadding'>
                <img src={ image } alt={ title } />
                <Typography  data-testid="title-documents-related">
                    { title }
                </Typography>
            </Box>

            <Typography className={styles.descriptionText} data-testid="title-documents-related">
                { description }
            </Typography>

            <Box data-testid='payments-container' className={styles.paymentsContainer}>
                {
                    payments.map(({ name, value }, index) => 
                        <Box key={name} className={styles.payment}>
                            <span className={styles.paymentName}>{name}</span>
                            <span className={styles.paymentSeparator}></span>
                            <span className={`${styles.paymentValue} ${index === payments.length - 1 ? styles.totalPayments : ''}`}>{value}</span>    
                        </Box>
                    )
                }
            </Box>

            <Box className={styles.textRichDetailsContainer}>
                <Typography variant='h6' className={styles.detailsLabel}>
                    {richTextLabel}
                </Typography>
                <Box className={styles.textRichContainer}>
                        <RichTextEditor
                            onChange={changeRichText}
                            onBlur={onBlur}
                            readOnly={readOnly}
                            value={richTextValue}
                        />
                </Box>
                <span className={styles.details}>{totalCaracteres}/{maxLength}</span>
            </Box>
        </Fragment>
    )
}
