
import { Box, Button, Modal } from '@mui/material';
import { PaymentDetailsForm, Spinner } from 'shared/components';
import { useModalAutorizationPayments } from '../hooks/useModalAutorizationPayments';
import Image from 'shared/assets/index';
import styles from '../styles/modalAutorizationPayments.module.scss';

interface Props {
    billName: string;
    open: boolean;
    onOpen: (open: boolean) => void;
}

export const ModalAutorizationPayments = ({ billName, open, onOpen }: Props) => {

    const { 
        isLoading,
        hideModal,
        maxLength,
        onCloseModal,
        onChangeRichText,
        onAuthorize,
        disableConfirmationButton,
    } = useModalAutorizationPayments({ billName, onOpen });

    return (
        <>
            <Modal 
                open={open} 
                onClose={onCloseModal} 
                className={styles.modal} 
                sx={{ display: hideModal ? 'none' : '' }} 
                data-testid='modal-authorization-payments'
            >
                <Box className={styles.modalContainer}>
                    <PaymentDetailsForm
                        title='Autorización de pagos'
                        description={`A continuación, se detalla la información con la que será autorizado el pago de la factura ${billName}, tenga en cuenta que una vez autorizado este pago, usted no podrá realizar ningún ajuste en la Auditoría`}
                        image={Image.iconoGlosanoacuerdo}
                        maxLength={maxLength}
                        richTextLabel='Detalle de Autorización de Pagos:'
                        onChangeRichText={onChangeRichText}
                    />
                    <Box className={styles.buttonsContainer}>
                        <Button
                            variant='contained'
                            className={styles.confirmButton}
                            data-testid='authorize_payment_button'
                            disabled={disableConfirmationButton()}
                            onClick={onAuthorize}
                        >
                            Autorizar Pago
                        </Button>
                        <Button
                            variant='contained'
                            className={styles.cancelButton}
                            data-testid='cancel_authorize_payment_button'
                            onClick={onCloseModal}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Modal>
            
            {isLoading && <Spinner/>}
        </>
    )
}
