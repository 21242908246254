
import { IconButton, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import styles from './styles/ModalMessage.module.scss';
import CloseIcon from '@mui/icons-material/Close';

export const ModalMessageComponent = () => {

    const { messageModal } = useSelector((state: RootState) => state);
    const dispatch = useDispatch<Dispatch>();
    
    return (
        <Modal
            open={messageModal?.show || false}
            onClose={()=> { dispatch.messageModal.cleanStateMessage() }}
            className={styles.modalStates}
            data-testid='modal_message'
        >
            <Box className={`${styles.container} ${styles[messageModal?.size || '']}`} data-testid='container_box'>
                <section className={styles.header}>
                    <Typography
                        className={styles.titleModal}
                    >
                        {messageModal?.title}
                    </Typography>
                    <IconButton
                        className={styles.buttonClose}
                        onClick={messageModal?.onClose}
                        data-testid='boton_cerrar'
                    >
                        <CloseIcon />
                    </IconButton>
                </section>
                <section className={styles.description} data-testid='content_description'>
                {
                    typeof messageModal.description != "string" ? (
                        messageModal?.description
                    ) : (
                        <p className={styles.textDescription} data-testid='text_description'>{messageModal.description}</p>
                    )
                }
                </section>
                <section className={styles.footerButton}>
                    {
                        messageModal?.OkTitle ? 
                            <button 
                                className={styles.buttonOk}
                                onClick={
                                    messageModal.onOk 
                                        ? messageModal.onOk
                                        : () => dispatch.messageModal.cleanStateMessage()
                                }
                                data-testid='button_ok'
                            >
                                { messageModal?.OkTitle }
                            </button>
                        : <></>

                    }
                    {
                        messageModal?.cancelTitle ? 
                            <button 
                                className={styles.buttonCancel}
                                onClick={
                                    messageModal.onCancel
                                        ? messageModal.onCancel
                                        : () => dispatch.messageModal.cleanStateMessage()
                                }
                                data-testid='button_cancel'
                            >
                               { messageModal?.cancelTitle }
                            </button>
                        : <></>
                    }
                </section>
            </Box>
        </Modal>

    )
}
