import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import { GET_DATA_PAYMENT_LINE } from 'shared/api';
import { apiInstance } from 'shared/api/config/apiInstance';
import { CustomFileUploader, Spinner } from 'shared/components';
import { Field, Form, Formik } from 'formik';
import { fieldsFormInfo, fieldsFormPayment, payLineInfo } from './helper/fieldsForm';
import { usePrepareInvoice } from './hooks/usePrepareInvoice';
import { useSelector } from 'react-redux';
import { RootState } from 'shared/store/store';
import _ from 'lodash';
import Image from 'shared/assets/index';
import styles from './styles/prepareInvoice.module.scss';

interface IPrepareInvoice {
  isHadleSubmit: boolean,
  setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>,
  setIsNextPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const PrepareInvoice = ({ isHadleSubmit, setIsHadleSubmit, setIsNextPage }: IPrepareInvoice) => {
  
  const { 
    payLine,
    isPaidValue,
    document,
    typeAccept,
    isUnderage,
    prepareInvoiceData,
    handleChangeRadioUnderage,
    handleChangeSelect, 
    handleBlurSelectDepartment,
    handleSubmit, 
    handleChangeRadioPaidValue, 
    validationSchema,
    handleChangeFile,
    removeFile,
    onlyNumbers,
    formikRef
  } = usePrepareInvoice(isHadleSubmit, setIsHadleSubmit, setIsNextPage);

  const { optionsDropdowns } = useSelector((state: RootState) => state);
  const { loading } = useSelector((rootState: RootState) => rootState.loading.models.electronicDocuments);
  const loadingEdit  = useSelector((rootState: RootState) => rootState.loading.models.prepareInvoiceData);
  const [paymentList, setPaymentList] = useState<any[]>([]);
  const [dataFields, setDataFields] = useState<any[]>([]);

  useEffect(() => {
    async function fetchData() {
      const response = await apiInstance.get(GET_DATA_PAYMENT_LINE);
      setPaymentList(response.data);
    }
    fetchData();
  }, [])
  
  useEffect(() => {
    const fields = fieldsFormInfo(optionsDropdowns)
    setDataFields(fields)
  }, [optionsDropdowns])

  return (
    <Fragment>
      <div className={styles.container}>
        <Formik
            innerRef={formikRef}
            initialValues={{
              recoveryValue: '',
              paidValue: '',
              typeDocument: '',
              documentNumber: '',
              names: '',
              lastNames: '',
              tel1: '',
              tel2: '',
              email: '',
              address: '',
              department: '',
              city: '',
              namePatientSeen: '',
              LastNamePatientAttended: '',
              payLine: '',
              isLoadFile: null
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={false}
            validateOnMount={false} 
          >
            {        
              formik => (
                <Form className={styles.formPrepareInvoice} data-testid='form-invoice'
                  onChange={(value: any) => {
                    if (value.target.id === 'itemNamePatientSeen' && !isUnderage) {
                      formik.setFieldValue('names', value.target.value)
                    }
                    if (value.target.id === 'itemLastNamePatientAttended' && !isUnderage) {
                      formik.setFieldValue('lastNames', value.target.value)
                    }
                  }}
                >
                  <Grid container>
                    <Grid container direction={"row"}>
                      <Grid item xs={12} md={12} lg={4} className={styles.gridRadioButtons}>
                        <FormControl  className={` ${styles.itemForm} ${styles.noJustifyContentCenter}`}>
                          <label htmlFor="select-pay-line" className='text-required'>Línea de Pago:</label>
                          <select
                            id="select-payline"
                            value={payLine}
                            onChange={handleChangeSelect}
                            data-testid='select_pay_line'
                            className={ Object.keys(formik.errors).includes('payLine') ? `${styles.itemForm} ${styles.errorCampoSelect}` : ""}
                            name='payLine'
                          >
                            <option value="">--</option>
                            {paymentList.map((line) => (  
                              <option
                              key={line.id}
                              value={line.id}
                              data-testid={`menu_item_${line.id}`}
                              >
                                {line.name}
                              </option>
                            ))}
                          </select>
                            <Box display='flex' justifyContent='space-between'>
                              <span className={styles.spanError}>
                                {Object.keys(formik.errors).includes('payLine') && 'Campo obligatorio'}
                              </span>
                            </Box>
                        </FormControl>
                      </Grid>
                      {
                        payLineInfo.map((field, i) => (
                          <Grid item xs={12} md={6} lg={4} className={styles.gridRadioButtons} key={i}>
                            <FormControl className={styles.itemForm}>
                              <label htmlFor={field.id} className={`${field.required && 'text-required'}`}> {field.label} </label>
                              <Field
                                id={field.id} 
                                placeholder={field.placeholder}
                                type={field.type}
                                variant="outlined"
                                name={field.name}
                                maxLength={field.maxLength}
                                className={field.required && Object.keys(formik.errors).includes(field.name) ? formik.touched[field.name] && styles.errorCampo : ""} 
                                data-testid={`input_${field.name}`}
                              />
                              <Box display='flex' justifyContent='space-between'>
                                <span className={styles.spanError}>
                                  {Object.keys(formik.errors).includes(field.name) && formik.touched[field.name] && 'Campo obligatorio'}
                                </span>
                                <span className={styles.spanCounter}>
                                  { formik?.values?.[field.name]?.toString().length || 0}/{field.maxLength}
                                </span>
                              </Box>
                            </FormControl>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12} md={6} lg={4}  >
                        <FormControl fullWidth className={styles.itemForm}>
                          <FormLabel id="radio-buttons-values" className={`${styles.labelNumberAgreement} text-required`}>¿Se realizo gestión de cobro de cuota de recuperación?</FormLabel>
                          <RadioGroup
                            aria-labelledby="radio-buttons-values"
                            defaultValue="SI"
                            name="radio-buttons-values"
                            row
                            value={isPaidValue}
                            onChange={handleChangeRadioPaidValue}
                            data-testid='button_radio_group'
                            className={styles.radioGroup}                           
                          >
                            <FormControlLabel value={true} data-testid='is_paid_si' control={<Radio />} label="SI" />
                            <FormControlLabel value={false} data-testid='is_paid_no' control={<Radio />} label="NO" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {
                        fieldsFormPayment.map((field,i)=> (
                          <Grid item xs={12} md={6} lg={4}  key={i}>
                            <FormControl fullWidth className={styles.itemForm}>
                              <label htmlFor={field.id} className={`${field.required && 'text-required'}`} >{field.label} </label>
                              <Field
                                id={field.id} 
                                placeholder={field.placeholder}
                                type={field.type}
                                variant="outlined"
                                name={field.name}
                                disabled={(field.name === 'paidValue' && !isPaidValue)  }
                                maxLength={10}
                                className={ field.name === 'paidValue' && !isPaidValue ? '' : 
                                  (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') && formik.touched[field.name] ? styles.errorCampo : ""
                                }
                                onKeyPress={(event: any) => {
                                  isNaN(Number(event.key)) &&
                                    event.preventDefault();
                                }}
                                onPaste={(e: any) => e.preventDefault()}
                                onBlur={() => formik.setFieldTouched(field.name)}
                              />
                              <Box display='flex' justifyContent='space-between'>
                                <span className={styles.spanError}>
                                  {field.name === 'paidValue' && !isPaidValue ? '' : (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') && formik.touched[field.name] && 'Campo obligatorio'}
                                </span>
                                <span className={styles.spanCounter}>
                                  { formik?.values?.[field.name]?.toString().length || 0}/10
                                </span>
                              </Box>
                            </FormControl>
                          </Grid>
                        ))
                      }
                    </Grid>                      
                  
                    <Grid item xs={12}  marginTop='1rem' className={styles.itemDocument}>
                      <label className='text-required'>Formato diligenciado de no capacidad de pago </label>
                      { 
                        prepareInvoiceData?.file && 
                          <div className={styles.documentContainerText}>
                            <Typography>Actualmente tienes adjunto un formato en PDF.</Typography>
                          </div>
                      }
                      <CustomFileUploaderMemo 
                        fileLength={10}
                        originalName={document?.name || ''}
                        typeAccept={typeAccept}
                        document={document}
                        handleChangeFile={handleChangeFile}
                        removeFile={removeFile}
                      />
                      <Box display='flex' justifyContent='space-between'>
                        <span className={styles.spanError}>
                          { formik.errors?.isLoadFile && !formik.values.isLoadFile  && 'Archivo obligatorio' }     
                        </span>
                      </Box> 
                     
                    </Grid>
                  </Grid>

                  <Grid container className={styles.contentInfo}>
                    <Grid item xs={12} className={styles.contentTitleMain}>
                      <img src={Image.iconoInfoContact} alt="" />
                      <Typography className={styles.subtitleMain}>Información de contacto para gestión de cobro de Cuota de recuperación</Typography> 
                    </Grid>
                    {/* ***agregar en el formilk****** */}
                    <Grid item xs={12} md={6} lg={4}  >
                        <FormControl fullWidth className={styles.itemForm}>
                          <FormLabel id="radio-buttons-values-is-underage" className={`${styles.labelNumberAgreement} text-required`}>¿El usuario atendido es  menor de edad ?</FormLabel>
                          <RadioGroup
                            aria-labelledby="radio-buttons-values-is-underage"
                            defaultValue="SI"
                            name="radio-buttons-values-is-underage"
                            row
                            value={isUnderage}
                            onChange={handleChangeRadioUnderage}
                            data-testid='button_radio_group_is_underage'
                            className={styles.radioGroup}
                          >
                            <FormControlLabel value={true} data-testid='is_underage_si' control={<Radio />} label="SI" />
                            <FormControlLabel value={false} data-testid='is_underage_no'  control={<Radio />} label="NO" />
                          </RadioGroup>
                        </FormControl>
                    </Grid>
                    {
                      dataFields.map((field,i)=> (
                        field.type === 'select' ?
                        <Grid item xs={12} md={6} lg={4} key={i}>
                          <FormControl fullWidth className={styles.itemForm}>
                            <label htmlFor={field.id} className={`${field.required && 'text-required'}`}>{field.label} </label>
                            <Field
                                id={field.id} 
                                placeholder={field.placeholder}
                                variant="outlined"
                                name={field.name}
                                maxLength={field.maxLength}
                                className={formik.touched[field.name] && Object.keys(formik.errors).includes(field.name) ? styles.errorCampo : ""}
                                as={field.type}
                                onBlur={(e: any) => {
                                  formik.setFieldTouched(field.name);
                                  field.id === "itemformDeparment" ? handleBlurSelectDepartment(e): formik.validateForm()
                                }
                              }
                              
                            >
                              <option value=''>--</option>
                              {
                                  field.options?.map((option: any) => (
                                      <option key={option.id} value={option.id}>
                                          {option.name}
                                      </option>
                                  ))
                              }
                            </Field>
                            <Box display='flex' justifyContent='space-between'>
                              <span className={styles.spanError}>
                                { formik.touched[field.name] && Object.keys(formik.errors).includes(field.name) && 'Campo obligatorio'}
                              </span>
                            </Box>
                          </FormControl>
                        </Grid>
                        : field.type === 'textNumber' ?
                        <Grid item xs={12} md={6} lg={4} key={i}>
                          <FormControl fullWidth className={styles.itemForm}>
                            <label htmlFor={field.id} className={`${field.required && 'text-required'}`}>{field.label}</label>
                            <div 
                              className={`${styles.containerItemTel} ${field.required && (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') && formik.touched[field.name] && styles.errorCampo}`}>
                              <Field
                                id={field.id} 
                                placeholder={field.placeholder}
                                type={field.type}
                                variant="outlined"
                                name={field.name}
                                maxLength={field.maxLength}
                                className={""}
                                autoComplete='off'
                                onBlur={() => formik.setFieldTouched(field.name)}
                                onKeyPress={(event: any) => {
                                  isNaN(Number(event.key)) &&
                                      event.preventDefault();
                                  }}
                                onPaste={onlyNumbers}
                                
                              />
                              <span className={styles.itemIndicator}>+57</span>
                            </div>
                            <Box display='flex' justifyContent='space-between'>
                              <span className={styles.spanError}>
                                {field.required && (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') && formik.touched[field.name] && 'Campo obligatorio'}
                              </span>
                              <span className={styles.spanCounter}>
                                { formik?.values?.[field.name]?.toString().length || 0}/{field.maxLength}
                              </span>
                            </Box>
                          </FormControl>
                        </Grid>
                        : field.name === 'lastNames'  || field.name === 'names' ?
                          <Grid item xs={12} md={6} lg={4} key={i}>
                            <FormControl fullWidth className={styles.itemForm}>
                              <label htmlFor={field.id} className={`${field.required && 'text-required'}`} >{field.label} </label>
                              <Field
                                id={field.id} 
                                placeholder={field.placeholder}
                                type={field.type}
                                variant="outlined"
                                name={field.name}
                                maxLength={field.maxLength}
                                className={field.required && Object.keys(formik.errors).includes(field.name) ? formik.touched[field.name] && styles.errorCampo : ""}
                                onKeyPress={(event: any) => {
                                  const regex = /^[a-zA-Z]+$/;
                                  const inputChar = String.fromCharCode(event.charCode)

                                  !regex.test(inputChar) && event.preventDefault()
                                }}
                                data-testid={`input_${field.name}`}
                              />
                              <Box display='flex' justifyContent='space-between'>
                                <span className={styles.spanError}>
                                  {field.required && Object.keys(formik.errors).includes(field.name) && formik.touched[field.name] && 'Campo obligatorio'}
                                </span>
                                <span className={styles.spanCounter}>
                                  { formik?.values?.[field.name]?.toString().length || 0}/{field.maxLength}
                                </span>
                              </Box>
                          </FormControl>
                          </Grid>
                        : <Grid item xs={12} md={6} lg={4} key={i}>
                            <FormControl fullWidth className={styles.itemForm}>
                              <label htmlFor={field.id} className={`${field.required && 'text-required'}`}>{field.label} </label>
                              <Field
                                id={field.id} 
                                placeholder={field.placeholder}
                                type={field.type}
                                variant="outlined"
                                name={field.name}
                                maxLength={field.maxLength}
                                className={field.required && Object.keys(formik.errors).includes(field.name) ? formik.touched[field.name] && styles.errorCampo : ""}
                                
                              />
                              <Box display='flex' justifyContent='space-between'>
                                <span className={styles.spanError}>
                                  {field.required && Object.keys(formik.errors).includes(field.name) && formik.touched[field.name] && 'Campo obligatorio'}
                                </span>
                                <span className={styles.spanCounter}>
                                  { formik?.values?.[field.name]?.toString().length || 0}/{field.maxLength}
                                </span>
                              </Box>
                            </FormControl>
                          </Grid>
                      ))
                    }
                  </Grid>
                </Form>
            )}
        </Formik>
      </div>
      {/* {(loading || loadingEdit) && <Spinner/>} */}
    </Fragment>
  )
}

const CustomFileUploaderMemo = React.memo(CustomFileUploader);