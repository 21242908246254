import { ICreditNoteAdded, IRequestPostCreditNoteAdded, IResponseCreditNoteAdded } from "pages/detailed-consultation/Models/ICreditNoteAdded";
import { CREDIT_NOTES_ADDED } from "..";
import { apiInstance } from "../config/apiInstance";
import { CreditNoteType } from "pages/detailed-consultation/Models/ECreditNoteType";

export const postCreditNoteToAdd = async (creditNoteToAdd: IRequestPostCreditNoteAdded, documentId: string): Promise<ICreditNoteAdded> => {
    const { data } = await apiInstance.post(
        `${CREDIT_NOTES_ADDED}/${documentId}`,
        creditNoteToAdd
    );
    
    return {
        CUDE: data.uniqueCodeElectronicBilling,
        legalNumber: data.documentNumber,
        value: data.totalValue
    }
}

export const getCreditNotesAdded = async (documentId: string, type: CreditNoteType): Promise<ICreditNoteAdded[]> => {
    const { data } = await apiInstance.get(
        `${CREDIT_NOTES_ADDED}/${documentId}`,
        { params: { type } }
    );

    const responseCreditNotesAdded: IResponseCreditNoteAdded[] = data;

    return responseCreditNotesAdded.map(({ creditNote }) => ({
        CUDE: creditNote.uniqueCodeElectronicBilling,
        legalNumber: creditNote.documentNumber,
        value: creditNote.totalValue,
        creditNote: creditNote.documentNumber,
    }))
}

export const putCreditNotesAdded = async (creditNoteToAdd: IRequestPostCreditNoteAdded, documentId: string): Promise<ICreditNoteAdded> => {
    const { data } = await apiInstance.put(
        `${CREDIT_NOTES_ADDED}/${documentId}`,
        creditNoteToAdd
    );
    
    return {
        CUDE: data.uniqueCodeElectronicBilling,
        legalNumber: data.documentNumber,
        value: data.totalValue
    }
}