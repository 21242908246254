import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { IInvoiceStates, IPropsChangeActivities, IResponseStates } from "shared/models/store/IInvoiceStates";
import { getDocumentStates } from "shared/api/services";
import { convertInvoiceStates } from "shared/mappers/convertInvoiceStates";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_DATA_STATES_DOCS } from "shared/api";

export const invoiceStates = createModel<RootModel>()({
  state: [{
    name: '',
    activities: []
  }] as IInvoiceStates[],
  reducers: {
    addStatesActivities( state, payload: IResponseStates[] ){
        const responseForGroup: IInvoiceStates = payload.reduce((value:any, element) => {
            const groupName = element.group.name;
            if (!value[groupName]) {
                value[groupName] = [];
            }
            value[groupName].push({
                id: element.id,
                code: element.code,
                titulo: element.name,
                selected: false
            });
            return value
        }, []);
          
        return Object.entries(responseForGroup).map(([name, activities]) => ({
            name,
            activities
        }))
    },
    changesStatesSelected(state, payload:IPropsChangeActivities){
      const currentState = payload.state.map(st=> {
        return {
          name: st.name,
          activities: st.activities.map(act => 
            act.id === payload.id ? {...act, selected: !act.selected} : act
          )
        }
      })
      return currentState
    },
    changesAllStatesByActivitySelected(state, payload:IPropsChangeActivities, activity: string){
      const currentState = payload.state.map(st => {
        if (st.name === activity) {
          const allIsSelected = st.activities.every(({ selected }) => selected)

          return {
            name: st.name,
            activities: st.activities.map(activity => ({ ...activity, selected: !allIsSelected }))
          }
        }
        return { ...st }
      })
      return currentState
    },
    changesAllActivitiesSelected(state){
      const allIsSelected = state.every(({ activities }) => activities.every(({ selected }) => selected));
      const currentState = state.map(st => ({ ...st, activities: st.activities.map(activity => ({ ...activity, selected: !allIsSelected })) }));
      return currentState;
    },
    resetStatesSelected(state){
      const currentState = state.map(st=> {
        return {
          name: st.name,
          activities: st.activities.map(act => {return {...act, selected: false}})
        }
      })
      return currentState
    },
  },
  effects: (dispatch) => ({
    async getinvoiceStates(): Promise<void> {
      const response = await apiInstance.get(`${GET_DATA_STATES_DOCS}/grouped`);
      dispatch.invoiceStates.addStatesActivities(convertInvoiceStates(response.data))
    },

  }),
});
