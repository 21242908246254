import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { ICommentsState, IComment, ICommentEdited, ICommentList } from "shared/models/store/IComments";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_COMMENTS, WEBSITES } from "shared/api";
import { IIFramesState, IIframe } from "shared/models/store/IIframes";
import { convertWebSites } from "shared/mappers/convertWebSites";

export const iframes = createModel<RootModel>()({
    state: {
        openDrawer: false,
        iframes: [],
      } as IIFramesState,
      reducers: {
        handleOpenDrawer( state, payload ){
            return {
                ...state,
                openDrawer: payload
            }
        },
        addIframe(state, payload: IIframe[]) {
          return {
            ...state,
            iframes: payload
          }
        },
  },
  effects: (dispatch) => ({
    async getIframeListAsync() {
      const response = await apiInstance.get(`${WEBSITES}`);
      dispatch.iframes.addIframe(convertWebSites(response.data))

    },
    async postIframeAsync(payload, state) {
      const response = await apiInstance.post(`${WEBSITES}/`, {
        name: payload.title,
        link: payload.url,
      });

      const iframe = {
        id: response.data.id,
        title: response.data.name,
        url: response.data.link
      }
      dispatch.iframes.addIframe(
        [iframe, ...state.iframes.iframes]
      )
    },
  })
});
