import { IDataPrepareInvoice } from "shared/models/store/IDataPrepareInvoice"


export const convertDataPrepareInvoice = (option: IDataPrepareInvoice) => {
    return {
            paymentLineId: option.paymentLineId,
            recoveryFeeValue: option.recoveryFeeValue,
            valuePaidByTheUser: option.valuePaidByTheUser,
            documentType: option.documentType,
            documentNumber: option.documentNumber,
            name: option.name,
            lastname: option.lastname,
            phoneOne: option.phoneOne.slice(-10).replace(/^\+57/, ''),
            phoneTwo: option.phoneTwo.slice(-10).replace(/^\+57/, ''),
            email: option.email,
            province: option.province.id,
            city: option.city.id,
            address: option.address,
            namePatientSeen: option.patientName,
            LastNamePatientAttended: option.patientLastName,
            isRecoveryFee: option.isRecoveryFee,
            isUnderage: option.isYounger,
            id: option.id,
            file: {
                id: option.attachment.id,
                originalName: option.attachment.originalName,
                path: option.attachment.path
            },
    }
}