import { Layout } from 'shared/utils'
import { FormReadDocument } from './components/FormReadDocument'
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'shared/components/Spinner';
import { useEffect, useState } from 'react';
import { apiInstance } from 'shared/api/config/apiInstance';
import { GET_DATA_PROVIDER } from 'shared/api';
import { fieldsForm } from './helper/fieldsForm';
import { TableComponent } from 'shared/components/Table.component';
import { columnsTable } from './helper/columnsTable';
import { ComboBoxCustom, ModalFiles, RouterGuard } from 'shared/components';
import { useTableResults } from '../../shared/hooks/useTableResults';
import { MenuFunctions } from './components/MenuFunctions';
import { accesRoles } from 'shared/models';
import { IconButton } from '@mui/material';
import Images from 'shared/assets/index';

export const ReadDocuments = () => {
  const dispatch = useDispatch<Dispatch>();
  const { electronicDocuments, optionsDropdowns, consolidateGlosas  } = useSelector((state: RootState) => state);
  const { loading } = useSelector((rootState: RootState) => rootState.loading.models.electronicDocuments);
  const { sumValuesAllValueNotAccept } = consolidateGlosas;
  const [ isBillValueNotAccept, setIsBillValueNotAccept ] = useState(false);
  const [dataProvider, setDataProvider] = useState(null);
  const [dataFields, setDataFields] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [invoicesWithRecivedStatus, setInvoicesWithRecivedStatus] = useState<boolean>(false);
  const {
    modalFiles,
    openModal,
    modalAbortDoc,
    paramsUrl,
    rowSelected,
    dataMassiveAssigment,
    activateDropDown,
    actionModalAbortDE,
    actionModalResetAudit,
    handleSelectAllClick,
    handleChangeCheck,
    handleUserAssignment,
    linkToDian,
    handleMassiveAssignment,
    validateUserAuditor,
    validateEqualStates,
    validateInvoicesRecivedStatus,
  } = useTableResults();

  const valuesResultsPages = [
    50,100,150,200
  ];

  const optionsTrazact = () => {
    return (
      <>
        <RouterGuard roles={accesRoles.usersSSSA} type="component">
          <ComboBoxCustom
            handleChange={handleMassiveAssignment}
            idsDoc={rowSelected}
            data={dataMassiveAssigment}
            placeholder='Asignar'
            isDisabled={activateDropDown || validateUserAuditor()}
            useButtonIcon
            />
        </RouterGuard>
      </>
    )
  }

  useEffect(() => {
    validateEqualStates();
    setInvoicesWithRecivedStatus(validateInvoicesRecivedStatus());
  }, [rowSelected])

  useEffect(() => {
    async function fetchData() {
      const response = await apiInstance.get(GET_DATA_PROVIDER);
      setDataProvider(response.data)
    }
    fetchData();
  }, [])  
  
  useEffect(() => {
    const fields = fieldsForm(optionsDropdowns)
    setDataFields(fields)
  }, [optionsDropdowns])

  useEffect(() => {
    const columnsTransform:any[] = [
      ...columnsTable(handleUserAssignment,validateUserAuditor),
      {
        field: 'actions',
        headerName: '',
        minWidth: 120,
        renderCell(params:any) {
            return (
                <MenuFunctions
                    paramsUrl={paramsUrl}
                    doc={params}
                    actionModalAbortDE={actionModalAbortDE}
                    linkToDian={linkToDian}
                    actionModalResetAudit={actionModalResetAudit}
                    isBillValueNotAccept={isBillValueNotAccept}
                />
            )
          },
      },
    ]
    setColumns(columnsTransform)
  }, [electronicDocuments, isBillValueNotAccept])

  useEffect(() => {
    const billNotAccept = sumValuesAllValueNotAccept <= 0;
    setIsBillValueNotAccept(!billNotAccept);
  }, [sumValuesAllValueNotAccept])

  useEffect(() => {
    dispatch.optionsDropdowns.getUsers();
    dispatch.optionsDropdowns.getTypesDocuments();
    dispatch.optionsDropdowns.getDepartments();
  }, [])
  
  
  return (
    <Layout>
      {
        electronicDocuments.content 
          ? <TableComponent 
              columns={columns}
              title='Trazabilidad de Facturación Electrónica'
              valuesResultsPages={valuesResultsPages}
              optionsTrazact={optionsTrazact}
              rowSelected={rowSelected}
              downloadExcel={() =>
                dispatch.electronicDocuments.getFileExcelToExport({
                  urlByParams: electronicDocuments.url?.slice(electronicDocuments.url.indexOf('?')+1),
                  paramsUrl,
                  text: "Archivo excel descargado"
                })}
              selectAllRows={handleSelectAllClick}
              selectRow={handleChangeCheck}
            />
          : <FormReadDocument dataProvider={dataProvider} dataFields={dataFields}/>
      }

      <ModalFiles
        title={modalFiles.title}
        subtitle={modalFiles.subtitle}
        open={openModal || modalAbortDoc ||  false}
        onClose={modalFiles.onClose}
        hidden={modalFiles.hidden}
        children={modalFiles.children}
      />

      {(loading) && <Spinner/>}
      
    </Layout>
  )
}
