import { IpaymentStructure, valuesPayment } from "shared/models/store/IAuditDetails";

interface IPayments {
    name: string;
    value: string;
}

export const paymentsTransform = (paymentInformation: IpaymentStructure[]) => {
    const totalDebit = valuesPayment.totalNotasDebito.toLowerCase();
    const totalCreditGlosses = [valuesPayment.totalNotasCredito.toLowerCase(), valuesPayment.totalGlossas.toLowerCase()];

    const payments: IPayments[] = [...paymentInformation].map(({ name, value }) => {
        const lowerName = name.toLowerCase();
        const formatedValue = `$${value.toLocaleString("es-CO")}`;

        if (lowerName === totalDebit) {
            return { name, value: `+${formatedValue}` }
        }

        if (totalCreditGlosses.includes(lowerName)) {
            return { name, value: `-${formatedValue}` }
        }

        return {
            name,
            value: formatedValue
        }

    })

    return payments;
}