import {Outlet, useLoaderData} from "react-router-dom";
import {AuthProvider, AuthService, useAuth} from "./oauh2-with-pkce";
import {JWTIDToken} from "./oauh2-with-pkce/AuthService";
import {getOAuthConfigByLoginType} from "./oauh2-with-pkce/config/oauth2ServerProps";


const RootContent = ()=> {
  const {authService} = useAuth();
  return (
      authService.isAuthenticated() ? <Outlet/> : (!!authService.authorize() && <></> )
  );
}

export const Root = ()=> {
  const userType = (useLoaderData() as 'I'|'E'|null);
  const oauth2Service: AuthService = new AuthService<JWTIDToken>(getOAuthConfigByLoginType(userType));
  return (
      <AuthProvider authService={oauth2Service}>
        <RootContent/>
      </AuthProvider>
  );
}
