import { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { FormNoAgreementBalance } from "./FormNoAgreementBalance";
import styles from '../styles/modalResultAcepptance.module.scss';
import Image from 'shared/assets/index';
import { FormAuditGlosses } from "pages/detailed-consultation/components/audit-glosses/FormAuditGlosses";

interface Props {
    open: boolean;
    selectedDocument: string;
    onOpen: (open: boolean) => void;
}

export const ModalResultAcepptance = ({ open, selectedDocument, onOpen }: Props) => {

    const [hideModal, setHideModal] = useState(false);

    const onCloseModal = () => { onOpen(false) };
    
    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            className={styles.modal}
            sx={{ display: hideModal ? 'none' : '' }} 
            data-testid='modal-result-acepptance'
        >
            <Box className={styles.modalContainer}>
                
                <FormAuditGlosses selectedDocument={selectedDocument} />

                <Box className={styles.titleContainer}>
                    <img src={Image.iconoResumen} alt="Resumen" />
                    <Typography className={styles.title}>Detalle gestión de Saldo en No Acuerdo</Typography>
                </Box>
                <Box className={styles.contentContainer}>
                    <Typography variant='body1' className={styles.description}>
                        La conciliación de glosas es un proceso que se realiza cuando no hay acuerdo entre las partes El proceso de conciliación tiene como finalidad el saneamiento de la cartera y contribuir a mejorar las relaciones con los prestadores.
                    </Typography>
                    <Typography variant='body1' className={styles.description}>
                        Ante la controversia suscitada por el pago de los servicios entre las IPS y las ERP, ésta puede ser resuelta por la Superintendencia Nacional de Salud, en el marco de su función jurisdiccional tal y como lo prevé el literal f) del articulo 41 de la Ley 1122 de 2007, modificado por el articulo 6 de la Ley 1949 de 2019. Así mismo, la IPS podrá acudir a la facultad de conciliación ante dicha superintendencia en virtud de lo previsto en el articulo 38 de la Ley 1122 de 2007.
                    </Typography>

                    <FormNoAgreementBalance selectedDocument={selectedDocument} hideModal={setHideModal} closeModal={onCloseModal} />
                </Box>
            </Box>
        </Modal>
    )
}
