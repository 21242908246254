import { Navigate } from 'react-router-dom';
import { AuthProvider, AuthService, getOAuthConfigByLoginType, useAuth } from 'shared/auth';
import { JWTIDToken } from './oauh2-with-pkce/AuthService';
import {loginType} from "../utils";


const LoginSuccessContent = ({ children }: any) => {
    const {authService} = useAuth();
    return ( authService.isAuthenticated() ? <Navigate to={'/'}/> : children)
}


export function ValidateLogin({ children }: any) {
    const userType = loginType();
    getOAuthConfigByLoginType(userType)
    const oauth2Service: AuthService = new AuthService<JWTIDToken>(getOAuthConfigByLoginType(userType));
    return (
        <AuthProvider authService={oauth2Service}>
          <LoginSuccessContent>{children}</LoginSuccessContent>
        </AuthProvider>
    );
}