import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import Imagen from '../assets/index'
import { useWidth } from 'shared/hooks/HookDetailedConsultation/useWidth';
import styles from './styles/Navbar.module.scss'
import { useNavigateToCustom } from 'shared/hooks/useNavigateToCustom';
import { ButtonNotifications } from './notifications/ButtonNotifications';
import { ModalNotifications } from './notifications/ModalNotifications';

export const Navbar = () => {
    const dispatch = useDispatch<Dispatch>();    
    const { width } = useWidth();
    const { anchorOrigin } = useSelector((state: RootState) => state.notifications);
    const {navigateHome, navigateToInvoices} = useNavigateToCustom();

    React.useEffect(() => {
      dispatch.notifications.getNotificationsAsync({
        page: 1,
        status:'N',
        initialLoad: true
      })
    }, [])

  return (
    <>
      {
        width > 900 ?
          <Box className={styles.containerTopBar}>
            <Box 
                className={styles.contentLogo}
                data-testid='logo-trazabilidad'
            >
              <img src={Imagen.logoTrazabilidad} alt='Trazabilidad' onClick={navigateHome}/>
            </Box>
            <Box className={styles.topBar}>
              
              <img className={styles.imageGobernacion} src={Imagen.logoGobernacion} alt="Logo Gobernación" />
              <Box display='flex' gap={3}>
                <Box className={styles.itemSearch} onClick={navigateToInvoices} data-testid='navigateInvoices'>
                  <SearchIcon/>
                  <span>Buscar</span>
                </Box>
                <ButtonNotifications/>
              </Box>
            </Box>
          </Box>
        : 
          <Box className={styles.containerTopBarMobile}>
            <Box className={styles.contentMobile}>
              <IconButton
                  aria-label="btn_toogle_menu"
                  size="large"
                  edge="start"
                  color="success"
                  sx={{ mr: 2}}
                  onClick={dispatch.menuDrawer.toogleMenu}
                  data-testid="menu-mobile"
                >
                  <MenuIcon />
                </IconButton>
                <Box 
                    className={styles.logoTrazabilidad}
                    onClick={navigateHome}
                >
                    <img src={Imagen.logoTrazabilidad} alt="trazabilidad" />
                </Box>
                <Box className={styles.itemSearchMobile} onClick={navigateToInvoices}>
                  <SearchIcon/>
                </Box>
            </Box>
          </Box>
      }
      {
        anchorOrigin && <ModalNotifications/>
      }
    </>
  )
}
