import React from 'react'
import Imagen from '../../assets/index'
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { typesNotifications } from './models/ENotifications';
import style from './styles/ButtonNotifications.module.scss'

export const ButtonNotifications = () => {

    const dispatch = useDispatch<Dispatch>();   
    const { totalElements } = useSelector((state: RootState) => state.notifications);

    const openModal = (event: React.MouseEvent<HTMLElement>) => {
        dispatch.notifications.addStatemodal(event.currentTarget)
    }
    

  return (
    <div className={style.logoNotifications}>
      <img 
        id='img_logo_notifications'
        data-testid='img_logo_notifications' 
        src={ Imagen.iconoNotificaciones } alt='Notificaciones'
        onClick={openModal}
      />
      {
        totalElements > 0 &&
        <button className={style.newsNotifications} data-testid='news_notifications'>
          {totalElements }
        </button>
      }
    </div>
  )
}
