import React, { useState } from 'react'
import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import WebIcon from '@mui/icons-material/Web';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { Dispatch } from 'shared/store/store';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './styles/iframes.module.scss'

export const FormAddIframes = () => {
    const { idDocument } = useParams();
    const dispatch = useDispatch<Dispatch>();
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [isUrlError, setIsUrlError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
        
        const AddIframe = () => {
            const sanitizedUrl = DOMPurify.sanitize(url);
            const payload = {
              title,
              url,
            } 
            
            if(!sanitizedUrl.substring(0,5).includes('https')){
                setIsUrlError(true);
                setErrorMessage('La url ingresada no es valida o accesible.')
                return;
            }

            dispatch.iframes.postIframeAsync(payload);
            setTitle('');
            setUrl('');
            setIsUrlError(false);
        }

    return (
        <Box 
            component="form"
            noValidate
            autoComplete="off"
            className={styles.formAddIframes}
            data-testid="form_iframes"
        >
            <Box className={styles.formTitle}>
                <WebIcon />
                <Typography>Embeber sitio</Typography>
            </Box>
            <FormControl fullWidth className={styles.itemForm}>
                <label htmlFor={"outlined-title"}>Titulo</label>
                <TextField
                    id="outlined-title"
                    inputProps={{
                        "data-testid": "input_title",
                    }}
                    value={title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setTitle(event.target.value);
                    }}
                />
            </FormControl>
            <FormControl fullWidth className={styles.itemForm}>
                <label htmlFor={"outlined-link"}>Url</label>
                <TextField
                    id="outlined-link"
                    inputProps={{
                        "data-testid": "input_link",
                    }}
                    value={url}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setUrl(event.target.value);
                    }}
                />
                {
                    isUrlError &&
                    <span className={styles.spanError}>
                        {errorMessage}
                    </span>
                }
            </FormControl>

            <Button 
                variant='contained'
                color='primary'
                disabled={ title.length < 1 || url.length < 1}
                className={styles.buttonAdd}
                onClick={AddIframe}
                data-testid='btn_add_frame'
            >
                Agregar
            </Button>

        </Box>
    )
}
