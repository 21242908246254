import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Pagination, PaginationItem, TablePaginationProps } from "@mui/material";
import { GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import './styles/_customPagination.scss'

function PaginationCustom({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
            className={"paginador"}
            data-testid='action-pagination'
            count={pageCount}
            page={page + 1}
            siblingCount={0}
            boundaryCount={1}
            renderItem={(item) => (
                <PaginationItem
                    slots={{
                        previous: () => <Box className={"botonPaginador"}><KeyboardArrowLeft /><p>Ant.</p></Box>,
                        next: () => <Box className={"botonPaginador"}><p>Sig.</p><KeyboardArrowRight /></Box>
                    }}
                    {...item}
                    />
            )}
            onChange={(event:any, newPage:any) => {
                onPageChange(event as any, newPage - 1);
              }}
        />
    );
  }
  
  export function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={PaginationCustom} {...props} />;
  }