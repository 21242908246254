import { createModel } from '@rematch/core';
import { RootModel } from '../rootModel';

export const menuDrawer = createModel<RootModel>()({
	state: {
		open: false,
		selectedIndex: 0
	} as Imenu,
	reducers: {
		toogleMenu(state) {
			return{
				...state,
				open: !state.open,
			}
		},
		activeMenu(state) {
			return{
				...state,
				open: true
			}
		},
		closeMenu(state) {
			return{
				...state,
				open: false
			}
		},
		changeSelectedIndex(state, index:number){
			return{
				...state,
				open: false,
				selectedIndex: index
			}
		}
	},
});

interface Imenu {
	open: boolean;
	selectedIndex: number;
}
