import { createModel } from "@rematch/core";
import { apiInstance } from "shared/api/config/apiInstance";
import { RootModel } from "../rootModel";
import { IReports, IUpdateReports } from '../../models/store/IReports';
import { REPORTS } from "shared/api";

export const reports = createModel<RootModel>()({
  state: {
    dataView: [],
    limitItems: 12,
    totalElements: null,
    search: ''
  } as IReports,
  reducers: {
    addReports(state,payload){
      return {
        ...state,
        dataView: payload.content,
        totalElements: payload.totalElements
      };
    },
    orderData(state,payload) {
      return {
        ...state,
        dataView: payload
      };
    },
    updateReports (state, payload:IUpdateReports) {
      return {
        ...state,
        limitItems: payload.limit,
        search: payload.search || ''
      };
    },
  },
  effects: (dispatch) => ({
    async getDataReportsAsync(params) {
        
      const response = await apiInstance.get(`${REPORTS}/page?${params}`)

      dispatch.reports.addReports(response.data);
    },

  }),
});