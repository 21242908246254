import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { getData } from "shared/api/services";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_DATA_NOTIFICATIONS, UPDATE_DATA_NOTIFICATIONS } from "shared/api";
import { INotifications, IPropsGetNotifications, IPropsUpdateNotifications } from "shared/models/store/INotifications";
import { convertNotifications } from "shared/mappers/convertNotifications";

export const notifications = createModel<RootModel>()({
  state: {
    anchorOrigin: null,
    notifications: [],
    page: 1,
    totalElements: 0
  } as INotifications,
  reducers: {
    addStatemodal(state,payload){
      return {
        ...state,
        anchorOrigin: payload
      };
    },
    addNumberNotRead(state,payload){
      return {
        ...state,
        totalElements: payload
      };
    },
    listNotifications(state,payload){
      return {
        ...state,
        notifications: payload.changeTab ? payload.notifications : state.notifications.concat(payload.notifications || []) ,
        page: payload.page
      };
    },
    cleanNotifications(state){
      return{
        ...state,
        anchorOrigin: null,
        notifications: [],
        page: 1,
        totalElements: 0
      }
    }
  },
  effects: (dispatch) => ({

    async getNotificationsAsync(paramsUrl:IPropsGetNotifications, rootState): Promise<void> {
        getData(`${GET_DATA_NOTIFICATIONS}/?page=${paramsUrl.page}&status=${paramsUrl.status}`).then((data)=>{
          dispatch.notifications.listNotifications(convertNotifications(data.content, data.number, data.totalElements, paramsUrl.resetLoad))
          paramsUrl.initialLoad && dispatch.notifications.addNumberNotRead(data.totalElements)
        })
    },
    async updateStatusNotificationAsync(payload: IPropsUpdateNotifications, rootState): Promise<void> {
        apiInstance.put(`${UPDATE_DATA_NOTIFICATIONS}${payload.url}`, payload.params ).then(()=>{
          getData(`${GET_DATA_NOTIFICATIONS}/?page=${payload.page}&status=${payload.status}`).then((data)=>{
            dispatch.notifications.listNotifications(convertNotifications(data.content, data.number, data.totalElements, payload.resetLoad))
            payload.initialLoad && getData(`${GET_DATA_NOTIFICATIONS}/?status=N`).then((data)=>{
              dispatch.notifications.addNumberNotRead(data.totalElements)
            })
          })
        })
    },
    async updateByNavigationNotificationAsync(payload: IPropsUpdateNotifications, rootState): Promise<void> {
        apiInstance.put(`${UPDATE_DATA_NOTIFICATIONS}${payload.url}`, payload.params ).then(()=>{
          dispatch.notifications.cleanNotifications()
          getData(`${GET_DATA_NOTIFICATIONS}/?page=${payload.page}&status=${payload.status}`).then((data)=>{
            payload.initialLoad && getData(`${GET_DATA_NOTIFICATIONS}/?status=N`).then((data)=>{
              dispatch.notifications.addNumberNotRead(data.totalElements)
            })
          })
        })
    },
  }),
});