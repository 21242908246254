import { Autocomplete, Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import './styles/_filterSearchOrder.scss'
import { debounceFunction400 } from 'shared/utils';

export const SearchItems: React.FC<{ data: any[], placeholder: string, autocomplete?: boolean }> = ({ data, placeholder, autocomplete }) => {
    const dispatch = useDispatch<Dispatch>();
    const { text } = useSelector((state: RootState) => state.searcher);
    const [currentItems, setCurrentItems] = useState<any[]>([]);

    const handleTooltipOpen = () => {
        dispatch.searcher.toogleSearcher(true);
    };

    useEffect(() => {
        setCurrentItems(data);
    }, [data]);

    useEffect(() => {
        return () => {
            dispatch.searcher.addText('');
            dispatch.searcher.toogleSearcher(false);
        }
    }, [])

    const searchInvoices = (searchBy: string) => {
        debounceFunction400(() => {
            dispatch.searcher.addText(searchBy.toLowerCase().replace('#', '%23'))
        })
    }

    return (
        <>
            <div className='padding-search' data-testid="search_filter" id="search_filter_p">
                {
                    currentItems && (
                        <div className='contenido-autocomplete'>
                            <SearchIcon className='icono-buscar' />
                            <Autocomplete
                                value={text}
                                id="autocomplete_search"
                                open={autocomplete}
                                options={currentItems}
                                freeSolo
                                sx={{ width: '100%' }}
                                data-testid="autocomplete_search"
                                getOptionLabel={(content) => content.item ? content.item : content.title || text}
                                onInputChange={(e, value) => { searchInvoices(value) }}
                                clearIcon={false}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} data-testid="btn_search"
                                        onAbort={() => dispatch.searcher.addText(option.title || option.column5)}
                                        id="btn_search"
                                        key={option.id}
                                    >
                                        {option.title || option.column5}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        className='inputText'
                                        {...params}
                                        type="text"
                                        id="buscar"
                                        label={!text && placeholder}
                                        data-testid="text_field_buscar"
                                        autoFocus={false}
                                        key={params.id}
                                        onChange={(e) => { searchInvoices(e.target.value) }}
                                        onClick={handleTooltipOpen}
                                        inputProps={{
                                            ...params.inputProps,
                                            maxLength: 100,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )
                }
            </div>
        </>
    )
}
