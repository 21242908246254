import { GET_CONTACT_NUMBER } from "..";
import { apiInstance } from "../config/apiInstance";

export interface IContract{
    id: number;
    contractNumber: string;
    contractStartAt: string;
    contractEndAt: string;
    totalValue: number;
    contractObject: string;
    provider: {
        id: number;
        code: string;
        employerIdNumber: string;
        name: string;
    };
    cdpNumber: string;
    auditorName: string;
    contractBalance: number;
    physicalExecutionPercentage: string,
    financialExecutionPercentage: string,
    numberInvoicesFiled: string,
    totalValueInvoicesFiled: string,
    invoiceFiledPercentage: string,
    numberInvoicesAudited: string
    totalValueInvoicesAudited: string,
    numberInvoicesConciliated: string,
    totalValueConciliatedGlosses: string
    glossedPercentage: string,
    numberInvoicesPaid: string,
    totalValueInvoicesPaid: string,
}

export interface IInvoices{
    documentNumber: string,
    issuedAt: string,
    state: string,
    filingNumber: string,
    filingNumberCreatedAt: string,
    auditorName: string,
    auditAt: string,
    totalValue: number,
    endorsedValue: number,
    glossedValue: number,
    valueAccept: number,
    valueNotAccept: number,
    valueSustained: number,
    valuePaid: number,
    creditTotalValue: number,
    debitTotalValue: number
}

export const getContractNumbers = async (idContract: string): Promise<IContract> => {
    const response = await apiInstance.get(`${GET_CONTACT_NUMBER}/${idContract}`);
    return response.data;
}

export const getAssociatedInvoices = async (idContract: string): Promise<IInvoices[]> => {
    const response = await apiInstance.get(`${GET_CONTACT_NUMBER}/${idContract}/invoice`);
    return response.data;
}