import React, {useState} from 'react';
import { contentDataAuditDetails, typeContentAudtDetailed } from '../helpers/contentTableRowDetailed';
import { patchSaveAuditDocument } from 'shared/api/services/patchsaveAuditDocument';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { getData, getDataRips } from 'shared/api/services';
import { GET_ELECTRONIC_DOCS, POST_PAYMENT_AUTHORIZATION_DETAILS } from 'shared/api';
import { IDataAuditDetail } from '../Models/IDataAuditDetail';
import { removeHTMLTagsFromText } from 'shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { valuesPayment } from 'shared/models/store/IAuditDetails';
import { deleteData } from 'shared/api/services/deleteData';
import { useDetailedConsultation } from './useDetailedConsultation';
import { Typography } from '@mui/material';
import style from '../styles/detailedConsultation.module.scss';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const useTabAuditDetail = () => {

    const dispatch = useDispatch<Dispatch>(); 
    const navigate = useNavigate()
    const { idDocument } = useParams();
    const { auditDetails } = useSelector((state: RootState) => state);
    const { readOnly, paymentInformation, bill, glossesGeneralDetailStatus } = auditDetails;
    const valueGlossas = paymentInformation?.find(pay=> pay.name === valuesPayment.totalGlossas)
    const { callGetDetailedDocument } = useDetailedConsultation();
    const [data, setData] = useState<IDataAuditDetail>({
        userInfoQuestion: null,
        userInfoText: "",
        validateRightQuestion: null,
        validateRightText: "",
        adminInfoQuestion: null,
        adminInfoText: "",
        clinicHistoryQuestion: null,
        clinicHistoryText: "",
        medicalAttentionQuestion: null,
        medicalAttentionText: "",
        processQuestion: null,
        processText: "",
        medicationsQuestion: null,
        medicationsText: "",
        otherServicesQuestion: null,
        otherServicesText: ""
    });
    const [contentDataAudit, setContentDataAudit] = useState(contentDataAuditDetails)
    const [btnAuthorizeInvoice, setBtnAuthorizeInvoice] = useState(true);
    const [btnGlossInvoices, setBtnGlossInvoices] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [openModalAutorizationPayments, setOpenModalAutorizationPayments] = useState(false);

    const onOpenModalAutorizationPayments = (open: boolean) => {
        setOpenModalAutorizationPayments(open);
    }

    const handleSetData = (values: any) =>{
        setData(values)
    }

    const convertServiceAudits = (dataAudits: any) => {
            setData({
                userInfoQuestion: [false, true].includes(dataAudits.userInfoQuestion) ? dataAudits.userInfoQuestion : null,
                userInfoText: dataAudits.userInfoText || "<p><br></p>",
                validateRightQuestion: [false, true].includes(dataAudits.validateRightQuestion) ? dataAudits.validateRightQuestion : null,
                validateRightText: dataAudits.validateRightText || "<p><br></p>",
                adminInfoQuestion: [false, true].includes(dataAudits.adminInfoQuestion) ? dataAudits.adminInfoQuestion : null,
                adminInfoText: dataAudits.adminInfoText || "<p><br></p>",
                clinicHistoryQuestion: [false, true].includes(dataAudits.clinicHistoryQuestion) ? dataAudits.clinicHistoryQuestion : null,
                clinicHistoryText: dataAudits.clinicHistoryText || "<p><br></p>",
                medicalAttentionQuestion: [false, true].includes(dataAudits.medicalAttentionQuestion) ? dataAudits.medicalAttentionQuestion : null,
                medicalAttentionText: dataAudits.medicalAttentionText || "<p><br></p>",
                processQuestion: [false, true].includes(dataAudits.processQuestion) ? dataAudits.processQuestion : null,
                processText: dataAudits.processText || "<p><br></p>",
                medicationsQuestion: [false, true].includes(dataAudits.medicationsQuestion) ? dataAudits.medicationsQuestion : null,
                medicationsText: dataAudits.medicationsText || "<p><br></p>",
                otherServicesQuestion: [false, true].includes(dataAudits.otherServicesQuestion) ? dataAudits.otherServicesQuestion : null,
                otherServicesText: dataAudits.otherServicesText || "<p><br></p>"
            });
        setIsLoading(false);
    }

    const validateRip= (valRips:any[]) => valRips.length === 1 && typeof valRips[0] === 'object' && Object.keys(valRips[0]).length === 0
    
    const convertServiceRips = (dataRips:any) => {
        const currentContent = contentDataAudit.map((content:typeContentAudtDetailed)=>{
            if(content.id === "process" && dataRips.ripsReportAP)
                return{
                    ...content,
                    isHidden: validateRip(dataRips.ripsReportAP)
                }
            if(content.id === "medications" && dataRips.ripsReportAM)
                return{
                    ...content,
                    isHidden: validateRip(dataRips.ripsReportAM)
                }
            if(content.id === "otherServices" && dataRips.ripsReportAT)
                return{
                    ...content,
                    isHidden: validateRip(dataRips.ripsReportAT)
                }
            return content
        })
        setContentDataAudit(currentContent);
    }

    const callGetDataAuditDocument = () => {
        setIsLoading(true);
        getData(`${GET_ELECTRONIC_DOCS}/${idDocument}/audit/audit-document`).then((response:any)=>{
            convertServiceAudits(response)
        }).finally(()=>{setIsLoading(false)})
    }

    const callSaveAuditDetails = (data:any) => {
        setIsLoading(true);
        return patchSaveAuditDocument( idDocument!, data ).then((response: any) => {
            setIsLoading(false)
            Promise.resolve();
        }).catch(()=> {
            setIsLoading(false);
            Promise.reject();
        });
    }

    const validateInitial = () => {
        
        const processIsHidden = contentDataAudit.find(field => field.id === "process")?.isHidden;
        const medicationsIsHidden = contentDataAudit.find(field => field.id === "medications")?.isHidden;
        const otherServicesIsHidden = contentDataAudit.find(field => field.id === "otherServices")?.isHidden;

        if (data["validateRightQuestion"] === false) {
            let currentContent = contentDataAudit.map((content:typeContentAudtDetailed)=>
                content.id === 'validateRight' ? {...content, disable: false }: {...content, disable: true}
            )
            setContentDataAudit(currentContent)
            setBtnGlossInvoices(false);
            setBtnAuthorizeInvoice(true);
            return
        }

        if (data["validateRightQuestion"] === true) {
            const currentContent = contentDataAudit.map((content:typeContentAudtDetailed)=> {
                return{
                    ...content,
                    disable: false
                }
            });
            setContentDataAudit(currentContent);
            setBtnGlossInvoices(true);
            setBtnAuthorizeInvoice(true);
        }

        if ([
            data['userInfoQuestion'],
            data['validateRightQuestion'],
            data['adminInfoQuestion'],
            data['clinicHistoryQuestion'],
            data['medicalAttentionQuestion'],
        ].some(data => data === undefined)) {
            setBtnGlossInvoices(true);
            setBtnAuthorizeInvoice(true);
            return;
        }

        if(!contentDataAudit.some(data=> data.id === 'medications' && data.isHidden) 
            && data['medicationsQuestion'] === undefined){
                setBtnGlossInvoices(true);
                setBtnAuthorizeInvoice(true);
                return;
        }
        if(!contentDataAudit.some(data=> data.id === 'process' && data.isHidden) 
            && data['processQuestion'] === undefined){
                setBtnGlossInvoices(true);
                setBtnAuthorizeInvoice(true);
                return;
        }
        if(!contentDataAudit.some(data=> data.id === "otherServices" && data.isHidden) 
            && data['otherServicesQuestion'] === undefined){
                setBtnGlossInvoices(true);
                setBtnAuthorizeInvoice(true);
                return;
        }
        
        
        if(data["userInfoQuestion"] && 
            data["validateRightQuestion"] && 
            data["adminInfoQuestion"] && 
            data["clinicHistoryQuestion"] && 
            data["medicalAttentionQuestion"] && 
            (processIsHidden ? true : data["processQuestion"]) && 
            (medicationsIsHidden ? true : data["medicationsQuestion"]) && 
            (otherServicesIsHidden ? true : data["otherServicesQuestion"])
        ){
            setBtnGlossInvoices(true);
            setBtnAuthorizeInvoice(false);
        }else{
            setBtnGlossInvoices(false);
            setBtnAuthorizeInvoice(true);
        }
    }

    const validateFieldsAutorize = () => {
        const userInfoQuestionIsValid = validateFields(data["userInfoQuestion"], data["userInfoText"]);
        const validateRightQuestionIsValid = validateFields(data["validateRightQuestion"], data["validateRightText"]);
        const adminInfoQuestionIsValid = validateFields(data["adminInfoQuestion"], data["adminInfoText"]);
        const clinicHistoryQuestionIsValid = validateFields(data["clinicHistoryQuestion"], data["clinicHistoryText"]);
        const medicalAttentionQuestionIsValid = validateFields(data["medicalAttentionQuestion"], data["medicalAttentionText"]);
        const medicalAttentionTextIsValid = validateInputs(data["medicalAttentionText"]);
        const contentPresent = contentDataAudit.filter(field => field.isHidden === false);
        const processIsValid = contentPresent.find(field => field.id === "process") === undefined ? true : validateInputs(data["processText"]);
        const medicationsIsValid = contentPresent.find(field => field.id === "medications") === undefined ? true : validateInputs(data["medicationsText"]);
        const otherServicesIsValid = contentPresent.find(field => field.id === "otherServices") === undefined ? true : validateInputs(data["otherServicesText"]);

        if(valueGlossas?.value > 0 || glossesGeneralDetailStatus){
            dispatch.messageModal.setMessageModal({
                show: true, 
                title: "Atención",
                size: 'medium',
                description: (
                  <div className={style.contentDescriptionModal} data-testid='content_description'>
                    <ErrorOutlineIcon className={`${style.iconButton} ${style.iconButtonWarning}`}/>
                    <Typography>
                        Al Autorizar para pagos,  se eliminaran las glosas  previamente creadas  en esta  Auditoria.
                        <p><b>¿Desea autorizar la Factura ${bill} para pagos ?</b></p>
                    </Typography>
                  </div>
                ),
                onClose() {
                    dispatch.messageModal.cleanStateMessage()
                },
                onOk(){
                    setIsLoading(true);
                    deleteData(`${POST_PAYMENT_AUTHORIZATION_DETAILS}/${idDocument}`).then(()=>{
                        callGetDetailedDocument(idDocument || '');
                        if(
                            userInfoQuestionIsValid && 
                            validateRightQuestionIsValid && 
                            adminInfoQuestionIsValid && 
                            clinicHistoryQuestionIsValid && 
                            medicalAttentionQuestionIsValid &&
                            medicalAttentionTextIsValid && 
                            processIsValid &&
                            medicationsIsValid &&
                            otherServicesIsValid
                        ){
                            onOpenModalAutorizationPayments(true);
                        } else {
                            showMessageError()
                        }                    
                    }).finally(()=> setIsLoading(false))
                },
                OkTitle: 'Autorizar',
                cancelTitle: 'Cancelar',
            })
            
        }

        if(
            userInfoQuestionIsValid && 
            validateRightQuestionIsValid && 
            adminInfoQuestionIsValid && 
            clinicHistoryQuestionIsValid && 
            medicalAttentionQuestionIsValid &&
            medicalAttentionTextIsValid && 
            processIsValid &&
            medicationsIsValid &&
            otherServicesIsValid
        ){
            onOpenModalAutorizationPayments(true);
        } else {
            showMessageError()
        }                    

    } 

    const validateFields = (radio: boolean | null, field: string) => {
        if(field !== "" && removeHTMLTagsFromText(field).length >= 100 && removeHTMLTagsFromText(field).length <= 5000 && radio){
            return true;
        } else {
            return false;
        }
    }

    const validateFieldsGloses = (lastFieldModified:any) => {
        const contentPresent = contentDataAudit.filter(field => field.isHidden === false);
        const processIsValid = contentPresent.find(field => field.id === "process") === undefined ? true : validateInputs(data["processText"]);
        const medicationsIsValid = contentPresent.find(field => field.id === "medications") === undefined ? true : validateInputs(data["medicationsText"]);
        const otherServicesIsValid = contentPresent.find(field => field.id === "otherServices") === undefined ? true : validateInputs(data["otherServicesText"]);

        if(data["validateRightQuestion"] === false && validateInputs(data["validateRightText"])){
            _.isEmpty(lastFieldModified) ? navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/auditar_glosas`)
            : callSaveAuditDetails(lastFieldModified).then(()=>{
                navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/auditar_glosas`)
            })
        }else{
            if(validateInputs(data["userInfoText"]) && 
                validateInputs(data["validateRightText"]) &&
                validateInputs(data["adminInfoText"]) &&
                validateInputs(data["clinicHistoryText"]) &&
                validateInputs(data["medicalAttentionText"]) && 
                processIsValid &&
                medicationsIsValid &&
                otherServicesIsValid
            ){
                _.isEmpty(lastFieldModified) ? navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/auditar_glosas`)
                : callSaveAuditDetails(lastFieldModified).then(()=>{
                    navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/auditar_glosas`)
                })
            }else{
                showMessageError()
            }
        }
    }

    const validateInputs = (field: string) => {
        const cleanText = removeHTMLTagsFromText(field);
        if(cleanText !== "" && cleanText.length >= 100 && cleanText.length <= 5000){
            return true;
        }else{
            return false;
        }
    }

    const callGetDataRips = () => {
        getDataRips( idDocument || "").then((response: any) => {
            convertServiceRips(response);
        }).finally(()=> setIsLoading(false))
    }

    const showMessageError = () => {
        dispatch.messageModal.setMessageModal({
            show: true, 
            title: "Atención",
            size: 'small',
            description: (
              <div className={style.contentDescriptionModal} data-testid='content_description'>
                <HighlightOffIcon className={style.iconButton}/>
                <Typography>
                    Debe diligenciar todos los campos, mínimo 100 caracteres y máximo 5000 caracteres.
                </Typography>
              </div>
            ),
            onClose() {
                dispatch.messageModal.cleanStateMessage()
            },
            onOk(){
                dispatch.messageModal.cleanStateMessage()
            },
            OkTitle: 'Aceptar',
        })
    }

    return {
        isLoading,
        data,
        btnGlossInvoices,
        btnAuthorizeInvoice,
        contentDataAudit,
        openModalAutorizationPayments,
        readOnly,
        onOpenModalAutorizationPayments,
        callSaveAuditDetails,
        callGetDataRips,
        handleSetData,
        convertServiceAudits,
        validateInitial,
        validateFieldsAutorize,
        validateFieldsGloses,
        callGetDataAuditDocument,
    }
}
