import { filterProperties } from "shared/utils";
import { transformKey } from "./transformKey";

export const convertToAuditPatchRequest = (object: any, propertyName: string) => {
    const filteredObject = { ...filterProperties(object, propertyName) };

    const newObject = Object.keys(filteredObject).reduce((_, key) => {
        const oldObjectKey = transformKey(key);
        return {
            fieldName: oldObjectKey,
            value: filteredObject[key]
        }
    }, {} as { [key: string]: any });
    
    return newObject;
}