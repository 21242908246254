import * as React from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ColorlibConnector } from "shared/utils";
import { useStepper } from "../hooks/useStepper";
import { Typography } from "@mui/material";
import { ITimeLine } from "../Models/ITimeLine";
import {useEffect, useState} from 'react';
import '../styles/_stepperStates.scss'

interface Props{
  timeLine: ITimeLine
}

export const SteppersStates = ({timeLine}:Props)=> {

  const { ColorlibStepIcon, steps, changeSeeTimeLine, seeTimeLine } = useStepper({timeLine});

  useEffect(() => {
    setTimeout(() => {
      changeSeeTimeLine();
    }, 600);
  }, [seeTimeLine])
  
  return (
    <Stack className="containerStepper" spacing={4}>
      <Typography className="titleContent">Estado</Typography>
      <Stepper
        alternativeLabel
        activeStep={1}
        connector={<ColorlibConnector />}
      >
        {steps.map((step) => (
          <Step key={step.label} active={step.active} completed={step.completed}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
