import { createModel } from "@rematch/core";
import { apiInstance } from "shared/api/config/apiInstance";
import { IGetLink, ILinkDocument, IValidateDocument } from "shared/models/store/ILinkDocument";
import { RootModel } from "../rootModel";
import { linkDocAdapter } from "../../adapters/linkDoc.adapter";
import Swal from "sweetalert2";
import { putUploadDocBlob } from '../../api/services/putUploadDocBlob';
import { cleanFileName } from '../../utils/helpers/cleanFileName';
import { downloadDocument } from "shared/utils/helpers/downloadDocument";
import { downloadsDescription, downloadsTitle } from "shared/utils/data/infoDownloadDocument";

export const linkDocument = createModel<RootModel>()({
  state: {
    id: null,
    link: null,
    originalName: null,
    openModalRips: false,
  } as ILinkDocument,
  reducers: {
    getLink(state,payload){
      return {
        ...state,
        id: payload.id,
        link: payload.link,
        originalName: payload.originalName,
      };
    },
    toogleModalRips(state, payload) {
      return {
        ...state,
        openModalRips: payload,
      };
    },
    cleanState(state) {
      return {
        ...state,
        id: null,
        link: null,
        originalName: null,
      };
    }
  },
  effects: (dispatch) => ({
    async getLinkAsync(params:IGetLink) {
      const response = await apiInstance.post(params.url, {
        fileNames: [cleanFileName(params.file.name)],
      });
      const data = Array.isArray(response.data) ? response.data[0]: response.data;
      data && putUploadDocBlob(data.link, data.id, data.originalName, params.file).then(()=>{
        dispatch.linkDocument.getLink(linkDocAdapter(data))
      })
    },

    async processDocumentPatchAsync({url,id}) {
      const response = await apiInstance.post(url, {attachmentId: id,}, {responseType: 'blob'});
      downloadDocument(response, downloadsTitle.successDocument, downloadsDescription.successDocument, 'success');
      dispatch.linkDocument.cleanState();
    },

    async validateLinkAsync(state) {
      
      state.linkDocument = {
        id: null,
        link: null,
        originalName: null,
        openModalRips: false,
      }
    },
  }),
});
