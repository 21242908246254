import React, { useEffect } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { typesNotifications } from './models/ENotifications';
import Imagen from '../../assets/index'
import { INotification } from 'shared/models/store/INotifications';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const CardNotifications = ({id, title, message, createdAt, electronicDocumentId, action, status, readNotification, deleteNotificacion, viewNotification }:INotification) => {

    useEffect(() => {
        dayjs.locale("es");
      }, [])

  return (
    <Box aria-label='cardNotification' className='cardNotification'>
        <Box className='contentNotification'>
            <Box className='containerTitle'>
                <Typography
                    variant="subtitle2"
                    className={`titleCard ${electronicDocumentId && 'titleCardUrl'}`}
                    data-testid='titleCard'
                    onClick={()=>electronicDocumentId && viewNotification(status, id, electronicDocumentId)}
                >
                    {title}
                </Typography>
            </Box>
            <Typography variant="body1" className="infoCard">
                {message}
            </Typography>

                {
                    electronicDocumentId && (
                        <Typography 
                            variant="body2" 
                            className="btnNavigate"
                            data-testid='btnNavigate'
                        >
                            <Button variant='text' data-testid='link-detailed' onClick={()=>viewNotification(status, id, electronicDocumentId)}>
                                {action}
                            </Button>
                        </Typography>
                    )
                }
                <Typography 
                    variant="body2" 
                    className="dateNotification"
                    data-testid='text_hora'
                >
                    {createdAt ? dayjs(new Date(createdAt)).format('DD MMMM YYYY HH:mm') : ''}
                </Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

            </Box>
        </Box>
        <Box className='containerActions'>
            <IconButton 
                className={`${status === typesNotifications.Leido && 'btnRead'}`}
                data-testid='btnRead'
                onClick={()=> readNotification(status, id) }
            >
                <img className='imageRead' src={Imagen.iconoLectura} alt='Lectura' />
            </IconButton>
            <IconButton
                data-testid='btnDelete'
                onClick={() => { deleteNotificacion(id) }}
            >
                <img className='imageDelete' src={Imagen.iconoBorrar} alt='Borrar' />
            </IconButton>
        </Box>
    </Box>
  )
}
