import React, { SyntheticEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { debounceFunction } from 'shared/utils';
import { typesNotifications } from './models/ENotifications';
import { useNavigate } from 'react-router-dom';

export const useNotifications = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch<Dispatch>();    
    const { notifications, page, totalElements } = useSelector((state: RootState) => state.notifications);
    const [valor, setValor] = useState(0);
    const [statusNotifications, setStatusNotifications] = useState('N');
    const [isLoading, setIsLoading] = useState(false);


    const changeLoading = (value:boolean) => {
      setIsLoading(value)
    }

    const changeTab = (event: SyntheticEvent, newValue: number) => {
      setIsLoading(true);
      setValor(newValue);
      newValue === 0 && setStatusNotifications('N');
      newValue === 1 && setStatusNotifications('R');
      newValue === 2 && setStatusNotifications('');
    };

    const validateScrollEnd = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
    
        debounceFunction(() => {
          const esFinalScroll = (clientHeight + scrollTop + 10) >= scrollHeight;
          esFinalScroll && notifications.length >= 10 
            && dispatch.notifications.getNotificationsAsync({
              page: page + 1,
              status:statusNotifications
            })
        });
    }

    const readNotification = (status: string, id: number) => {
      const newStatus = status === typesNotifications.Leido ? typesNotifications.NoLeido : (status === typesNotifications.NoLeido && typesNotifications.Leido);
      dispatch.notifications.updateStatusNotificationAsync({
        page: 1,
        status: statusNotifications,
        initialLoad: true,
        resetLoad: true,
        params: [{
          id,
          status: newStatus,
        }],
        url: '/'
      })
    }

    const readAllNotifications = () => {
      const reads = notifications.map(notification => { return {id: notification.id, status: 'R'}} )
      dispatch.notifications.updateStatusNotificationAsync({
        page: 1,
        status: statusNotifications,
        initialLoad: true,
        resetLoad: true,
        params: reads,
        url: '/all-read'
      })
    }

    const deleteNotificacion = (id: number) => {
      dispatch.notifications.updateStatusNotificationAsync({
          page: 1,
          status: statusNotifications,
          initialLoad: true,
          resetLoad: true,
          params: [{
            id,
            status: typesNotifications.Borrado,
          }],
          url: '/'
      })
    }

    const viewNotification = (status: string, id: number, electronicDocumentId: number) => {
      const newStatus = status === typesNotifications.Leido ? typesNotifications.NoLeido : (status === typesNotifications.NoLeido && typesNotifications.Leido);
      dispatch.notifications.updateByNavigationNotificationAsync({
        page: 1,
        status: statusNotifications,
        initialLoad: true,
        resetLoad: true,
        params: [{
          id,
          status: newStatus,
        }],
        url: '/'
      }).then(()=>{
        navigate(`/consulta_documentos_electronicos/detalle_documento/${electronicDocumentId}`)
      })
    }

  return {
    valor,
    isLoading,
    statusNotifications,
    notifications,
    totalElements,
    changeTab,
    validateScrollEnd,
    readNotification,
    readAllNotifications,
    deleteNotificacion,
    changeLoading,
    viewNotification
  }
}
