import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {AuthService, getOAuthConfigByLoginType} from "shared/auth";
import {getMessageHTTP} from "shared/utils";
import Swal from "sweetalert2";
import {ApiErrorDescriptor, ApiErrorDescriptorDetail} from "shared/models";
import {JWTIDToken} from "shared/auth/oauh2-with-pkce/AuthService";
import {getQueryParam} from "../../auth/oauh2-with-pkce/util";

export const apiInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_TFE_APP_ADMIN,
  timeout: 120000,
  headers: {
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS'
  },
});

apiInstance.interceptors.request.use(async (configuracion) => {
  const userType = getQueryParam('LT') || localStorage.getItem("LT") as "I" | "E" | null;
  const authService = new AuthService<JWTIDToken>(
    getOAuthConfigByLoginType(userType as 'I' | 'E' | null)
  );
  configuracion.headers = {
    Authorization: `Bearer ${authService.getAuthTokens().access_token}`,
  };
  return configuracion;
});

apiInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const errorApiResponse: ApiErrorDescriptorDetail = error?.response?.data as ApiErrorDescriptorDetail;
    let apiError: ApiErrorDescriptor;

    if(errorApiResponse instanceof Blob && (errorApiResponse as Blob).type === 'application/json') {
      const errorJson: string = await (error.response?.data as Blob).text();
      apiError = JSON.parse(errorJson);
    }else{
      apiError = errorApiResponse?.message
      ? errorApiResponse
      : getMessageHTTP(error.response?.status);
    }

    Swal.fire({
      icon: "warning",
      title: "¡Ups!",
      text: apiError.message,
      html: `
        <p>${apiError.message}</p>
      `,
      confirmButtonText: "Aceptar",
    });

    return Promise.reject(error);
  }
);
