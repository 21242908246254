
export const toSnakeCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}

export const toUrlEncoded = (obj: any): string => {
  return Object.keys(obj)
    .map(
      (k) =>
        encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k])
    )
    .join('&')
}

export const toFormData = (obj: any): URLSearchParams => {
  const urlParams = new URLSearchParams();
  for (const key in obj) {
    if(key !== 'clientSecret')
      urlParams.append(encodeURIComponent(toSnakeCase(key)), obj[key])
  }
  return urlParams;
}

export const validateToken = (token: any) : boolean => {
  return [`${process.env.REACT_APP_OAUTH2SERV_ISSUER_URI}`, `${process.env.REACT_APP_AZUREAD_ISSUER_URI}`].includes(token.iss) &&
      (Number(token.exp) * 1000) - new Date().getTime() > 0;
}
export const getQueryParam = (paramName: string): string => {
  const split = window.location.toString().split('?')
  if (split.length < 2) {
    return '';
  }
  const pairs = split[1].split('&');
  return pairs.filter(pair => pair.split('=')[0] === paramName)
      .map(pair => decodeURIComponent(pair.split('=')[1] || ''))
      .reduce((prev, accum) => accum || prev, '')
}

export const emptyIfNull = (value: any): string => {
  return value !== undefined && value != null ? value : '';
}